import debounce from 'lodash.debounce'

import { DEFAULT_SORT_OPTION } from './state'

export default {
  /**
   * @param context
   * @return {Promise<void>}
   */
  resetState(context) {
    context.commit('RESET_STATE')
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingModel[]} listings
   * @return {Promise<void>}
   */
  setListings(context, listings) {
    context.commit('SET_LISTINGS', listings)
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingFilter[]} filters
   * @return {Promise<void>}
   */
  setFilters(context, filters) {
    context.commit('SET_FILTERS', filters)
    context.commit('SET_AVAILABLE_FILTERS', filters)
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingFilter[]} availableFilters
   * @return {Promise<void>}
   */
  setAvailableFilters(context, availableFilters) {
    context.commit('SET_AVAILABLE_FILTERS', availableFilters)
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingFilter[]} activeFilters
   */
  setActiveFilters(context, activeFilters) {
    context.commit('SET_ACTIVE_FILTERS', activeFilters)
  },

  /**
   * @param context
   * @param {boolean} [resetQueryString]
   * @return {Promise<void>}
   */
  async resetActiveFilters(context, { resetQueryString = false } = {}) {
    context.commit('SET_ACTIVE_FILTERS', [])

    if (resetQueryString) {
      await this.$filterQueryHelperService.removeFiltersFromQuery()
    }
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingFilter} filter
   * @return {Promise<void>}
   */
  async deleteActiveFilter(context, filter) {
    const activeFilterIndex = context.state.activeFilters.findIndex(activeFilter => activeFilter.key === filter.key)

    if (activeFilterIndex !== -1) {
      context.commit('DELETE_ACTIVE_FILTER_BY_INDEX', activeFilterIndex)

      await this.$filterQueryHelperService.setFiltersInQuery({ filters: context.state.activeFilters })

      return context.dispatch('fetchListingsByActiveFiltersDebounced')
    }
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingFilter} filter
   * @param {loavies.models.listing.ListingFilterOption} filterOption
   * @return {Promise<void>}
   */
  async addActiveFilterOption(context, { filter, filterOption }) {
    let activeFilterIndex = -1
    const activeFilter = context.state.activeFilters.find((activeFilter, index) => {
      if (activeFilter.key === filter.key) {
        activeFilterIndex = index

        return true
      }

      return false
    })

    if (activeFilterIndex === -1) {
      // Add new active filter with option
      context.commit('ADD_ACTIVE_FILTER', filter.assign({
        options: [filterOption],
      }))
    } else {
      // Add option to active filter
      context.commit('REPLACE_ACTIVE_FILTER_BY_INDEX', {
        index: activeFilterIndex,
        activeFilter: filter.assign({
          options: [...activeFilter.options, filterOption],
        }),
      })
    }

    await this.$filterQueryHelperService.setFiltersInQuery({ filters: context.state.activeFilters })

    return context.dispatch('fetchListingsByActiveFiltersDebounced')
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingFilter} filter
   * @param {loavies.models.listing.ListingFilterOption} filterOption
   * @return {Promise<void>}
   */
  async removeActiveFilterOption(context, { filter, filterOption }) {
    let activeFilterIndex = -1
    const activeFilter = context.state.activeFilters.find((activeFilter, index) => {
      if (activeFilter.key === filter.key) {
        activeFilterIndex = index

        return true
      }

      return false
    })

    // Check if filter exists
    if (activeFilterIndex === -1) {
      return Promise.resolve()
    }

    // Create new active filter
    const newActiveFilter = filter.assign({
      options: activeFilter.options.filter(option => option.key !== filterOption.key),
    })

    if (newActiveFilter.options.length === 0) {
      // If all options are removed, delete the whole active filter
      context.commit('DELETE_ACTIVE_FILTER_BY_INDEX', activeFilterIndex)
    } else {
      // Set newly created active filter with correct options
      context.commit('REPLACE_ACTIVE_FILTER_BY_INDEX', {
        index: activeFilterIndex,
        activeFilter: newActiveFilter,
      })
    }

    await this.$filterQueryHelperService.setFiltersInQuery({ filters: context.state.activeFilters })

    return context.dispatch('fetchListingsByActiveFiltersDebounced')
  },

  /**
   * @param context
   * @return {Promise<void>}
   */
  async fetchListingsByActiveFilters(context) {
    const {
      listings,
      filters: availableFilters,
      totals,
    } = await this.$elasticApiService.getListings({
      activeCategory: context.state.activeCategory,
      activeFilters: context.state.activeFilters,
      activePriceFilters: context.state.activePriceFilters,
      activeSortOption: context.state.activeSortOption,
    })

    await context.dispatch('setListings', listings)
    await context.dispatch('setAvailableFilters', availableFilters)
    await context.dispatch('setTotals', totals)
  },

  /**
   * @param context
   * @return {Promise<void>}
   */
  fetchListingsByActiveFiltersDebounced: debounce(context => {
    return context.dispatch('fetchListingsByActiveFilters')
  }, 500),

  /**
   * @param context
   * @param {loavies.models.listing.ListingSortOption} sortOption
   * @param {boolean} [setQueryString]
   * @param {boolean} [fetchListings]
   * @return {Promise<void>}
   */
  async setActiveSortOption(context, { sortOption, setQueryString = true, fetchListings = true }) {
    context.commit('SET_ACTIVE_SORT_OPTION', sortOption)

    if (setQueryString) {
      await this.$filterQueryHelperService.setSortOptionInQuery({ sortOption: context.state.activeSortOption })
    }

    if (fetchListings) {
      return context.dispatch('fetchListingsByActiveFiltersDebounced')
    }
  },

  /**
   * @param context
   * @param {boolean} [fetchListings]
   * @return {Promise<void>}
   */
  setDefaultSortOption(context, fetchListings) {
    return context.dispatch('setActiveSortOption', {
      sortOption: DEFAULT_SORT_OPTION,
      fetchListings,
    })
  },

  /**
   * @param context
   * @return {Promise<void>}
   */
  removeActiveSortOption(context) {
    return context.dispatch('setDefaultSortOption')
  },

  /**
   * @param context
   * @return {Promise<void>}
   */
  resetActiveSortOption(context) {
    return context.dispatch('setDefaultSortOption', false)
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingPriceFilter[]} priceFilters
   * @return {Promise<void>}
   */
  setPriceFilters(context, priceFilters) {
    context.commit('SET_PRICE_FILTERS', priceFilters)
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingPriceFilter[]} activePriceFilters
   * @return {Promise<void>}
   */
  setActivePriceFilters(context, activePriceFilters) {
    context.commit('SET_ACTIVE_PRICE_FILTERS', activePriceFilters)
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingPriceFilter} priceFilter
   * @return {Promise<void>}
   */
  async setActivePriceFilter(context, priceFilter) {
    let activePriceFilterIndex
    const activePriceFilter = context.state.activePriceFilters
      .find((activePriceFilter, index) => {
        const statement = activePriceFilter.key === priceFilter.key

        if (statement) {
          activePriceFilterIndex = index
        }

        return statement
      })

    if (activePriceFilter?.amount === priceFilter.amount) {
      return Promise.resolve()
    }

    if (!activePriceFilter) {
      context.commit('ADD_ACTIVE_PRICE_FILTER', priceFilter)
    } else if (activePriceFilter.amount !== priceFilter.amount) {
      context.commit('REPLACE_ACTIVE_PRICE_FILTER_BY_INDEX', {
        index: activePriceFilterIndex,
        activePriceFilter: priceFilter,
      })
    }

    await this.$filterQueryHelperService.setPriceFiltersInQuery({ priceFilters: context.state.activePriceFilters })

    return context.dispatch('fetchListingsByActiveFiltersDebounced')
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingPriceFilter} priceFilter
   * @return {Promise<void>}
   */
  async removeActivePriceFilter(context, priceFilter) {
    const activePriceFilterIndex = context.state.activePriceFilters
      .findIndex(activePriceFilter => activePriceFilter.key === priceFilter.key)

    if (activePriceFilterIndex === -1) {
      return Promise.resolve()
    }

    context.commit('DELETE_ACTIVE_PRICE_FILTER_BY_INDEX', activePriceFilterIndex)

    await this.$filterQueryHelperService.setPriceFiltersInQuery({ priceFilters: context.state.activePriceFilters })

    return context.dispatch('fetchListingsByActiveFiltersDebounced')
  },

  /**
   * @param context
   * @param {boolean} [resetQueryString]
   * @return {Promise<void>}
   */
  resetActivePriceFilters(context, { resetQueryString = false } = {}) {
    context.commit('SET_ACTIVE_PRICE_FILTERS', [])

    if (resetQueryString) {
      return this.$filterQueryHelperService.removePriceFiltersFromQuery()
    }

  },

  /**
   * @param context
   * @param {loavies.models.api.ApiTotalsModel} totals
   * @return {Promise<void>}
   */
  setTotals(context, totals) {
    context.commit('SET_TOTALS', totals)
  },

  /**
   * @param context
   * @param {string|null} activeCategory
   * @return {Promise<void>}
   */
  setActiveCategory(context, activeCategory) {
    context.commit('SET_ACTIVE_CATEGORY', activeCategory)
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingModel[]} recentlyWatchedListings
   * @return {Promise<void>}
   */
  setRecentlyWatchedListings(context, recentlyWatchedListings) {
    context.commit('SET_RECENTLY_WATCHED_LISTINGS', recentlyWatchedListings)
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingModel} listing
   * @return {Promise<void>}
   */
  addToRecentlyWatchedListings(context, listing) {
    const recentlyWatchedIncludesListing = context.state.recentlyWatchedListings
      .findIndex(recentlyWatchedListing => recentlyWatchedListing.id === listing.id) !== -1

    // Recently watched al ready includes listing
    if (recentlyWatchedIncludesListing) {
      // Remove listing from current recently watched array
      const filteredListings = context.state.recentlyWatchedListings
        .filter(recentlyWatchedListing => recentlyWatchedListing.id !== listing.id)

      // Set the current listing id as first item in the new array
      filteredListings.unshift(listing)

      context.commit('SET_RECENTLY_WATCHED_LISTINGS', filteredListings)

      return Promise.resolve()
    }

    context.commit('ADD_TO_RECENTLY_WATCHED_LISTINGS', listing)

    return this.$listingHandlerService.increaseListingTimesWatched(listing.id)
  },

  /**
   * @param context
   * @param {Array<Object>} content
   * @return {Promise<void>}
   */
  setListingsPageContent(context, content) {
    context.commit('SET_LISTING_PAGE_CONTENT', content)
  },
}
