import { OrderVerificationModel, PriceModel } from '@loavies/c2c-models'
import {
  mapOrder,
  mapOrderVerification,
} from '~/lib/services/api/node-api-mappers'

/**
 * @typedef {OrderHandlerService}
 * @alias this.$orderHandlerService
 */
export class OrderHandlerService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
  }

  init() {
    this.$nodeApiService = this.context.$nodeApiService
    this.$microserviceApiService = this.context.$microserviceApiService
    this.$clientStorageService = this.context.$clientStorageService

    this.nodeApiInstance = this.$nodeApiService.instance
    this.microserviceApiInstance = this.$microserviceApiService.instance
  }

  /**
   * @param {string} listingId
   * @param {loavies.models.user.UserAddressModel} deliveryAddress
   * @param {loavies.models.payment.PaymentMethodModel|loavies.models.payment.PaymentMethodIssuerModel} selectedPaymentMethod
   * @return {Promise<loavies.models.payment.OrderModel>}
   */
  createOrder({ listingId, deliveryAddress, selectedPaymentMethod }) {
    return this.nodeApiInstance
      .post('/order', {
        listingId,
        deliveryAddress,
        selectedPaymentMethod,
      })
      .then(response => mapOrder(response.data))
  }

  /**
   * @param {loavies.models.payment.OrderModel} order
   * @param {Object} paymentMethodData
   * @param {string} returnUrl
   */
  payOrder({ order, paymentMethodData, returnUrl }) {
    return this.microserviceApiInstance
      .post(`/payment/order/pay/${order.transactionId}`, {
        ...paymentMethodData,
        returnUrl,
      })
      .then(response => response.data)
  }

  /**
   * @param {string} redirectResult
   * @returns {Promise<loavies.models.payment.OrderVerificationModel>}
   */
  verifyOrder(redirectResult) {
    return this.microserviceApiInstance
      .post('/payment/order/pay/verify', { redirectResult })
      .then(response => mapOrderVerification(response.data.data))
  }

  /**
   * @param {loavies.models.payment.OrderVerificationModel} orderVerification
   */
  setOrderVerificationInStorage(orderVerification) {
    const currentOrderVerifications = this.$clientStorageService.getOrderVerifications()

    if (!currentOrderVerifications) {
      const mappedOrderVerifications = {
        [orderVerification.transactionId]: orderVerification,
      }

      this.$clientStorageService.setOrderVerifications(mappedOrderVerifications)
      return
    }

    currentOrderVerifications[orderVerification.transactionId] = orderVerification

    this.$clientStorageService.setOrderVerifications(currentOrderVerifications)
  }

  /**
   * @param {string} transactionId
   * @return {null|loavies.models.payment.OrderVerificationModel}
   */
  getOrderVerification(transactionId) {
    const orderVerifications = this.getOrderVerifications()

    return orderVerifications?.[transactionId] ?? null
  }

  /**
   * @returns {{}|null}
   */
  getOrderVerifications() {
    const rawOrderVerifications = this.$clientStorageService.getOrderVerifications()

    if (!rawOrderVerifications) {
      return null
    }

    const mappedOrderVerification = {}

    Object.keys(rawOrderVerifications).forEach(key => {
      const rawOrderVerification = rawOrderVerifications[key]

      mappedOrderVerification[key] = new OrderVerificationModel({
        ...rawOrderVerification,
        totalPaid: new PriceModel(rawOrderVerification.totalPaid),
      })
    })

    return mappedOrderVerification
  }
}
