import { FAQ, TERMS_AND_CONDITIONS, PRIVACY_POLICY } from '~/lib/constants/routes'

/**
 * @param {Object} data
 * @return {Promise<Object>}
 */
export async function transformDatoData(data) {
  await recursiveLookForContentItem({
    content: data,
    typename: 'SmartLinkRecord',
    asyncCallback: mapSmartLink,
  })

  return data
}

/**
 * @param {Object} smartLink
 * @return {Promise<void>}
 */
export function mapSmartLink(smartLink) {
  switch (smartLink.internalUrl?.__typename) {
    case 'FaqOverviewPageRecord': {
      smartLink.internalUrl = FAQ
      break
    }
    case 'FaqQuestionRecord': {
      smartLink.internalUrl = `${FAQ}/${smartLink.internalUrl.slug}`
      break
    }
    case 'TermsAndConditionsPageRecord': {
      smartLink.internalUrl = TERMS_AND_CONDITIONS
      break
    }
    case 'PrivacyPolicyPageRecord': {
      smartLink.internalUrl = PRIVACY_POLICY
      break
    }
    default: {
      smartLink.internalUrl = smartLink.internalUrl.startsWith('/') ? smartLink.internalUrl : `/${smartLink.internalUrl}`
    }
  }

  return Promise.resolve()
}

/**
 * This function looks for content with a specific typename recursively (such as 'SmartLinkRecord').
 * When this content is found, a callback is called which is provided.
 * @param {*} content
 * @param {string} typename
 * @param {Function} asyncCallback
 * @return {Promise<void>}
 */
export async function recursiveLookForContentItem({ content, typename, asyncCallback }) {
  if (Array.isArray(content)) {
    // We want to resolve promises sequentially, so we can use storage
    // to limit api calls. Otherwise, with Promise.all all promises are
    // fired at once.
    for (const item in content) {
      if (!content.hasOwnProperty(item)) {
        return
      }

      await recursiveLookForContentItem({
        content: content[item],
        typename,
        asyncCallback,
      })
    }
    return
  }

  if (
    typeof content === 'object'
    && content !== null
    && content?.__typename === typename
  ) {
    await asyncCallback(content)
    return
  }

  if (
    typeof content === 'object'
    && content !== null
  ) {
    const promises = []

    Object.keys(content).forEach(key => promises.push(recursiveLookForContentItem({
      content: content[key],
      typename,
      asyncCallback,
    })))

    await Promise.all(promises)
  }
}
