export default function () {
  return {
    isDesktop: false,
    isMobile: false,
    isHoverSupported: false,
    mediaQueryInitialized: false,
    showErrors: false,
    showLargeProductCards: false,
    isIOS: false,
  }
}
