/**
 * @typedef {AuthenticationService}
 * @alias this.$authenticationService
 */
export class AuthenticationService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
  }

  init() {
    this.$marketplaceAuthenticationService = this.context.$marketplaceAuthenticationService
    this.$microserviceApiService = this.context.$microserviceApiService

    this.microserviceApiInstance = this.$microserviceApiService.instance
  }

  /**
   * @param {loavies.models.user.LoginUserModel} loginUserModel
   * @return {Promise<void>}
   */
  login(loginUserModel) {
    return this.microserviceApiInstance
      .post(this.$microserviceApiService.identityAuthorizeEndpoint, {
        grantType: 'CLIENT_CREDENTIALS',
        username: loginUserModel.username,
        password: loginUserModel.password,
      })
      .then(response => this.setAuthenticationForApis(response.data))
  }

  /**
   * @param {string} email
   * @return {Promise<string>}
   */
  resetPassword(email) {
    return this.microserviceApiInstance
      .post(`/identity/user/reset/password/${email}`)
      .then(response => response.data.message)
  }

  /**
   * @param {Object} authenticationDetails
   * @return {void}
   */
  setAuthenticationForApis(authenticationDetails) {
    this.$marketplaceAuthenticationService.setTokenDetails(authenticationDetails.sharetribeTokens)
    this.$microserviceApiService.setTokenDetails(this.mapMicroserviceApiTokenDetails(authenticationDetails))
  }

  /**
   * @param {Object} authenticationDetails
   * @return {Object}
   */
  mapMicroserviceApiTokenDetails(authenticationDetails) {
    return {
      accessToken: authenticationDetails.accessToken,
      accessTokenExpiresAt: authenticationDetails.accessTokenExpiresAt,
      refreshToken: authenticationDetails.refreshToken,
    }
  }

  /**
   * @param {string} email
   * @return {Promise<boolean>}
   */
  userWithEmailExists(email) {
    return this.microserviceApiInstance
      .get(`/identity/user/${email}`)
      .then(response => response.data.response)
      .catch(() => false)
  }
}

