/**
 * @typedef {CustomerCareApiService}
 * @alias this.$customerCareApiService
 */
export class CustomerCareApiService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
  }

  init() {
    this.$nodeApiService = this.context.$nodeApiService

    this.nodeApiInstance = this.$nodeApiService.instance
  }

  /**
   * @param {loavies.models.customerCare.CreateCustomerCareTicketModel} customerCareTicket
   * @return {Promise<void>}
   */
  async createTicket(customerCareTicket) {
    return this.nodeApiInstance
      .post('/customer-care/ticket', customerCareTicket)
  }
}
