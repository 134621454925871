
  import { mapGetters } from 'vuex'

  import MatchMedia from '~/mixins/match-media'

  export default {
    name: 'CheckoutLayout',
    mixins: [MatchMedia],
    computed: {
      ...mapGetters({
        isAuthenticated: 'user/isAuthenticated',
      }),
    },
  }
