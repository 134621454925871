/**
 * @typedef {MicroserviceEventService}
 * @alias this.$microserviceEventService
 */
export class MicroserviceEventService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
  }

  init() {
    this.$nodeApiService = this.context.$nodeApiService

    this.nodeApiInstance = this.$nodeApiService.instance
  }

  /**
   * @param {string} transactionId
   * @returns {Promise}
   */
  async disputeStarted(transactionId) {
    return this.nodeApiInstance
      .post(`/microservice-event/dispute-started/${transactionId}`)
  }

  /**
   * @param {string} transactionId
   * @returns {Promise}
   */
  async orderCompleted(transactionId) {
    return this.nodeApiInstance
      .post(`/microservice-event/order-completed/${transactionId}`)
  }

  /**
   * @param {string} transactionId
   * @returns {Promise}
   */
  async orderRefund(transactionId) {
    return this.nodeApiInstance
      .post(`/microservice-event/order-refund/${transactionId}`)
  }
}
