import { CHECKOUT, LISTING } from '~/lib/constants/routes'

export default {
  /**
   * @param {Object} state
   * @returns {boolean}
   */
  currentRoutePathIsCheckoutPage(state) {
    return state.currentRoutePath.includes(CHECKOUT)
  },

  /**
   * @param {Object} state
   * @returns {boolean}
   */
  currentRoutePathIsListingPage(state) {
    return state.currentRoutePath.includes(LISTING)
  },
}
