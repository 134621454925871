import axios from 'axios'

/**
 * @typedef {MagentoApiService}
 * @alias this.$magentoApiService
 */
export class MagentoApiService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.config = context.$config
    this.locale = context.i18n.locale

    this.instance = this.getAxiosInstance()
  }

  /**
   * @returns {Object} AxiosInstance
   */
  getAxiosInstance() {
    const axiosInstance = axios.create({
      baseURL: `${this.config.magentoApiBaseUrl}/${this.locale}/V1`,
    })

    this.setResponseInterceptors(axiosInstance)

    return axiosInstance
  }

  /**
   * @param {Object} axiosInstance
   * @returns {void}
   */
  setResponseInterceptors(axiosInstance) {
    axiosInstance.interceptors.response.use(
      response => response,
      error => {
        if (!error?.response?.data) {
          return Promise.reject(error)
        }

        const formattedError = this.formatMagentoError(error.response.data)

        return Promise.reject(formattedError ? Error(formattedError) : error)
      }
    )
  }

  /**
   * @param {Object} [error]
   * @returns {string|null}
   */
  formatMagentoError(error = {}) {
    const { message, parameters } = error

    return message?.replace(/%(\w+)/g, (_, g) => {
      return Array.isArray(parameters) ? parameters[--g] : parameters[g]
    }) || null
  }
}
