export default {
  /**
   * @param state
   * @param getters
   * @returns {boolean}
   */
  hasUnreadChatMessages(state, getters) {
    return getters.unreadChatMessages.length > 0
  },

  /**
   * @param state
   * @param getters
   * @param rootState
   * @returns {loavies.models.chat.ChatModel[]}
   */
  unreadChatMessages(state, getters, rootState) {
    const userId = rootState.user?.user?.id

    if (!state.chats?.length || !userId) {
      return []
    }

    return state.chats
      .filter(chat => {
        const lastMessage = chat.talkJsChat.lastMessage

        if (lastMessage && !lastMessage.readBy?.includes(userId) && lastMessage.senderId !== userId) {
          return chat
        }
      })
  },
}
