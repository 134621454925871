import axios from 'axios'

import {
  mapApiTotals,
  mapListings,
  mapListingFilters,
  mapPriceFilters,
} from './elastic-api-mappers'
import {
  getProductGroupsQuery,
  getActiveFiltersQuery,
  getAggregationsQuery,
  getSortOptionQuery,
  getListingsSearchQuery,
  getStoreIsClosedFilterEntryQuery,
  getStoreIsClosedFilterQuery,
  getStoreIsClosedQuery,
} from './elastic-api-queries'

/**
 * @typedef {ElasticApiService}
 * @alias this.$elasticApiService
 */
export class ElasticApiService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.config = context.$config
    this.i18n = context.i18n
    this.sentry = context.$sentry

    this.instance = axios.create({
      baseURL: `${this.config.elasticApiUrl}/`,
      auth: {
        username: this.config.elasticApiUsername,
        password: this.config.elasticApiPassword,
      },
    })
    this.listingIndexPath = this.config.elasticListingIndex
    this.defaultPerPage = 24
    this.defaultPage = 1
  }

  /**
   * @param {string} index
   * @param {Object} query
   * @param {Object} [options]
   * @param {number} [options.page]
   * @param {number} [options.perPage]
   * @return {Promise<{
   *   [aggregations]: Object,
   *   listings: loavies.models.listing.ListingModel[],
   *   totals: loavies.models.api.ApiTotalsModel,
   * }>}
   */
  search(
    index,
    query,
    {
      page = this.defaultPage,
      perPage = this.defaultPerPage,
    } = {}
  ) {
    const from = perPage * (page - 1)

    return this.instance.post(`${index}/_search`, {
      ...query,
      size: perPage,
      from,
    })
      .then(response => {
        return {
          ...(response?.data?.aggregations && {
            aggregations: response?.data?.aggregations,
          }),
          listings: mapListings(response?.data?.hits?.hits.map(hit => hit._source)),
          totals: mapApiTotals({
            page,
            perPage,
            totalItems: response?.data?.hits?.total.value,
          }),
        }
      })
      .catch(error => {
        this.sentry.captureException(error)

        return Promise.reject(error)
      })
  }

  /**
   * @param {Object} [query]
   * @param {Object} [activeCategory]
   * @param {loavies.models.listing.ListingFilter[]} [activeFilters]
   * @param {loavies.models.listing.ListingPriceFilter[]} [activePriceFilters]
   * @param {loavies.models.listing.ListingSortOption} [activeSortOption]
   * @param {number} [page]
   * @param {number} [perPage]
   * @return {Promise<{
   *  listings: loavies.models.listing.ListingModel[],
   *  totals: loavies.models.api.ApiTotalsModel,
   *  filters: loavies.models.listing.ListingFilter[],
   *  priceFilters: loavies.models.listing.ListingPriceFilter[],
   * }>}
   */
  getListings({
    query = {},
    activeCategory,
    activeFilters,
    activePriceFilters,
    activeSortOption,
    page,
    perPage,
  } = {}) {
    return this.search(this.listingIndexPath, {
        ...query,
        ...getStoreIsClosedQuery(),
        ...getSortOptionQuery(activeSortOption),
        ...getProductGroupsQuery(activeCategory),
        ...getActiveFiltersQuery({ activeFilters, activePriceFilters }),
        ...getAggregationsQuery({ activeFilters, activePriceFilters }),
      },
      {
        page,
        perPage,
      })
      .then(({ listings, totals, aggregations }) => {
        return {
          listings: listings,
          totals: totals,
          filters: mapListingFilters(aggregations),
          priceFilters: mapPriceFilters(aggregations),
        }
      })
  }

  /**
   * @param {string} id
   * @return {Promise<loavies.models.listing.ListingModel>}
   */
  getListingById(id) {
    return this.search(
      this.listingIndexPath,
      {
        query: {
          match: { id },
        },
      })
      .then(({ listings }) => {
        if (!listings?.length) {
          return Promise.reject(Error(this.i18n.t('listing_not_found')))
        }

        const foundListing =  listings.find(listing => listing.id === id)

        if (!foundListing) {
          return Promise.reject(Error(this.i18n.t('listing_not_found')))
        }

        return foundListing
      })
  }

  /**
   * Listings get sorted by the requested id's
   * @param {string[]} ids
   * @return {Promise<loavies.models.listing.ListingModel[]>}
   */
  getListingsByIds(ids) {
    return this.search(
      this.listingIndexPath,
      {
        query: {
          ids: { values: ids },
        },
      },
      {
        perPage: 1000,
      })
      .then(({ listings }) => listings)
      .then(listings => ids.reduce((acc, id) => {
        const listing = listings.find(listing => listing.id === id)

        if (listing) {
          acc.push(listing)
        }

        return acc
      }, []))
  }

  /**
   * @param {string} userId
   * @param {number} [page]
   * @param {number} [perPage]
   * @return {Promise<{
   *   listings: loavies.models.listing.ListingModel[],
   *   totals: loavies.models.api.ApiTotalsModel,
   * }>}
   */
  getListingsByUserId({ userId, page, perPage }) {
    return this.search(
      this.listingIndexPath,
      {
        query: {
          match: { 'author.id': userId },
        },
        sort: [
          { 'publicData.shopIndex': { order: 'desc' } },
        ],
      },
      {
        page,
        perPage,
      })
  }

  /**
   * @param {loavies.models.listing.ListingModel} listing
   * @return {Promise<loavies.models.listing.ListingModel[]>}
   */
  getRelatedListings(listing) {
    return this.search(
      this.listingIndexPath,
      {
        query: {
          bool: {
            must: [
              { match: { 'publicData.productGroup': listing.publicData.productGroup } },
            ],
            ...getStoreIsClosedFilterQuery(),
          },
        },
      })
      .then(({ listings }) => listings)
  }

  /**
   * @param {loavies.models.listing.ListingModel} listing
   * @param {number} [minimalCount]
   * @return {Promise<loavies.models.listing.ListingModel[]>}
   */
  async getComparableListings(listing, minimalCount = 5) {
    let foundListings = []

    await this.search(
      this.listingIndexPath,
      {
        query: {
          bool: {
            must: [
              { match: { 'publicData.size': listing.publicData.size } },
              { match: { 'publicData.productGroup': listing.publicData.productGroup } },
              { match: { 'publicData.color': listing.publicData.color } },
            ],
            must_not: [
              getStoreIsClosedFilterEntryQuery(),
              { match: { 'author.id': listing.author.id } },
            ],
          },
        },
      },
      {
        perPage: minimalCount - foundListings.length,
      }
    )
      .then(({ listings }) => this.handleComparableListingsResponse(foundListings, listings))

    // return listings
    if (foundListings.length >= minimalCount) {
      return foundListings
    }

    await this.search(
      this.listingIndexPath,
      {
        query: {
          bool: {
            must: [
              { match: { 'publicData.size': listing.publicData.size } },
              { match: { 'publicData.productGroup': listing.publicData.productGroup } },
            ],
            must_not: [
              getStoreIsClosedFilterEntryQuery(),
              { match: { 'author.id': listing.author.id } },
            ],
          },
        },
      },
      {
        perPage: minimalCount - foundListings.length,
      }
    )
      .then(({ listings }) => this.handleComparableListingsResponse(foundListings, listings))

    if (foundListings.length >= minimalCount) {
      return foundListings
    }

    await this.search(
      this.listingIndexPath,
      {
        query: {
          bool: {
            must: [
              { match: { 'publicData.size': listing.publicData.size } },
            ],
            must_not: [
              getStoreIsClosedFilterEntryQuery(),
              { match: { 'author.id': listing.author.id } },
            ],
          },
        },
      },
      {
        perPage: minimalCount - foundListings.length,
      }
    )
      .then(({ listings }) => this.handleComparableListingsResponse(foundListings, listings))

    return foundListings
  }

  /**
   * @param {loavies.models.listing.ListingModel[]} foundListings
   * @param {loavies.models.listing.ListingModel[]} [currentListings]
   */
  handleComparableListingsResponse(foundListings, currentListings) {
    if (!currentListings) {
      return
    }

    currentListings.forEach(listing => {
      const listingExists = foundListings.findIndex(foundListing => foundListing.id === listing.id) !== -1

      if (listingExists) {
        return
      }

      foundListings.push(listing)
    })
  }

  /**
   * @param {number} maxPrice in Euros
   * @return {Promise<loavies.models.listing.ListingModel[]>}
   */
  getListingDeals(maxPrice) {
    return this.search(
      this.listingIndexPath,
      {
        query: {
          bool: {
            ...getStoreIsClosedFilterQuery(),
            filter: [
              {
                range: {
                  ['price.amount']: { lte: maxPrice * 100 },
                },
              },
            ],
          },
        },
        collapse: {
          field: 'author.id',
        },
        sort: [
          { 'publicData.publishedAt': { order: 'desc' } },
        ],
      },
      {
        perPage: 5,
      })
      .then(({ listings }) => listings)
  }

  /**
   * @return {Promise<loavies.models.listing.ListingModel[]>}
   */
  getMostInDemandListings() {
    return this.search(
      this.listingIndexPath,
      {
        ...getStoreIsClosedQuery(),
        collapse: {
          field: 'author.id',
        },
        sort: [
          { 'publicData.wishlistCount': { order: 'desc' } },
          { 'publicData.timesWatched': { order: 'desc' } },
        ],
      })
      .then(({ listings }) => listings)
  }

  /**
   * @return {Promise<loavies.models.listing.ListingModel[]>}
   */
  getNewAdditionListings() {
    return this.search(this.listingIndexPath,
      {
        ...getStoreIsClosedQuery(),
        collapse: {
          field: 'author.id',
        },
        sort: [
          { 'publicData.publishedAt': { order: 'desc' } },
        ],
      })
      .then(({ listings }) => listings)
  }

  /**
   * @param {string} query
   * @param {Array<string>} productColorKeys
   * @param {Array<string>} productGroupKeys
   * @param {number} [page]
   * @param {number} [perPage]
   * @param {boolean} [getPreviousPages]
   * @return {Promise<{
   *   [aggregations]: Object,
   *   listings: loavies.models.listing.ListingModel[],
   *   totals: loavies.models.api.ApiTotalsModel,
   * }>}
   */
  getListingsByMultiMatchSearchQuery(
    {
      query,
      productColorKeys,
      productGroupKeys,
    },
    {
      page,
      perPage,
      getPreviousPages = false,
    } = {}
  ) {
    return this.search(
      this.listingIndexPath,
      getListingsSearchQuery({
        query,
        productColorKeys,
        productGroupKeys,
        queryFields: [
          'author.publicData.storeName',
          'title',
        ],
      }),
      {
        page: getPreviousPages ? 1 : page,
        perPage: getPreviousPages ? this.defaultPerPage * page : perPage,
      }
    )
  }
}
