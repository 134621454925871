import axios from 'axios'
import axiosRateLimit from 'axios-rate-limit'
import { transformDatoData } from './dato-api-mappers'

/**
 * @typedef {DatoApiService}
 * @alias this.$datoApiService
 */
export class DatoApiService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.config = context.$config
    this.locale = context.i18n.locale

    this.apiToken = this.config.datoApiToken
    this.datoMaxRequests = 60
    this.datoMaxRequestTimeMs = 3000
    this.datoRateLimitHeader = 'x-ratelimit-reset'
    this.datoAxiosInstance = this.createDatoAxiosInstance()
  }

  /**
   * Vue router route
   * https://nuxtjs.org/docs/internals-glossary/context/#route
   * @param {Object} route
   * @return {Promise<*>}
   */
  async getPageData(route) {
    const { slug, isContentPage } = route.params
    const routeName = route.name.match('.*(?=___)')?.[0] || route.name
    const variables = {
      locale: this.locale,
      slug,
    }
    const queryPath = this.getQueryPathFromRouteName({ routeName, slug, isContentPage })
    const query = await this.readQuery(queryPath)
    const options = {
      variables,
    }

    return this.query(query, options)
  }

  /**
   * @param {string} queryPath
   * @param {string} slug
   */
  async getPageDataWithCustomQueryPath(queryPath, slug) {
    const query = await this.readQuery(queryPath)
    const variables = {
      locale: this.locale,
      slug,
    }
    const options = {
      variables,
    }

    return this.query(query, options)
  }

  /**
   * @param {string} routeName
   * @param {string} slug
   * @param {boolean} isContentPage
   * @return {string|*}
   */
  getQueryPathFromRouteName({ routeName, slug, isContentPage = false }) {
    const isHomepage = routeName === 'index'

    if (isHomepage) {
      return routeName
    }

    if (isContentPage) {
      return 'content/index'
    }

    if (slug) {
      const [slugRouteName] = routeName.split('-slug')
      return `${slugRouteName}/_slug`
    }

    return `${routeName}/index`
  }

  /**
   * @param {string} path
   * @return {Promise<*>}
   */
  async readQuery(path) {
    return await import(`~/pages/${path}.query.js`).then(module => module.default)
  }

  /**
   * @returns {Object}
   */
  getAuthorizationConfig() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.apiToken}`,
      },
    }
  }

  /**
   * Create axios instance with rate limit
   * @return {Object}
   */
  createDatoAxiosInstance() {
    const datoInstance = axios.create(this.getAuthorizationConfig())

    datoInstance.interceptors.request.use(
      config => {
        return {
          ...config,
          ...this.getAuthorizationConfig(),
        }
      },
      error => Promise.reject(error))

    /*
      We define an interceptor for "429: Too Many Requests"-error.

      Dato responds with a header which state after how many seconds
      the server can be queried again. The query is called recursively after this time.
     */
    datoInstance.interceptors.response.use(
      response => response,
      async error => {
        const is429Error = error.config && error.response?.status === 429

        if (is429Error) {
          const rateLimitSeconds = error.response.headers[this.datoRateLimitHeader]
          const milliseconds = rateLimitSeconds * 1000

          console.warn(`DatoCMS: ${error.response.statusText}: Retrying after ${rateLimitSeconds}s`)

          await this.asyncTimeout(milliseconds)

          return datoInstance.request(error.config)
        }

        return Promise.reject(error)
      }
    )

    return axiosRateLimit(datoInstance, {
      maxRequests: this.datoMaxRequests,
      perMilliseconds: this.datoMaxRequestTimeMs,
    })
  }

  /**
   * @param {number} ms
   * @return {Promise<*>}
   */
  asyncTimeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  /**
   * @param {string} query
   * @param {Object} options
   * @return {Promise<*>}
   */
  query(query, options) {
    const variables = options?.variables

    return this.datoAxiosInstance
      .post(this.getDatoUrl(), JSON.stringify({ query, variables }))
      .then(response => {
        const isValidResponse = !response?.data?.errors && this.hasValidStatus(response)

        if (response.data?.errors) {
          return Promise.reject(response.data.errors)
        }

        if (!isValidResponse) {
          return Promise.reject(response.data)
        }

        // Warning: data is transformed by reference
        return transformDatoData(response.data.data)
      })
  }

  /**
   * @return {string}
   */
  getDatoUrl() {
    const flags = this.config.datoCliFlags?.split(' ')
    const showDrafts = flags?.includes('--preview') ?? false
    const sandboxEnvironment = flags?.find(item => item.includes('--environment'))?.split('=')[1] ?? null

    let url = 'https://graphql.datocms.com'

    if (sandboxEnvironment) {
      url += `/environments/${sandboxEnvironment}`
    }

    if (showDrafts) {
      url += '/preview'
    }

    return url
  }

  /**
   * @param {Object} response
   * @return {boolean}
   */
  hasValidStatus(response) {
    return response && response.status >= 200 && response.status < 300
  }
}
