export const AGGREGATION_FILTER_KEYS = {
  SIZE: 'publicData.size',
  MODEL: 'publicData.productGroup',
  CONDITION: 'publicData.condition',
  COLOR: 'publicData.color',
  LABEL: 'publicData.labelId',
  SELLER_BADGES: 'author.publicData.userLabels',
}

export const AGGREGATION_PRICE_FILTERS = [
  { key: 'min_price', type: 'min', field: 'price.amount' },
  { key: 'max_price', type: 'max', field: 'price.amount' },
]

/**
 * @param {Object} [activeCategory]
 * @return {Object}
 */
export function getProductGroupsQuery(activeCategory) {
  const productGroups = getProductGroupsFromActiveCategory(activeCategory)

  if (!productGroups) {
    return {}
  }

  return {
    query: {
      terms: {
        'publicData.productGroup': productGroups,
      },
    },
  }
}

/**
 * @param {Object} [activeCategory]
 * @return {undefined|string[]}
 */
function getProductGroupsFromActiveCategory(activeCategory) {
  if (!activeCategory || activeCategory?.showAllListings) {
    return
  }

  if (activeCategory.subcategories?.length) {
    const combinedProductGroups = [...activeCategory.productGroups]

    activeCategory.subcategories.forEach(subcategory => {
      combinedProductGroups.push(...subcategory.productGroups)
    })

    return [...new Set(combinedProductGroups)]
  }

  return activeCategory.productGroups
}

/**
 * @param {loavies.models.listing.ListingFilter[]} [activeFilters]
 * @param {loavies.models.listing.ListingPriceFilter[]} [activePriceFilters]
 * @return {Object}
 */
export function getActiveFiltersQuery({ activeFilters = [], activePriceFilters = [] }) {
  if (!activeFilters.length && !activePriceFilters.length) {
    return {}
  }

  return {
    post_filter: {
      bool: {
        filter: [
          ...activePriceFilters.map(priceFilter => getPriceFilterQuery(priceFilter)),
          ...activeFilters.map(filter => getFilterQuery(filter)),
        ],
      },
    },
  }
}

/**
 * @param {loavies.models.listing.ListingFilter[]} [activeFilters]
 * @param {loavies.models.listing.ListingPriceFilter[]} [activePriceFilters]
 * @return {Object}
 */
export function getAggregationsQuery({ activeFilters = [], activePriceFilters = [] }) {
  return {
    aggs: {
      ...AGGREGATION_PRICE_FILTERS.reduce((acc, priceFilter) => {
        acc[priceFilter.key] = getPriceAggregationQuery(priceFilter)

        return acc
      }, {}),
      ...Object.values(AGGREGATION_FILTER_KEYS).reduce((acc, aggregationKey) => {
        acc[aggregationKey] = getAggregationQuery({
          aggregationKey,
          activeFilters,
          activePriceFilters,
        })

        return acc
      }, {}),
    },
  }
}

/**
 * @param {Object} priceFilter
 * @return {Object}
 */
function getPriceAggregationQuery(priceFilter) {
  return {
    [priceFilter.type]: {
      field: priceFilter.field,
    },
  }
}

/**
 * @param {string} aggregationKey
 * @param {loavies.models.listing.ListingFilter[]} [activeFilters]
 * @param {loavies.models.listing.ListingPriceFilter[]} [activePriceFilters]
 * @return {Object}
 */
function getAggregationQuery({ aggregationKey, activeFilters = [], activePriceFilters = [] }) {
  // There needs to be checked if filters are active which are not the same as the aggregation.
  // So this aggregation gets filtered with the other active filters.
  const otherActiveFilters = activeFilters.filter(activeFilter => activeFilter.key !== aggregationKey) ?? []

  if (!otherActiveFilters.length && !activePriceFilters.length) {
    return {
      terms: {
        field: aggregationKey,
        size: 1000,
      },
    }
  }

  return {
    filter: {
      bool: {
        filter: [
          ...activePriceFilters.map(priceFilter => getPriceFilterQuery(priceFilter)),
          ...otherActiveFilters.map(activeFilter => getFilterQuery(activeFilter)),
        ],
      },
    },
    aggs: {
      [aggregationKey]: {
        terms: {
          size: 1000,
          field: aggregationKey,
        },
      },
    },
  }
}

/**
 * @param {loavies.models.listing.ListingSortOption} [activeSortOption]
 * @return {Object}
 */
export function getSortOptionQuery(activeSortOption) {
  if (!activeSortOption) {
    return {}
  }

  return {
    sort: [
      {
        [activeSortOption.key]: {
          order: activeSortOption.order,
        },
      },
    ],
  }
}

/**
 * @param {loavies.models.listing.ListingFilter} filter
 * @return {Object}
 */
export function getFilterQuery(filter) {
  return {
    terms: {
      [filter.key]: filter.options.map(option => option.key),
    },
  }
}

/**
 * @param {loavies.models.listing.ListingPriceFilter} priceFilter
 * @return {Object}
 */
export function getPriceFilterQuery(priceFilter) {
  const PRICE_FILTER_TYPE_TO_PARAMETER_MAPPING = {
    min: 'gte',
    max: 'lte',
  }

  return {
    range: {
      [priceFilter.field]: {
        [PRICE_FILTER_TYPE_TO_PARAMETER_MAPPING[priceFilter.type]]: priceFilter.amount,
      },
    },
  }
}

// If More functionality of match multi query is needed, this wil need refactoring
// https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-multi-match-query.html

/**
 * @param {string} query
 * @param {Array<string>} productColorKeys
 * @param {Array<string>} productGroupKeys
 * @param {string[]} queryFields
 */
export function getListingsSearchQuery({
  query,
  productColorKeys,
  productGroupKeys,
  queryFields,
}) {
  if (!productColorKeys.length && !productGroupKeys.length) {
    return {
      query: {
        bool: {
          must: {
            multi_match: {
              query,
              fields: queryFields,
              ...(query.length > 3 && { fuzziness: 2 }),
            },
          },
          must_not: {
            ...getStoreIsClosedFilterEntryQuery(),
          },
        },
      },
    }
  }

  if (productColorKeys.length === 1) {
    return singleColorQuery({ productColorKey: productColorKeys[0], productGroupKeys })
  }

  if (productGroupKeys.length === 1) {
    return singleProductGroupQuery({ productColorKeys, productGroupKey: productGroupKeys[0] })
  }

  return multipleColorOrProductGroupQuery({ productColorKeys, productGroupKeys })
}

/**
 * @param {string} productColorKey
 * @param {Array<string>} productGroupKeys
 * @return {Object}
 */
function singleColorQuery({ productColorKey, productGroupKeys }) {
  const must = [
    {
      term: {
        'publicData.color': productColorKey,
      },
    },
  ]

  if (productGroupKeys?.length > 0) {
    must.push({
      bool: {
        should: [
          ...productGroupKeys.map(productGroup => {
            return {
              match: {
                'publicData.productGroup': productGroup,
              },
            }
          }),
        ],
      },
    })
  }

  return {
    query: {
      bool: {
        must,
        must_not: {
          ...getStoreIsClosedFilterEntryQuery(),
        },
      },
    },
  }
}

/**
 * @param {Array<string>} productColorKeys
 * @param {string} productGroupKey
 * @return {Object}
 */
function singleProductGroupQuery({ productColorKeys, productGroupKey }) {
  const must = [
    {
      term: {
        'publicData.productGroup': productGroupKey,
      },
    },
  ]

  if (productColorKeys?.length > 0) {
    must.push({
      bool: {
        should: [
          ...productColorKeys.map(color => {
            return {
              match: {
                'publicData.color': color,
              },
            }
          }),
        ],
      },
    })
  }

  return {
    query: {
      bool: {
        must,
        must_not: {
          ...getStoreIsClosedFilterEntryQuery(),
        },
      },
    },
  }
}

/**
 * @param {Array<string>} productColorKeys
 * @param {Array<string>} productGroupKeys
 * @return {Object}
 */
function multipleColorOrProductGroupQuery({ productColorKeys, productGroupKeys }) {
  return {
    query: {
      bool: {
        must: {
          dis_max: {
            queries: [
              ...productColorKeys.map(color => {
                return {
                  term: {
                    'publicData.color': color,
                  },
                }
              }),
              ...productGroupKeys.map(productGroup => {
                return {
                  term: {
                    'publicData.productGroup': productGroup,
                  },
                }
              }),
            ],
          },
        },
        must_not: {
          ...getStoreIsClosedFilterEntryQuery(),
        },
      },
    },
  }
}

/**
 * @returns {Object}
 */
export function getStoreIsClosedFilterEntryQuery() {
  return {
    term: {
      'author.publicData.storeIsClosed': true,
    },
  }
}

/**
 * @returns {Object}
 */
export function getStoreIsClosedFilterQuery() {
  return {
    must_not: {
      ...getStoreIsClosedFilterEntryQuery(),
    },
  }
}

/**
 * @returns {Object}
 */
export function getStoreIsClosedQuery() {
  return {
    query: {
      bool: {
        ...getStoreIsClosedFilterQuery(),
      },
    },
  }
}
