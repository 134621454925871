import axios from 'axios'

/**
 * @typedef {NodeApiService}
 * @alias this.$nodeApiService
 */
export class NodeApiService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.config = context.$config

    this.instance = this.getAxiosInstance()
    this.maxRetries = 1
    this.retries = {}
  }

  init() {
    this.$marketplaceAuthenticationService = this.context.$marketplaceAuthenticationService
  }

  /**
   * @returns {Object} AxiosInstance
   */
  getAxiosInstance() {
    const axiosInstance = axios.create({
      baseURL: this.config.marketplaceApiUrl,
      headers: {
        apiKey: this.config.marketplaceApiKey,
      },
    })

    this.setUnauthorizedInterceptor(axiosInstance)
    this.setTokenInterceptor(axiosInstance)

    return axiosInstance
  }

  /**
   * @param {Object} [tokenDetails]
   * @returns {Object}
   */
  getAuthorizationConfig(tokenDetails) {
    return {
      headers: {
        Authorization: tokenDetails?.access_token,
        AuthorizationDetails: tokenDetails ? JSON.stringify(tokenDetails) : null,
      },
    }
  }

  /**
   * @param {Object} axiosInstance
   * @returns {void}
   */
  setTokenInterceptor(axiosInstance) {
    axiosInstance.interceptors.request.use(async config => {
      let tokenDetails = this.getTokenDetails()

      if (!tokenDetails) return config

      const { headers } = this.getAuthorizationConfig(tokenDetails)

      config.headers = {
        ...config.headers,
        ...headers,
      }

      return config
    }, error => {
      // Do something with request error
      return Promise.reject(error)
    })
  }

  /**
   * @param {Object} axiosInstance
   * @returns {void}
   */
  setUnauthorizedInterceptor(axiosInstance) {
    axiosInstance.interceptors.response.use(
      response => response,
      async error => {
        if (
          !error.config
          || error.response?.status !== 401
        ) {
          return Promise.reject(error)
        }

        const url = error.config.url

        // Check if request url has existing entry in retries Object, otherwise add it
        if (isNaN(parseFloat(this.retries[url]))) {
          this.retries[url] = 0
        }

        // If retry attempt passed maximum reties return rejection
        if (this.retries[url] >= this.maxRetries) {
          delete this.retries[url]

          return Promise.reject(error)
        }

        this.retries[url] += 1

        await this.updateToken()

        const { headers } = this.getAuthorizationConfig()

        error.config.headers = {
          ...error.config.headers,
          ...headers,
        }

        return axiosInstance
          .request(error.config)
          .then(response => {
            // Remove retries entry after successful try
            delete this.retries[url]

            return response
          })
      }
    )
  }

  /**
   * @returns {Promise}
   */
  updateToken() {
    return this.$marketplaceAuthenticationService.refreshToken()
  }

  /**
   * @returns {Object}
   */
  getTokenDetails() {
    return this.$marketplaceAuthenticationService.getTokenDetails()
  }

  /**
   * @param {Object} error
   * @returns {Object}
   */
  handleValidationErrors(error) {
    if (error?.response?.data?.data?.validationErrors) {
      const validationErrors = error.response.data.data.validationErrors

      return Promise.reject(Object.keys(validationErrors).map(key => validationErrors[key]).flat())
    }

    return Promise.reject(error)
  }
}
