import { APP_MODAL_NAMES, APP_TRAY_NAMES, OVERLAYS } from '~/lib/constants/overlays'

export default {
  /**
   * @param context
   * @param {string} transactionId
   * @return {Promise<void>}
   */
  completeByCustomer(context, transactionId) {
    return this.$microserviceEventService.orderCompleted(transactionId)
      .then(() => this.$marketplaceTransitionService.completeByCustomer(transactionId))
      .then(() => context.dispatch('transactions/updateTransactionById', transactionId, { root: true }))
      .then(() => context.dispatch('events/fetchUserTransactionEvents', null, { root: true }))
      .catch(error => context.dispatch('toast/showToastMessage', {
        text: error.message,
        icon: 'IconClose',
      }, { root: true }))
  },

  /**
   * @param context
   * @param {string} transactionId
   * @return {Promise<void>}
   */
  disputeByCustomer(context, transactionId) {
    return this.$microserviceEventService.disputeStarted(transactionId)
      .then(() => this.$marketplaceTransitionService.disputeByCustomer(transactionId))
      .then(async () => {
        await context.dispatch('transactions/updateTransactionById', transactionId, { root: true })
        return context.dispatch(
          'overlay/setCurrentOverlay',
          {
            settings: {
              options: {
                transactionId: transactionId,
              },
            },
            mobile: {
              type: OVERLAYS.APP_TRAY,
              component: APP_TRAY_NAMES.ORDER_CUSTOMER_DISPUTE_APP_TRAY,
            },
            desktop: {
              type: OVERLAYS.APP_MODAL,
              component: APP_MODAL_NAMES.ORDER_CUSTOMER_DISPUTE_APP_MODAL,
            },
          },
          { root: true })
      })
      .catch(error => context.dispatch('toast/showToastMessage', {
        text: error.message,
        icon: 'IconClose',
      }, { root: true }))
  },

  /**
   * @param context
   * @param {string} transactionId
   * @return {Promise<void>}
   */
  resolveDispute(context, transactionId) {
    return this.$microserviceEventService.orderCompleted(transactionId)
      .then(() => this.$marketplaceTransitionService.disputeResolvedByCustomer(transactionId))
      .then(() => context.dispatch('transactions/updateTransactionById', transactionId, { root: true }))
      .catch(error => context.dispatch('toast/showToastMessage', {
        text: error.message,
        icon: 'IconClose',
      }, { root: true }))
  },

  /**
   * @param context
   * @param {string} transactionId
   * @return {Promise<void>}
   */
  refundAfterDisputeByProvider(context, transactionId) {
    return this.$microserviceEventService
      .orderRefund(transactionId)
      .then(() => context.dispatch('transactions/updateTransactionById', transactionId, { root: true }))
      .catch(error => context.dispatch('toast/showToastMessage', {
        text: error.message,
        icon: 'IconClose',
      }, { root: true }))
  },
}
