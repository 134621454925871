export default {
  /**
   * @param state
   * @param {boolean} isDesktop
   */
  SET_IS_DESKTOP(state, isDesktop) {
    state.isDesktop = isDesktop
  },

  /**
   * @param state
   * @param {boolean} isMobile
   */
  SET_IS_MOBILE(state, isMobile) {
    state.isMobile = isMobile
  },

  /**
   * @param state
   * @param {boolean} isHoverSupported
   */
  SET_IS_HOVER_SUPPORTED(state, isHoverSupported) {
    state.isHoverSupported = isHoverSupported
  },

  /**
   * @param state
   * @param {boolean} mediaQueryInitialized
   */
  SET_MEDIA_QUERY_INITIALIZED(state, mediaQueryInitialized) {
    state.mediaQueryInitialized = mediaQueryInitialized
  },

  /**
   * @param state
   * @param {boolean} showErrors
   */
  SET_SHOW_ERRORS(state, showErrors) {
    state.showErrors = showErrors
  },

  /**
   * @param state
   * @param {boolean} showLargeProductCards
   */
  SET_SHOW_LARGE_PRODUCT_CARDS(state, showLargeProductCards) {
    state.showLargeProductCards = showLargeProductCards
  },

  /**
   * @param state
   * @param {boolean} isIOS
   */
  SET_IS_IOS(state, isIOS) {
    state.isIOS = isIOS
  },
}
