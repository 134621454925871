import Vue from 'vue'
import { sortListingsByCreatedAt } from '@loavies/c2c-marketplace-lib'

export default {
  /**
   * @param state
   * @param {loavies.models.user.UserModel} user
   */
  SET_USER(state, user) {
    state.user = user
  },

  /**
   * @param state
   * @param {boolean} isInitialized
   */
  SET_IS_INITIALIZED(state, isInitialized) {
    state.isInitialized = isInitialized
  },

  /**
   * @param state
   * @param {loavies.models.listing.ListingModel[]} listings
   */
  SET_LISTINGS(state, listings) {
    state.listings = listings
  },

  /**
   * @param state
   * @param {loavies.models.user.UserModel[]} followingUsers
   */
  SET_FOLLOWING_USERS(state, followingUsers) {
    state.followingUsers = followingUsers
  },

  /**
   * @param state
   * @param {loavies.models.api.ApiTotalsModel} totals
   */
  SET_FOLLOWING_USERS_TOTALS(state, totals) {
    state.followingUsersTotals = totals
  },

  /**
   * @param state
   */
  DECREASE_FOLLOWING_USERS_TOTALS_TOTAL_ITEMS_BY_ONE(state) {
    state.followingUsersTotals.totalItems -= 1
  },

  /**
   * @param state
   * @param {loavies.models.listing.ListingModel} listing
   */
  ADD_LISTING(state, listing) {
    state.listings = sortListingsByCreatedAt([...state.listings, listing])
  },

  /**
   * @param state
   * @param {number} index
   * @param {loavies.models.listing.ListingModel} listing
   */
  UPDATE_LISTING_BY_INDEX(state, { index, listing }) {
    Vue.set(state.listings, index, listing)
  },

  /**
   * @param state
   * @param {number} sequenceId
   */
  UPDATE_LAST_EVENTS_SEQUENCE_IDS(state, sequenceId) {
    state.user.privateData.lastEventSequenceIdMessageRead = sequenceId
    state.user.privateData.lastEventSequenceIdNotificationShown = sequenceId
  },

  /**
   * @param state
   * @param {string} gridLayout
   */
  SET_USER_GRID_LAYOUT(state, gridLayout) {
    state.user.publicData.storeGridType = gridLayout
  },
}
