import { mapPaymentMethods } from '~/lib/services/api/microservice-api-mappers'

/**
 * @typedef {PaymentHandlerService}
 * @alias this.$paymentHandlerService
 */
export class PaymentHandlerService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.i18n = context.i18n
  }

  init() {
    this.$microserviceApiService = this.context.$microserviceApiService
    this.$staticDataService = this.context.$staticDataService

    this.locales = this.$staticDataService.cmsApp.locales
    this.microserviceApiInstance = this.$microserviceApiService.instance
  }

  /**
   * @returns {string}
   */
  #getLocaleIso() {
    return this.locales.find(currentLocale => currentLocale.code === this.i18n.locale).iso
  }

  /**
   * @param {loavies.models.base.PriceModel} listingPrice
   * @param {string} [countryCode]
   * @return {Promise<loavies.models.payment.PaymentMethodModel[]>}
   */
  getPaymentMethods({ listingPrice, countryCode = 'NL' }) {
    return this.microserviceApiInstance
      .post('/payment/method/list', {
        countryCode,
        shopperLocale: this.#getLocaleIso(),
        amount: {
          currency: listingPrice.currency,
          value: listingPrice.amount,
        },
      })
      .then(response => mapPaymentMethods(response.data.paymentMethods))
  }
}
