/**
 * Recently watched listing ids are fetched from session storage
 * and corresponding listings are re-instated in the store
 * @param context
 */
export default context => {
  const recentlyWatchedListingIds = context.$clientStorageService.getRecentlyWatchedListingIds()

  if (recentlyWatchedListingIds.length) {
    // Warning: first 1000 listings are returned only
    context.$elasticApiService
      .getListingsByIds(recentlyWatchedListingIds)
      .then(listings => {
        // Filter out listing id's which don't exist anymore or are not returned by the API
        const filteredListings = recentlyWatchedListingIds.reduce((acc, listingId) => {
          const listing = listings.find(listing => listing.id === listingId)

          if (listing) {
            acc.push(listing)
          }

          return acc
        }, [])

        context.store.dispatch('listings/setRecentlyWatchedListings', filteredListings)
      })
      // In case of an error, recently watched listings array is not filled
      .catch(() => {})
  }
}
