/**
 * @typedef {ReviewsHelperService}
 * @alias this.$reviewsHelperService
 */
export class ReviewsHelperService {
  /**
   * @param {loavies.models.review.ReviewModel[]} reviews
   * @returns {number}
   */
  getAverageRatingFromReviews(reviews) {
    const ratingSum = reviews.reduce((sum, review) => sum += review.rating, 0)

    return parseFloat((ratingSum / reviews.length).toFixed(1))
  }
}
