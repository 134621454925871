export default {
  /**
   * @param state
   * @param {boolean} isOpen
   */
  SET_IS_OPEN(state, isOpen) {
    state.isOpen = isOpen
  },

  /**
   * @param state
   * @param {string} text
   */
  SET_TEXT(state, text) {
    state.text = text
  },

  /**
   * @param state
   * @param {string} icon
   */
  SET_ICON(state, icon) {
    state.icon = icon
  },
}
