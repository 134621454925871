import { ApiTotalsModel, WALLET_STATES } from '@loavies/c2c-models'
import {
  mapWallet,
  mapWalletTransfers,
  mapWalletUser,
} from '~/lib/services/api/microservice-api-mappers'

/**
 * @typedef {WalletHandlerService}
 * @alias this.$walletHandlerService
 */
export class WalletHandlerService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.i18n = context.i18n
  }

  init() {
    this.$microserviceApiService = this.context.$microserviceApiService

    this.microserviceApiInstance = this.$microserviceApiService.instance
  }

  /**
   * @param {loavies.models.wallet.CreateWalletModel} createWallet
   * @returns {Promise}
   */
  activateWallet(createWallet) {
    return this.microserviceApiInstance
      .post('/payment/user', createWallet)
      .then(response => response.data.data)
      .then(data => {
        if (data.onboardingState !== WALLET_STATES.COMPLETE) {
          return Promise.reject(Error(this.i18n.t('something_went_wrong_try_again')))
        }

        return data
      })
      .catch(error => this.$microserviceApiService.handleValidationErrors(error))
  }

  /**
   * @return {Promise<loavies.models.wallet.WalletModel>}
   */
  getWallet() {
    return this.microserviceApiInstance
      .get('/payment/wallet/balance')
      .then(response => response.data.data.find(wallet => wallet.type === 'Earnings'))
      .then(wallet => mapWallet(wallet))
  }

  /**
   * @returns {Promise<loavies.models.wallet.WalletUserModel>}
   */
  getWalletUser() {
    return this.microserviceApiInstance
      .get('/payment/user')
      .then(response => response.data.data)
      .then(user => mapWalletUser(user))
  }

  /**
   * @param {loavies.models.wallet.UpdateWalletUserModel} updateWalletUser
   * @returns {Promise}
   */
  updateWalletUser(updateWalletUser) {
    return this.microserviceApiInstance
      .patch('/payment/user/transfer-instrument', updateWalletUser)
  }

  /**
   * @params {number} [page]
   * @params {number} [perPage]
   * @returns {Promise<{
   *   transfers: loavies.models.wallet.WalletTransferModel[],
   *   totals: loavies.models.api.ApiTotalsModel,
   * }>}
   */
  getWalletTransfers({ page, perPage } = {}) {
    const queryParams = new URLSearchParams({
      ...(page && { page }),
      ...(perPage && { perPage }),
    }).toString()
    let url = '/payment/wallet/transactions'

    if (queryParams.length) {
      url += `?${queryParams}`
    }

    return this.microserviceApiInstance
      .get(url)
      .then(response => response.data)
      .then(response => ({
        transfers: mapWalletTransfers(response.data),
        totals: new ApiTotalsModel({
          totalItems: null,
          totalPages: response.pages,
          page: response.current,
        }),
      }))
  }

  /**
   * @param {Number} amount
   * @returns {Promise}
   */
  payoutBalance(amount) {
    return this.microserviceApiInstance
      .post('/payment/wallet/payout', {
        amount,
      })
  }
}
