import { render, staticRenderFns } from "./app-footer-menu-items.vue?vue&type=template&id=6b82efe5"
import script from "./app-footer-menu-items.vue?vue&type=script&lang=js"
export * from "./app-footer-menu-items.vue?vue&type=script&lang=js"
import style0 from "./app-footer-menu-items.vue?vue&type=style&index=0&id=6b82efe5&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmartLink: require('/opt/build/repo/components/smart-link/smart-link.vue').default})
