/**
 * @typedef {UserHelperService}
 * @alias this.$userHelperService
 */
export class UserHelperService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.i18n = context.i18n
  }

  /**
  * @param {loavies.models.user.UserModel} user
  * @returns {string}
  */
  getFormattedStoreName(user) {
    if (user.publicData.storeName && user.publicData.storeName.trim().length > 0) {
      return user.publicData.storeName
    }

    if (user.displayName && user.displayName.trim().length > 0) {
      `${user.displayName}${this.i18n.t('shop_name_extension')}`
    }

    return `${user.abbreviatedName}${this.i18n.t('shop_name_extension')}`
  }

  /**
   * @param {loavies.models.user.UserModel} user
   * @returns {string}
   */
  getFormattedDisplayName(user) {
    return user.displayName && user.displayName.trim().length > 0
      ? `@${user.displayName}`
      : `@${user.abbreviatedName}`
  }

  /**
   * @param {loavies.models.user.UserModel} user
   * @return {string}
   */
  getFormattedName(user) {
    if (user.firstName && user.firstName.trim().length > 0) {
      return user.firstName
    }

    if (user.displayName && user.displayName.trim().length > 0) {
      return user.displayName
    }

    return user.abbreviatedName
  }
}
