export default {
  /**
   * @param context
   * @param {string} currentRoutePath
   */
  setCurrentRoutePath(context, currentRoutePath) {
    context.commit('SET_CURRENT_ROUTE_PATH', currentRoutePath)
  },

  /**
   * @param context
   * @param {string} previousRouteName
   */
  setPreviousRouteName(context, previousRouteName) {
    context.commit('SET_PREVIOUS_ROUTE_NAME', previousRouteName)
  },

  /**
   * @param context
   * @param {string} previousRoutePath
   */
  setPreviousRoutePath(context, previousRoutePath) {
    context.commit('SET_PREVIOUS_ROUTE_PATH', previousRoutePath)
  },

  /**
   * @param context
   * @param {string} previousCategoryRoutePath
   */
  setPreviousCategoryRoutePath(context, previousCategoryRoutePath) {
    context.commit('SET_PREVIOUS_CATEGORY_ROUTE_PATH', previousCategoryRoutePath)
  },
}
