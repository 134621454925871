/**
 * @typedef {ResponsiveImageService}
 * @alias this.$responsiveImageService
 */
export class ResponsiveImageService {
  constructor() {
    this.defaultImageQuality = 75
    this.defaultResolutions = [
      2,
      3,
      4,
    ]
  }

  /**
   * Format image source URL for Dato's image resizing.
   *
   * @param {string} url
   * @param {number} width
   * @return {string}
   */
  getDatoImageResized({ url, width }) {
    return `${url}?w=${width}&q=${this.defaultImageQuality}`
  }

  /**
   * Format image source URL for Cloudflare's image resizing.
   *
   * @param {string} url
   * @param {number} width
   * @param {number} resolution
   * @param {number} quality
   * @return {string}
   */
  getResizeUrl({ url, width, resolution = 1, quality = this.defaultImageQuality }) {
    // Regex to extract the baseURL (i.e. loavies.com) and the filepath (i.e. /media/catalog/product/1/1/12312_V1.jpg)
    // from the url, so we can stitch it together with the Cloudflare URL.
    const getBaseUrlAndFilePathRegex =  new RegExp(/(^https?:\/\/[^#?\/]+)(.+)/)

    const matches = getBaseUrlAndFilePathRegex.exec(url)
    const baseUrl = matches && matches[1]
    const filePath = matches && matches[2]

    if (!baseUrl || !filePath) {
      return url
    }

    // Cloudflare can multiply the width if a dpr is given, so you don't have to do the math yourself
    // SOURCE: https://developers.cloudflare.com/image-resizing/url-format
    return `${baseUrl}/cdn-cgi/image/format=auto,sharpen=0.3,width=${width},dpr=${resolution},quality=${quality}${filePath}`
  }

  /**
   * Gets srcset for responsive images using different widths based
   * on the viewport.
   *
   * @param {string} url
   * @param {number[]} sizes
   * @return {string} some-image-url 200w
   */
  getSrcsetByWidths({ url = '', widths }) {
    return widths
      .map(width => `${this.getResizeUrl({ url, width })} ${width}w`)
      .filter((width, index, widths) => widths.indexOf(width) === index)
      .join(', ')
  }

  /**
   * Gets srcset for responsive images using the same width independent of
   * the viewport but uses different images based on the users' screen
   * resolution (dpr).
   *
   * @param {string} url
   * @param {number[]} resolutions
   * @param {number} width
   * @return {string} some-image-url 2x
   */
  getSrcsetByDensity({ url = '', resolutions = this.defaultResolutions, width }) {
    return [
      // Base image
      this.getResizeUrl({ url, width }),

      // Resolution variants
      ...resolutions.map(resolution => `${this.getResizeUrl({ url, width, resolution })} ${resolution}x`),
    ].join(', ')
  }
}
