import {
  UserAddressModel,
  ShipmentCapabilityModel,
  PostalServicePointModel,
  PostalServicePointAddressModel,
  PostalServicePointDistanceModel,
  PostalServicePointOpeningHoursDayModel,
  ProductModel,
  PriceModel,
  PRODUCT_STATUS,
  PaymentMethodModel,
  CREDIT_CARD_NAMES,
  PaymentMethodIssuerModel,
  WalletModel,
  WalletTransferModel,
  WalletUserModel,
} from '@loavies/c2c-models'

/**
 * @param {Object} address
 * @returns {loavies.models.user.UserAddressModel}
 */
export function mapUserAddress(address) {
  return new UserAddressModel({
    id: address.id,
    firstName: address.firstName,
    lastName: address.lastName,
    countryId: address.countryId,
    postcode: address.postcode,
    city: address.city,
    street: address.street,
    number: address.number,
    addition: address.addition,
    additionalAddressLine: address.additionalAddressLine,
    defaultShipping: Boolean(address.defaultShipping),
  })
}

/**
 * @param {Object} capability
 * @returns {loavies.models.shipment.ShipmentCapabilityModel}
 */
export function mapShipmentCapability(capability) {
  return new ShipmentCapabilityModel({
    carrier: capability.carrier,
    service: capability.service,
    size: capability.size,
    fromCountryCode: capability.fromCountryCode,
    toCountryCode: capability.toCountryCode,
    type: capability.type,
    price: capability.price,
    serviceOptions: capability.serviceOptions,
  })
}

/**
 * @param {Array<Object>} servicePoints
 * @returns {loavies.models.postal.PostalServicePointModel[]}
 */
export function mapPostalServicePoints(servicePoints) {
  return servicePoints.map(servicePoint => new PostalServicePointModel({
    address: new PostalServicePointAddressModel({
      city: servicePoint.address.city,
      number: servicePoint.address.number,
      street: servicePoint.address.street,
    }),
    carrier: servicePoint.carrier,
    distance: new PostalServicePointDistanceModel({
      unit: servicePoint.distance.unit,
      value: servicePoint.distance.value,
    }),
    name: servicePoint.name,
    openingHours: mapPostalServicePointOpeningHours(servicePoint.openingHours),
  }))
}

/**
 * @param {Object[]} openingHours
 * @returns {loavies.models.postal.PostalServicePointOpeningHoursDayModel[]}
 */
export function mapPostalServicePointOpeningHours(openingHours) {
  return openingHours.map(openingHour => new PostalServicePointOpeningHoursDayModel({
    day: openingHour.day,
    dayOfWeek: openingHour.dayOfWeek,
    isClosed: openingHour.isClosed,
    timeFrom: openingHour.timeFrom,
    timeTo: openingHour.timeTo,
  }))
}

/**
 * @param {Object} product
 * @return {loavies.models.product.ProductModel}
 */
export function mapProduct(product) {
  return new ProductModel({
    id: product.id,
    sku: product.sku,
    title: product.title,
    subtitle: product.subtitle,
    purchasePrice: new PriceModel({
      amount: product.purchasePrice.amount,
      currency: product.purchasePrice.currency,
    }),
    images: product.images.map(image => image.url),
    color: product.color?.value,
    productGroup: product.productGroup?.value,
    size: product.size?.label,
    parentSku: product.parentSku,
    purchaseDate: new Date(product.purchaseDate),
    createdAt: new Date(product.createdAt),
    updatedAt: new Date(product.updatedAt),
    status: mapProductStatus(product.status),
  })
}

/**
 * @param {Object} status
 * @return {null|'sold'|'available'|'listed'|'draft'|'notInPossession'}
 */
export function mapProductStatus(status) {
  if (
    !status.value
    || !Object.values(PRODUCT_STATUS).includes(status.value)
  ) {
    return null
  }

  return status.value
}

/**
 * @param {Object[]} paymentMethods
 * @return {loavies.models.payment.PaymentMethodModel[]}
 */
export function mapPaymentMethods(paymentMethods) {
  const mappedPaymentMethods = []

  paymentMethods.forEach(paymentMethod => {
    if (paymentMethod.type === 'scheme') {
      return paymentMethod.brands.forEach(brand => {
        mappedPaymentMethods.push(new PaymentMethodModel({
          type: brand,
          name: CREDIT_CARD_NAMES[brand],
        }))
      })
    }

    mappedPaymentMethods.push(new PaymentMethodModel({
      type: paymentMethod.type,
      name: paymentMethod.name,
      issuers: paymentMethod.issuers
        ? paymentMethod.issuers.map(issuer => mapPaymentMethodIssuer(paymentMethod.type, issuer))
        : null,
    }))
  })

  return mappedPaymentMethods
}

/**
 * @param {string} paymentMethodType
 * @param {Object} issuer
 * @return {loavies.models.payment.PaymentMethodIssuerModel}
 */
export function mapPaymentMethodIssuer(paymentMethodType, issuer) {
  return new PaymentMethodIssuerModel({
    id: issuer.id,
    name: issuer.name,
    type: paymentMethodType,
  })
}

/**
 * @param {Object} wallet
 * @returns {loavies.models.wallet.WalletModel}
 */
export function mapWallet(wallet) {
  return new WalletModel({
    balance: wallet.available,
    reserved: wallet.reserved,
    bonus: wallet.bonus,
    currency: wallet.currencyCode,
    payoutEnabled: wallet.payoutEnabled,
    iban: wallet.transferInstrument.iban,
    obfuscatedIban: wallet.transferInstrument.obfuscatedIban,
  })
}

/**
 * @param {Object[]} [transfers]
 * @returns {loavies.models.wallet.WalletTransferModel[]}
 */
export function mapWalletTransfers(transfers) {
  if (!Array.isArray(transfers)) {
    return []
  }

  return transfers
    .map(transfer => mapWalletTransfer(transfer))
    .sort((a, b) => Number(b.bookingDate) - Number(a.bookingDate))
}

/**
 * @param {Object} transfer
 * @returns {loavies.models.wallet.WalletTransferModel}
 */
export function mapWalletTransfer(transfer) {
  return new WalletTransferModel({
    type: transfer.type,
    amount: new PriceModel({
      amount: transfer.amount,
      currency: transfer.currency,
    }),
    bookingDate: new Date(transfer.bookingDate),
    description: transfer.description,
  })
}

/**
 * @param {Object} user
 * @returns {loavies.models.wallet.WalletUserModel}
 */
export function mapWalletUser(user) {
  return new WalletUserModel({
    fullName: user.fullName,
    email: user.email,
    telephone: user.telephone,
    dateOfBirth: user.dateOfBirth,
  })
}
