import defaultState from './state'

export default {
  /**
   * @param state
   */
  RESET_STATE(state) {
    clearInterval(state.eventsFetchInterval)
    Object.assign(state, defaultState())
  },

  /**
   * @param state
   * @param {loavies.models.event.EventModel[]} events
   */
  SET_TRANSACTION_EVENTS(state, events) {
    state.transactionEvents = events
  },

  /**
   * @param state
   * @param {loavies.models.event.EventModel[]} events
   */
  ADD_TO_TRANSACTION_EVENTS(state, events) {
    state.transactionEvents = [
      ...state.transactionEvents,
      ...events,
    ]
  },

  /**
   * @param state
   * @param {Function} eventsFetchFunction
   */
  SET_FETCH_EVENTS_INTERVAL(state, eventsFetchFunction) {
    clearInterval(state.eventsFetchInterval)

    const intervalInMs = this.$config.generalConfiguration.events.eventsRefreshInterval * 60 * 1000

    state.eventsFetchInterval = setInterval(eventsFetchFunction, intervalInMs)
  },
}
