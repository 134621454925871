
  import AppImage from '@loavies/component-library/dist/app-image'

  export default {
    components: {
      AppImage,
    },
    computed: {
      locale() {
        return this.$i18n.locale
      },
      socials() {
        return this.$staticDataService.globalSocials.socialMediaPlatforms
      },
    },
  }
