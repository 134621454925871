/**
 * This service is based on validation which is used by Radial.
 * Original PHP package: https://github.com/sirprize/postal-code-validator/
 */
class PostalCodeValidatorService {
  constructor() {
    this.formats = {
      AC: [],
      AD: ['AD###', '#####'],
      AE: [],
      AF: ['####'],
      AG: [],
      AI: ['AI-2640'],
      AL: ['####'],
      AM: ['####'],
      AN: [],
      AO: [],
      AQ: ['BIQQ 1ZZ'],
      AR: ['####', '@####@@@'],
      AS: ['#####', '#####-####'],
      AT: ['####'],
      AU: ['####'],
      AW: [],
      AX: ['#####', 'AX-#####'],
      AZ: ['AZ ####'],
      BA: ['#####'],
      BB: ['BB#####'],
      BD: ['####'],
      BE: ['####'],
      BF: [],
      BG: ['####'],
      BH: ['###', '####'],
      BI: [],
      BJ: [],
      BL: ['#####'],
      BM: ['@@ ##', '@@ @@'],
      BN: ['@@####'],
      BO: [],
      BQ: [],
      BR: ['#####-###', '#####'],
      BS: [],
      BT: ['#####'],
      BV: [],
      BW: [],
      BY: ['######'],
      BZ: [],
      CA: ['@#@ #@#'],
      CC: ['####'],
      CD: [],
      CF: [],
      CG: [],
      CH: ['####'],
      CI: [],
      CK: [],
      CL: ['#######', '###-####'],
      CM: [],
      CN: ['######'],
      CO: ['######'],
      CR: ['#####', '#####-####'],
      CU: ['#####'],
      CV: ['####'],
      CW: [],
      CX: ['####'],
      CY: ['####'],
      CZ: ['### ##'],
      DE: ['#####'],
      DJ: [],
      DK: ['####'],
      DM: [],
      DO: ['#####'],
      DZ: ['#####'],
      EC: ['######'],
      EE: ['#####'],
      EG: ['#####'],
      EH: [],
      ER: [],
      ES: ['#####'],
      ET: ['####'],
      FI: ['#####'],
      FJ: [],
      FK: ['FIQQ 1ZZ'],
      FM: ['#####', '#####-####'],
      FO: ['###'],
      FR: ['#####'],
      FX: [],
      GA: [],
      GB: ['@@## #@@', '@#@ #@@', '@@# #@@', '@@#@ #@@', '@## #@@', '@# #@@'],
      GD: [],
      GE: ['####'],
      GF: ['973##'],
      GG: ['GY# #@@', 'GY## #@@'],
      GH: [],
      GI: ['GX11 1AA'],
      GL: ['####'],
      GM: [],
      GN: ['###'],
      GP: ['971##'],
      GQ: [],
      GR: ['### ##'],
      GS: ['SIQQ 1ZZ'],
      GT: ['#####'],
      GU: ['#####', '#####-####'],
      GW: ['####'],
      GY: [],
      HK: [],
      HM: [],
      HN: ['@@####', '#####'],
      HR: ['#####'],
      HT: ['####'],
      HU: ['####'],
      IC: ['#####'],
      ID: ['#####'],
      IE: ['@#* ****'],
      IL: ['#######'],
      IM: ['IM# #@@', 'IM## #@@'],
      IN: ['######', '### ###'],
      IO: ['BBND 1ZZ'],
      IQ: ['#####'],
      IR: ['##########', '#####-#####'],
      IS: ['###'],
      IT: ['#####'],
      JE: ['JE# #@@', 'JE## #@@'],
      JM: ['##'],
      JO: ['#####'],
      JP: ['###-####', '###'],
      KE: ['#####'],
      KG: ['######'],
      KH: ['#####'],
      KI: [],
      KM: [],
      KN: [],
      KO: [],
      KP: [],
      KR: ['###-###', '#####'],
      KW: ['#####'],
      KY: ['KY#-####'],
      KZ: ['######'],
      LA: ['#####'],
      LB: ['#####', '#### ####'],
      LC: ['LC## ###'],
      LI: ['####'],
      LK: ['#####'],
      LR: ['####'],
      LS: ['###'],
      LT: ['LT-#####', '#####'],
      LU: ['L-####', '####'],
      LV: ['LV-####'],
      LY: [],
      MA: ['#####'],
      MC: ['980##'],
      MD: ['MD####', 'MD-####'],
      ME: ['#####'],
      MF: ['97150'],
      MG: ['###'],
      MH: ['#####', '#####-####'],
      MK: ['####'],
      ML: [],
      MM: ['#####'],
      MN: ['#####'],
      MO: [],
      MP: ['#####', '#####-####'],
      MQ: ['972##'],
      MR: [],
      MS: [],
      MT: ['@@@ ####'],
      MU: ['#####'],
      MV: ['#####'],
      MW: [],
      MX: ['#####'],
      MY: ['#####'],
      MZ: ['####'],
      NA: [],
      NC: ['988##'],
      NE: ['####'],
      NF: ['####'],
      NG: ['######'],
      NI: ['#####'],
      NL: ['####@@', '#### @@'],
      NO: ['####'],
      NP: ['#####'],
      NR: [],
      NU: [],
      NZ: ['####'],
      OM: ['###'],
      PA: ['####'],
      PE: ['#####', 'PE #####'],
      PF: ['987##'],
      PG: ['###'],
      PH: ['####'],
      PK: ['#####'],
      PL: ['##-###'],
      PM: ['97500'],
      PN: ['PCRN 1ZZ'],
      PR: ['#####', '#####-####'],
      PS: ['###'],
      PT: ['####-###'],
      PW: ['#####', '#####-####'],
      PY: ['####'],
      QA: [],
      RE: ['974##'],
      RO: ['######'],
      RS: ['#####'],
      RU: ['######'],
      RW: [],
      SA: ['#####', '#####-####'],
      SB: [],
      SC: [],
      SD: ['#####'],
      SE: ['### ##'],
      SG: ['######'],
      SH: ['@@@@ 1ZZ'],
      SI: ['####', 'SI-####'],
      SJ: ['####'],
      SK: ['### ##'],
      SL: [],
      SM: ['4789#'],
      SN: ['#####'],
      SO: ['@@ #####'],
      SR: [],
      SS: ['#####'],
      ST: [],
      SV: ['####'],
      SX: [],
      SY: [],
      SZ: ['@###'],
      TA: [],
      TC: ['TKCA 1ZZ'],
      TD: [],
      TF: [],
      TG: [],
      TH: ['#####'],
      TJ: ['######'],
      TK: [],
      TL: [],
      TM: ['######'],
      TN: ['####'],
      TO: [],
      TR: ['#####'],
      TT: ['######'],
      TV: [],
      TW: ['###', '###-##'],
      TZ: ['#####'],
      UA: ['#####'],
      UG: [],
      UM: [],
      US: ['#####', '#####-####'],
      UY: ['#####'],
      UZ: ['######'],
      VA: ['00120'],
      VC: ['VC####'],
      VE: ['####', '####-@'],
      VG: ['VG####'],
      VI: ['#####', '#####-####'],
      VN: ['#####'],
      VU: [],
      WF: ['986##'],
      WS: ['WS####'],
      YE: [],
      YT: ['976##'],
      ZA: ['####'],
      ZM: ['#####'],
      ZW: [],
    }
  }

  /**
   * @param {string} countryCode
   * @param {string} postalCode
   * @param {boolean} [ignoreSpaces]
   * @return {boolean}
   */
  isValid(countryCode, postalCode, ignoreSpaces = false) {
    const formattedCountryCode = countryCode.toUpperCase()

    if (!this.formats[formattedCountryCode] || !postalCode) {
      return false
    }

    if (!this.formats[formattedCountryCode].length) {
      return true
    }

    const countryCodeFormats = this.formats[formattedCountryCode]
    let postalCodeIsValid = false

    countryCodeFormats.forEach(format => {
      if (this.getFormatRegex(format, ignoreSpaces).test(postalCode)) {
        postalCodeIsValid = true
      }
    })

    return postalCodeIsValid
  }

  /**
   * @param {string} format
   * @param {boolean} [ignoreSpaces]
   * @return {RegExp}
   */
  getFormatRegex(format, ignoreSpaces = false) {
    let pattern
    pattern = format.replace(/#/g, '\\d')
    pattern = pattern.replace(/@/g, '[a-zA-Z]')
    pattern = pattern.replace(/\*/g, '[a-zA-Z0-9]')

    if (ignoreSpaces) {
      pattern = pattern.replace(/ /g, ' ?')
    }

    return new RegExp(`^${pattern}$`)
  }

  /**
   * @param {string} countryCode
   * @return {Error|array}
   */
  getCountryFormats(countryCode) {
    const formattedCountryCode = countryCode.toUpperCase()

    if (!this.formats[formattedCountryCode]) {
      throw new ReferenceError(`No formats available for country code: ${countryCode}`)
    }

    return this.formats[formattedCountryCode]
  }
}

export const postalCodeValidatorService = new PostalCodeValidatorService()
