
  import { mapActions } from 'vuex'

  import AppButton from '@loavies/component-library/dist/app-button'

  import { APP_TRAY_NAMES, OVERLAYS } from '~/lib/constants/overlays'

  export default {
    components: {
      AppButton,
    },
    props: {
      newsletterItem: {
        type: Object,
        required: true,
      },
    },
    methods: {
      ...mapActions({
        setCurrentOverlay: 'overlay/setCurrentOverlay',
      }),
      openOverlay() {
        this.setCurrentOverlay({
          settings: {
            type: OVERLAYS.APP_TRAY,
            component: APP_TRAY_NAMES.NEWSLETTER_SIGNUP_FORM_APP_TRAY,
            options: {
              formButtonText: this.newsletterItem.formButtonText,
              formTag: this.newsletterItem.formTag,
              formText: this.newsletterItem.formText,
              formTitle: this.newsletterItem.formTitle,
            },
          },
        })
      },
    },
  }
