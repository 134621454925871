import axios from 'axios'

/**
 * @typedef {MicroserviceApiService}
 * @alias this.$microserviceApiService
 */
export class MicroserviceApiService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.config = context.$config
    this.store = context.store
    this.i18n = context.i18n

    this.maxRetries = 1
    this.retries = {}
    this.cookieKey = 'microservice-api-token'
    this.identityAuthorizeEndpoint = '/identity/authorize'
  }

  init() {
    this.$clientCookieService = this.context.$clientCookieService

    this.instance = this.getAxiosInstance()
  }

  /**
   * @returns {Object} AxiosInstance
   */
  getAxiosInstance() {
    const axiosInstance = axios.create({
      baseURL: this.config.loaviesMicroserviceClusterUrl,
    })

    this.setUnauthorizedInterceptor(axiosInstance)
    this.setTokenInterceptor(axiosInstance)

    return axiosInstance
  }

  /**
   * @param {Object} tokenDetails
   * @returns {Object}
   */
  getAuthorizationConfig(tokenDetails) {
    return {
      headers: {
        'Platform': 'Reloaved',
        'Authorization': tokenDetails ? `Bearer ${tokenDetails.accessToken}` : null,
        'Content-Language': this.i18n.locale,
      },
    }
  }

  /**
   * @param {Object} axiosInstance
   * @returns {void}
   */
  setTokenInterceptor(axiosInstance) {
    axiosInstance.interceptors.request.use(async config => {
      // We also can get an error here from the response interceptor
      if (!config) return Promise.reject()

      if (config.url.startsWith(this.identityAuthorizeEndpoint)) return config

      try {
        let tokenDetails = this.getTokenDetails()

        if (!tokenDetails) return config

        const currentDateTime = new Date()
        const currentDateTimeInSeconds = currentDateTime.getTime() / 1000

        if (currentDateTimeInSeconds >= tokenDetails.accessTokenExpiresAt) {
          tokenDetails = await this.updateToken()
        }

        const { headers } = this.getAuthorizationConfig(tokenDetails)

        config.headers = {
          ...config.headers,
          ...headers,
        }

        return config
      } catch (error) {
        return Promise.reject(error)
      }
    }, error => {
      // Do something with request error
      return Promise.reject(error)
    })
  }

  /**
   * @param {Object} axiosInstance
   * @returns {void}
   */
  setUnauthorizedInterceptor(axiosInstance) {
    axiosInstance.interceptors.response.use(
      response => response,
      async error => {
        if (!error.config
          || error.response?.status !== 401
          || error.config.url.startsWith(this.identityAuthorizeEndpoint)) {
          return Promise.reject(error)
        }

        const url = error.config.url

        // Check if request url has existing entry in retries Object, otherwise add it
        if (isNaN(parseFloat(this.retries[url]))) {
          this.retries[url] = 0
        }

        // If retry attempt passed maximum reties return rejection
        if (this.retries[url] >= this.maxRetries) {
          delete this.retries[url]

          return Promise.reject(error)
        }

        this.retries[url] += 1

        try {
          const tokenDetails = await this.updateToken()
          const { headers } = this.getAuthorizationConfig(tokenDetails)

          error.config.headers = {
            ...error.config.headers,
            ...headers,
          }

          return axiosInstance
            .request(error.config)
            .then(response => {
              // Remove retries entry after successful try
              delete this.retries[url]

              return response
            })
        } catch (error) {
          return Promise.reject(error)
        }
      }
    )
  }

  /**
   * @returns {undefined|Object}
   */
  getTokenDetails() {
    const cookieValue = this.$clientCookieService?.get(this.cookieKey)

    if (cookieValue) {
      try {
        return JSON.parse(cookieValue)
      } catch (error) {
        return undefined
      }
    }

    return undefined
  }

  /**
   * @param {Object} tokenDetails
   * @returns {Object} tokenDetails
   */
  setTokenDetails(tokenDetails) {
    try {
      const jsonString = JSON.stringify(tokenDetails)
      this.$clientCookieService.set(this.cookieKey, jsonString, { expires: 365 })

      return tokenDetails
    } catch (error) {
      console.error(error)

      return this.store.dispatch('user/logout', { silent: false }, { root: true })
    }
  }

  /**
   * @returns {Promise}
   */
  updateToken() {
    const tokenDetails = this.getTokenDetails()

    if (!tokenDetails?.refreshToken) return Promise.reject(Error('Missing refresh token'))

    return this.instance
      .post(this.identityAuthorizeEndpoint, {
        grantType: 'REFRESH_TOKEN',
        refreshToken: tokenDetails.refreshToken,
      })
      .then(response => this.setTokenDetails(response.data))
      .catch(async error => {
        await this.store.dispatch('user/logout', { silent: false }, { root: true })

        return Promise.reject(error)
      })
  }

  /**
   * @returns {void}
   */
  async logout() {
    try {
      const tokenDetails = this.getTokenDetails()

      if (!tokenDetails) return

      await this.instance.post(`/identity/revoke/refresh-token/${tokenDetails.refreshToken}`)
    } catch (error) {
      console.error(error)
    } finally {
      this.$clientCookieService?.delete(this.cookieKey)
    }
  }

  /**
   * @param {Object} error
   * @returns {Object}
   */
  handleValidationErrors(error) {
    if (error?.response?.data?.validationErrors) {
      const validationErrors = error.response.data.validationErrors

      return Promise.reject(Object.keys(validationErrors).map(key => validationErrors[key]).flat())
    }

    return Promise.reject(error)
  }
}
