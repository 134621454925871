import { onLoad } from '~/lib/onload/onload.js'

const ONE_MINUTE = 60000

let eventsQueue = []
let gtmInitializedTime = null

// Overwrite push method to array so we can control memory leaking
eventsQueue.push = push

export default (context, inject) => {
  const GTMId = context.$config.GTMId

  inject('tracking', {
    enable() {
      if (!isInitialized()) {
        initGTM(GTMId)

        this.add({
          'event': 'gtm.js',
          'gtm.start': new Date().getTime(),
        })
      }
    },
    add(event) {
      if (isInitialized()) {
        return window.dataLayer.push(event)
      }

      const currentTime = new Date().getTime()
      const trackEventsInQueue = currentTime < gtmInitializedTime + ONE_MINUTE

      if (trackEventsInQueue) {
        eventsQueue.push(event)
      }
    },
  })

  context.app.$tracking.enable()
}

function initGTM(GTMId) {
  gtmInitializedTime = new Date().getTime()

  onLoad(() => {
    const script = document.createElement('script')
    script.src = `https://www.googletagmanager.com/gtm.js?id=${ GTMId }`
    script.async = true

    window.dataLayer = []

    // Overwrite push method to datalayer array so we can control memory leaking
    window.dataLayer.push = push

    document.body.appendChild(script)

    // If the queue contains events add them to the dataLayer
    if (eventsQueue.length > 0) {
      eventsQueue.forEach(queuedEvent => window.dataLayer.push(queuedEvent))
      eventsQueue = []
    }
  }, 5000)
}

function isInitialized() {
  return Array
    .from(document.getElementsByTagName('script'))
    .some(script => script.src.includes('googletagmanager'))
}

function push(event) {
  if (event['gtm.element']) {
    // clone the node and save that to the event instead of keeping reference to the original node, which causes memory leaks
    event['gtm.element'] = event['gtm.element'].cloneNode(true)
  }
  return Array.prototype.push.apply(this, arguments)
}
