export default {
  /**
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   * @returns {loavies.models.event.TransactionEventModel[]}
   */
  unreadUserTransactionEvents(state, getters, rootState, rootGetters) {
    const isAuthenticated = rootGetters['user/isAuthenticated']

    if (!isAuthenticated) {
      return []
    }

    const lastEventSequenceIdMessageRead = rootState.user.user.privateData.lastEventSequenceIdMessageRead

    return state.transactionEvents.filter(event => event.sequenceId > lastEventSequenceIdMessageRead)
  },

  /**
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   * @returns {loavies.models.event.TransactionEventModel[]}
   */
  notifiableUserTransactionEvents(state, getters, rootState, rootGetters) {
    const isAuthenticated = rootGetters['user/isAuthenticated']

    if (!isAuthenticated) {
      return []
    }

    const lastEventSequenceIdNotificationShown = rootState.user.user.privateData.lastEventSequenceIdNotificationShown

    return state.transactionEvents.filter(event => event.sequenceId > lastEventSequenceIdNotificationShown)
  },

  /**
   * @param state
   * @returns {number}
   */
  lastEventSequenceId(state) {
    if (!state.transactionEvents.length) {
      return 0
    }

    return Math.max(...state.transactionEvents.map(event => event.sequenceId)) ?? 0
  },

  /**
   * @param state
   * @param getters
   * @returns {boolean}
   */
  hasUnreadTransactionEvents(state, getters) {
    return getters.unreadUserTransactionEvents.length > 0
  },
}
