/**
 * @typedef {NewsletterHelperService}
 * @alias this.$newsletterHelperService
 */
export class NewsletterHelperService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
  }

  init() {
    this.magentoApiInstance = this.context.$magentoApiService.instance
  }

  /**
   * @param {string} email
   * @param {string} [tag]
   * @return {Promise}
   */
  subscribe({ email, tag }) {
    return this.magentoApiInstance
      .post('/newsletter/subscribe', {
        email,
        ...tag && {
          context: tag,
        },
      })
      .then(response => response.data.status_message)
  }
}
