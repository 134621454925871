const TOAST_TIMEOUT_DURATION = 2500
let toastMessageTimeout = null

export default {
  /**
   * @param context
   * @param {string} text
   * @param {string} [icon]
   * @param {number} [duration] in ms
   */
  showToastMessage(context, { text, icon, duration = TOAST_TIMEOUT_DURATION }) {
    if (!text) {
      return
    }

    clearTimeout(toastMessageTimeout)

    if (icon) {
      context.commit('SET_ICON', icon)
    }

    context.commit('SET_TEXT', text)
    context.commit('SET_IS_OPEN', true)

    toastMessageTimeout = setTimeout(() => {
      context.commit('SET_IS_OPEN', false)

      // The animation needs to finish before removing the text/icon
      setTimeout(() => {
        context.commit('SET_TEXT', null)
        context.commit('SET_ICON', null)
      }, 250)
    }, duration)
  },
}
