export default {
  data() {
    return {
      currentLocale: this.$i18n.locale,
    }
  },
  computed: {
    locales() {
      return this.$i18n.locales.map(locale => ({
        name: locale.name,
        value: locale.code,
      }))
    },
  },
  methods: {
    handleSwitchLocale() {
      this.$localizationService.switchLocale({
        locale: this.currentLocale,
        currentRoute: this.$route,
      })
    },
  },
}
