import { SHOW_LARGE_PRODUCT_CARDS_COOKIE_KEY } from '~/store/actions'

export default async context => {
  try {
    // Sharetribe Marketplace SDK stores a cookie on the client side
    // and is able to retrieve and re-authenticate the user automatically.
    // If this function trows an error, this means the user isn't authenticated.

    const user = context.$marketplaceAuthenticationService.getTokenDetails()
      && await context.$marketplaceUserService
      .getCurrentUser()
      .catch(() => null)

    const hasMicroserviceApiToken = context.$microserviceApiService.getTokenDetails()

    if (user && !hasMicroserviceApiToken) {
      return context.store.dispatch('user/logout', { silent: true })
    }

    if (user) {
      await context.store.dispatch('user/setUser', user)

      await Promise.all([
        context.store.dispatch('transactions/fetchLatestTransactionsForUser'),
        context.store.dispatch('user/fetchOwnListings'),
        context.store.dispatch('wishlist/setWishlistByListingsIds', user.privateData?.wishlist),
        context.store.dispatch('events/fetchUserTransactionEvents'),
        context.store.dispatch('events/initAutoFetchEvents'),
        context.store.dispatch('wallet/fetchWallet'),
      ])
    } else {
      const wishlistIds = context.$clientStorageService.getWishListIds()
      await context.store.dispatch('wishlist/setWishlistByListingsIds', wishlistIds)
    }

    // Set for everybody
    const showLargeProductCards = context.app.$clientCookieService.get(SHOW_LARGE_PRODUCT_CARDS_COOKIE_KEY)

    if (showLargeProductCards) {
      await context.store.dispatch('setShowLargeProductCards', JSON.parse(showLargeProductCards))
    }
  } catch (error) {
    context.app.$sentry.captureException(error)
  } finally {
    await context.store.dispatch('user/setIsInitialized', true)
  }
}
