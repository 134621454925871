import { ACTIVE_LISTING_ELIGIBLE_TRANSACTION_STATES_PROVIDER, LISTING_STATES, TRANSACTION_STATES } from '@loavies/c2c-models'

export default {
  /**
   * @param state
   * @returns {function(string): loavies.models.transaction.TransactionModel|null}
   */
  getTransactionByListingId: state => listingId => {
    let transaction = getTransactionByListingId(state.saleTransactions, listingId)

    if (!transaction) {
      transaction = getTransactionByListingId(state.orderTransactions, listingId)
    }

    return transaction
  },

  /**
   * @param state
   * @returns {function(string): loavies.models.transaction.TransactionModel|null}
   */
  getTransactionById: state => transactionId => {
    let transaction = getTransactionById(state.saleTransactions, transactionId)

    if (!transaction) {
      transaction = getTransactionById(state.orderTransactions, transactionId)
    }

    return transaction
  },

  /**
   * @param state
   * @returns {function(string): loavies.models.transaction.TransactionModel|null}
   */
  getSaleTransactionByListingId: state => listingId => {
    return getTransactionByListingId(state.saleTransactions, listingId)
  },

  /**
   * @param state
   * @returns {loavies.models.transaction.TransactionModel[]}
   */
  getActiveSalesTransactions: state => {
    const filterStates = [
      TRANSACTION_STATES.COMPLETED_AND_REVIEWED,
      TRANSACTION_STATES.COMPLETED,
      TRANSACTION_STATES.CANCELED,
      TRANSACTION_STATES.REFUNDED,
    ]

    return state.saleTransactions.filter(transaction => !filterStates.includes(transaction.state))
  },

  /**
   * @param state
   * @returns {function(string): loavies.models.transaction.TransactionModel|null}
   */
  getOrderTransactionByListingId: state => listingId => {
    return getTransactionByListingId(state.orderTransactions, listingId)
  },

  /**
   * A listing is active when it doesn't have a corresponding transaction
   * or when a corresponding transaction has a certain state.
   * @return {function(string): boolean}
   */
  getListingIsActiveByListingId: (state, getters, rootState, rootGetters) => listingId => {
    const listing = rootGetters['user/getOwnListingById'](listingId)

    return listing ? getters['getListingIsActive'](listing) : false
  },

  /**
   * A listing is active when it doesn't have a corresponding transaction
   * or when a corresponding transaction has a certain state.
   * @return {function(loavies.models.listing.ListingModel): boolean}
   */
  getListingIsActive: (state, getters) => listing => {
    if (listing.state !== LISTING_STATES.PUBLISHED) {
      return false
    }

    const transaction = getters['getTransactionByListingId'](listing.id)

    if (!transaction) {
      return true
    }

    return ACTIVE_LISTING_ELIGIBLE_TRANSACTION_STATES_PROVIDER.includes(transaction.state)
  },

  /**
   * @param state
   * @returns {function(string): loavies.models.transaction.TransactionModel[]}
   */
  getFilteredOrderTransaction: state => searchQuery => {
    return state.orderTransactions.filter(orderTransaction => orderTransaction.listing?.title?.toLowerCase().includes(searchQuery.toLowerCase()))
  },

  /**
   * @param state
   * @return {loavies.models.transaction.TransactionModel[]}
   */
  getAllTransactions(state) {
    return [...state.saleTransactions, ...state.orderTransactions]
  },
}

/**
 * @param {loavies.models.transaction.TransactionModel[]} transactions
 * @param {string} listingId
 * @return {loavies.models.transaction.TransactionModel|null}
 */
function getTransactionByListingId(transactions, listingId) {
  return transactions.find(transaction => transaction.listing?.id === listingId) || null
}

/**
 * @param {loavies.models.transaction.TransactionModel[]} transactions
 * @param {string} transactionId
 * @return {loavies.models.transaction.TransactionModel|null}
 */
function getTransactionById(transactions, transactionId) {
  return transactions.find(transaction => transaction.id === transactionId) || null
}
