export default {
  /**
   * @param context
   * @param {loavies.models.user.UserModel} user
   * @returns {Promise<void>}
   */
  setUser(context, user) {
    context.commit('SET_USER', user)

    this.$sentry.setUser(user)
    this.$bloomreach.identifyUser(user.email)
  },

  /**
   * @param context
   * @param {boolean} isInitialized
   * @returns {Promise<void>}
   */
  setIsInitialized(context, isInitialized) {
    context.commit('SET_IS_INITIALIZED', isInitialized)
  },

  /**
   * @param context
   * @param {loavies.models.user.LoginUserModel} loginUserModel
   * @returns {Promise<void>}
   */
  async login(context, loginUserModel) {
    try {
      await this.$authenticationService.login(loginUserModel)

      await context.dispatch('initUser')
    } catch (error) {
      return Promise.reject(error)
    }
  },

  /**
   * @param context
   * @param {loavies.models.user.CreateIdentityUserModel} createIdentityUserModel
   * @returns {Promise<void>}
   */
  async createUserAndLogin(context, createIdentityUserModel) {
    try {
      await this.$userHandlerService.createUserAndLogin(createIdentityUserModel)

      await context.dispatch('initUser')
    } catch (error) {
      return Promise.reject(error)
    }
  },

  /**
   * @param context
   * @returns {Promise<void>}
   */
  async initUser(context) {
    try {
      // This method needs to be called before getting the user from the SDK.
      // Because the user can be updated during the wishlist synchronizing process.
      await context.dispatch('wishlist/synchronizeLocalAndUserWishlist', null, { root: true })
      const user = await this.$marketplaceUserService.getCurrentUser()

      // Store the latest user data in the store
      await context.dispatch('setUser', user)
      await Promise.all([
        context.dispatch('transactions/fetchLatestTransactionsForUser', null, { root: true }),
        context.dispatch('fetchOwnListings'),
        context.dispatch('events/fetchUserTransactionEvents', null, { root: true }),
        context.dispatch('events/initAutoFetchEvents', null, { root: true }),
        context.dispatch('wallet/fetchWallet', null, { root: true }),
      ])

      // If user has no transactions, it has no chats, so a new chat session + user is not needed yet.
      const userHasTransactions = context.rootGetters['transactions/getAllTransactions'].length > 0
      if (!userHasTransactions) return

      await this.$chatApiService.createNewSession(user)
      await context.dispatch('chats/setChats', {}, { root: true })

      this.$chatApiService.setOnMessage(message => context.dispatch('chats/setChats', { singleNewMessage: message }, { root: true }))
    } catch (error) {
      return Promise.reject(Error(error))
    } finally {
      await context.dispatch('setIsInitialized', true)
    }
  },

  /**
   * @param context
   * @param {boolean} [silent]
   * @returns {Promise<void>}
   */
  async logout(context, { silent = false } = {}) {
    const toastConfig = {
      text: this.app.i18n.t('logout_successful'),
    }

    try {
      // Log out from Sharetribe
      await this.$marketplaceAuthenticationService.logout()

      // Log out from Microservices
      await this.$microserviceApiService.logout()

      // Reset corresponding data
      await Promise.all([
        context.dispatch('wishlist/resetState', null, { root: true }),
        context.dispatch('transactions/resetState', null, { root: true }),
        context.dispatch('listings/resetState', null, { root: true }),
        context.dispatch('chats/resetState', null, { root: true }),
        context.dispatch('events/resetState', null, { root: true }),
        context.dispatch('checkout/resetState', null, { root: true }),
        context.dispatch('wallet/resetState', null, { root: true }),
      ])

      // Reset user data
      context.commit('SET_USER', null)
      context.commit('SET_LISTINGS', [])

      if (!silent) {
        return context.dispatch('toast/showToastMessage', toastConfig, { root: true })
      }
    } catch (error) {
      return Promise.reject(Error(error))
    }
  },

  /**
   * @param context
   * @param {loavies.models.user.UserModel[]} followingUsers
   * @returns {Promise<void>}
   */
  setFollowingUsers(context, followingUsers) {
    context.commit('SET_FOLLOWING_USERS', followingUsers)
  },

  /**
   * @param context
   * @param {loavies.models.api.ApiTotalsModel} totals
   */
  setFollowingUsersTotals(context, totals) {
    context.commit('SET_FOLLOWING_USERS_TOTALS', totals)
  },

  /**
   * @param context
   * @returns {void}
   */
  decreaseFollowingUsersTotalItemsByOne(context) {
    context.commit('DECREASE_FOLLOWING_USERS_TOTALS_TOTAL_ITEMS_BY_ONE')
  },

  /**
   * @param context
   * @param {string} userId
   * @return {Promise<loavies.models.user.UserModel>}
   */
  async followUser(context, userId) {
    if (!context.getters.isAuthenticated) {
      return Promise.reject('Not authenticated')
    }

    const followingUsers = context.state.user.publicData.followingUsers
    const updatedFollowingUsers = [...followingUsers, userId]

    return this.$marketplaceUserService.setFollowingUsers(updatedFollowingUsers)
      .then(async updatedUser => {
        await this.$userHandlerService.increaseAmountOfFollowers(userId)
        return context.dispatch('setUser', updatedUser)
      })
      .catch(() => context.dispatch('toast/showToastMessage', { text: this.app.i18n.t('follow_user_failed') }, { root: true }))
  },

  /**
   * @param context
   * @param {string} userId
   * @return {void}
   */
  filterOutUnfollowedUser(context, userId) {
    const followingUsers = context.state.followingUsers
    const updatedFollowingUsers = followingUsers.filter(user => user.id !== userId)

    context.commit('SET_FOLLOWING_USERS', updatedFollowingUsers)
  },

  /**
   * @param context
   * @param {string} userId
   * @return {Promise<loavies.models.user.UserModel>}
   */
  async unfollowUser(context, userId) {
    if (!context.getters.isAuthenticated) {
      return Promise.reject(Error('Not authenticated'))
    }
    const followingUsers = context.state.user.publicData.followingUsers
    const updatedFollowingUsers = followingUsers.filter(id => id !== userId)
    return this.$marketplaceUserService.setFollowingUsers(updatedFollowingUsers)
      .then(async updatedUser => {
        await this.$userHandlerService.decreaseAmountOfFollowers(userId)
        return context.dispatch('setUser', updatedUser)
      })
      .catch(() => context.dispatch('toast/showToastMessage', { text: this.app.i18n.t('unfollow_user_failed') }, { root: true }))
  },

  /**
   * @param context
   * @param {string} userId
   * @return {Promise<loavies.models.user.UserModel>}
   */
  async increaseAmountOfStoreViewsByOne(context, userId) {
    return this.$userHandlerService.increaseAmountOfStoreViewsByOne(userId)
  },

  /**
   * @param context
   * @returns {Promise<void>}
   */
  async updateLastEventSequenceIdsInStore(context) {
    const lastSequenceId = context.rootGetters['events/lastEventSequenceId']

    context.commit('UPDATE_LAST_EVENTS_SEQUENCE_IDS', lastSequenceId)
  },

  /**
   * @param context
   * @param {string} gridLayout
   * @returns {void}
   */
  setUserGridLayout(context, gridLayout) {
    context.commit('SET_USER_GRID_LAYOUT', gridLayout)
  },

  /**
   * LISTINGS
   */

  /**
   * @param context
   * @param {loavies.models.listing.ListingModel} listing
   */
  setListing(context, listing) {
    const index = context.state.listings.findIndex(userListing => userListing.id === listing.id)
    if (index === -1) {
      return context.commit('ADD_LISTING', listing)
    }

    context.commit('UPDATE_LISTING_BY_INDEX', { index, listing })
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingModel[]} listings
   * @returns {Promise<void>}
   */
  setListings(context, listings) {
    context.commit('SET_LISTINGS', listings)
  },

  /**
   * @param context
   * @return {Promise<void>}
   */
  async fetchOwnListings(context) {
    const listings = await this.$marketplaceListingService.getOwnListings()

    return context.dispatch('setListings', listings)
  },
}
