
  import { mapGetters } from 'vuex'
  import MatchMedia from '~/mixins/match-media'

  export default {
    name: 'HeaderOnlyLayout',
    mixins: [MatchMedia],
    computed: {
      ...mapGetters({
        isAuthenticated: 'user/isAuthenticated',
      }),
    },
  }
