import {
  RECENTLY_WATCHED_LISTING_IDS,
  WISHLIST_IDS,
  SHARED_LISTING_IDS,
  RECENTLY_WATCHED_SHOP_IDS,
  REPORTED_USER_IDS,
  ORDER_VERIFICATIONS,
} from '~/lib/constants/storage'

/**
 * @typedef {ClientStorageService}
 * @alias this.$clientStorageService
 */
export class ClientStorageService {
  /**
   * @param {string[]} recentlyWatchedListingIds
   */
  setRecentlyWatchedListingIds(recentlyWatchedListingIds) {
    sessionStorage.setItem(RECENTLY_WATCHED_LISTING_IDS, JSON.stringify(recentlyWatchedListingIds))
  }

  /**
   * @return {string[]}
   */
  getRecentlyWatchedListingIds() {
    try {
      return JSON.parse(sessionStorage.getItem(RECENTLY_WATCHED_LISTING_IDS)) ?? []
    } catch {
      return []
    }
  }

  /**
   * @param {string[]} recentlyWatchedShopIds
   */
  setRecentlyWatchedShopIds(recentlyWatchedShopIds) {
    sessionStorage.setItem(RECENTLY_WATCHED_SHOP_IDS, JSON.stringify(recentlyWatchedShopIds))
  }

  /**
   * @return {string[]}
   */
  getRecentlyWatchedShopIds() {
    try {
      return JSON.parse(sessionStorage.getItem(RECENTLY_WATCHED_SHOP_IDS)) ?? []
    } catch {
      return []
    }
  }

  /**
   * @param {string[]} wishlistIds
   */
  setWishListIds(wishlistIds) {
    return localStorage.setItem(WISHLIST_IDS, JSON.stringify(wishlistIds))
  }

  /**
   * @return {string[]}
   */
  getWishListIds() {
    try {
      return JSON.parse(localStorage.getItem(WISHLIST_IDS)) ?? []
    } catch {
      return []
    }
  }

  /**
   * @param {string[]} sharedListingIds
   */
  setSharedListingIds(sharedListingIds) {
    localStorage.setItem(SHARED_LISTING_IDS, JSON.stringify(sharedListingIds))
  }

  /**
   * @return {string[]}
   */
  getSharedListingIds() {
    try {
      return JSON.parse(localStorage.getItem(SHARED_LISTING_IDS)) ?? []
    } catch {
      return []
    }
  }

  /**
   * @param {string[]} reportedUserIds
   */
  setReportedUserIds(reportedUserIds) {
    localStorage.setItem(REPORTED_USER_IDS, JSON.stringify(reportedUserIds))
  }

  /**
   * @return {string[]}
   */
  getReportedUserIds() {
    try {
      return JSON.parse(localStorage.getItem(REPORTED_USER_IDS)) ?? []
    } catch {
      return []
    }
  }

  /**
   * @param {Object} orderVerificationsObject
   */
  setOrderVerifications(orderVerificationsObject) {
    sessionStorage.setItem(ORDER_VERIFICATIONS, JSON.stringify(orderVerificationsObject))
  }

  /**
   * @return {null|Object}
   */
  getOrderVerifications() {
    try {
      return JSON.parse(sessionStorage.getItem(ORDER_VERIFICATIONS))
    } catch {
      return null
    }
  }
}
