import { extend, configure } from 'vee-validate'
import { email, min, regex, required } from 'vee-validate/dist/rules'
import { electronicFormatIBAN, isValidIBAN } from 'ibantools'

import { postalCodeValidatorService } from '~/lib/services/validators/postal-code-validator-service'

const COUNTRY_CODE_FR = 'FR'
const COUNTRY_CODE_ES = 'ES'

export default function ({ app }) {
  // For a complete list of validation rules visit:
  // https://logaretm.github.io/vee-validate/guide/rules.html#rules
  extend('email', email)
  extend('min', min)
  extend('regex', regex)
  extend('required', required)

  // Custom rules
  extend('password', value => {
    if (value.length < 8) {
      return app.i18n.t('validation_password_length')
    }

    return true
  })

  extend('numeric_spaces_house_number', value => {
    return !isNaN(Number(value.replace(/ /g, '')))
  })

  extend('postal_code', {
    validate(value, args) {
      return postalCodeValidatorService.isValid(args['countryId'], value)
    },
    params: ['countryId'],
  })

  extend('complete_address', {
    validate(value, args) {
      const houseNumber = args['populatedAddress']?.number

      if (!args['populatedAddress']?.street) {
        return app.i18n.t('validation_missing_street')
      }

      // House number is not required for French addresses
      if (!houseNumber && args['populatedAddress']?.countryId !== COUNTRY_CODE_FR) {
        return app.i18n.t('validation_missing_house_number')
      }

      if (houseNumber && isNaN(Number(houseNumber.replace(/ /g, '')))) {
        return app.i18n.t('validation_numeric_spaces_house_number')
      }

      if (!args['populatedAddress']?.postcode) {
        return app.i18n.t('validation_missing_postal_code')
      }

      // Dirty fix to let people from French overseas departments (i.e. Guadeloupe, Martinique etc.)
      // know that we do not ship to their area.
      if (isFrenchOverseasDepartmentCode(args['populatedAddress']?.countryId, args['populatedAddress']?.postcode)) {
        return app.i18n.t('validation_french_overseas_postal_code')
      }

      // Dirty fix to let people from Spanish overseas departments (i.e. Canary Island etc.)
      // know that we do not ship to their area.
      if (isSpainOverseasDepartmentCode(args['populatedAddress']?.countryId, args['populatedAddress']?.postcode)) {
        return app.i18n.t('validation_spain_overseas_postal_code')
      }

      if (!args['populatedAddress']?.city) {
        return app.i18n.t('validation_missing_city')
      }

      if (!args['populatedAddress']?.countryId) {
        return app.i18n.t('validation_missing_country')
      }

      if (!postalCodeValidatorService.isValid(args['populatedAddress']?.countryId, args['populatedAddress']?.postcode)) {
        return app.i18n.t('validation_postal_code')
      }

      return true
    },
    params: ['populatedAddress'],
  })

  // Dirty fix to let people from French overseas departments (i.e. Guadeloupe, Martinique etc.)
  // know that we do not ship to their area.
  extend('french_overseas_postal_code', {
    validate(value, args) {
      return !isFrenchOverseasDepartmentCode(args['countryId'], value)
    },
    params: ['countryId'],
  })

  // Dirty fix to let people from Spanish overseas departments (i.e. Canary Island etc.)
  // know that we do not ship to their area.
  extend('spain_overseas_postal_code', {
    validate(value, args) {
      return !isSpainOverseasDepartmentCode(args['countryId'], value)
    },
    params: ['countryId'],
  })

  extend('iban', value => {
    return isValidIBAN(electronicFormatIBAN(value))
  })

  extend('phone_number_digits', value => {
    return /^\d+$/.test(value)
  })

  extend('phone_number_country_code', value => {
    return /^\+\d+$/.test(value)
  })

  extend('max_amount_wallet', {
    validate(value, args) {
      const maxAmountInCents = args['maxAmount']

      if (Number(value) * 100 > maxAmountInCents) {
        const currency = args['currency']
        const maxAmountFormatted = app.$currencyService.formatCentsToLocalePrice(maxAmountInCents, currency)

        return app.i18n.t('validation_max_amount_wallet', { amount: maxAmountFormatted })
      }

      return true
    },
    params: ['maxAmount', 'currency'],
  })

  configure({
    defaultMessage: (field, values) => {
      // Validation error messages get injected in i18n here
      // Example translation token: validation_required
      return app.i18n.t(`validation_${values._rule_}`, values)
    },
  })
}

/**
 * @param {string} countryId
 * @param {string} postalCode
 * @return {boolean}
 */
function isFrenchOverseasDepartmentCode(countryId, postalCode) {
  if (!countryId || !postalCode) {
    return false
  }

  // All French overseas departments start with either 97 or 98
  // SOURCE: https://en.wikipedia.org/wiki/Postal_codes_in_France
  const departmentCodes = ['97', '98']
  const isFrenchCountry = countryId === COUNTRY_CODE_FR
  let isOverseasDepartmentCode = false

  departmentCodes.forEach(code => {
    if (postalCode.startsWith(code)) {
      isOverseasDepartmentCode = true
    }
  })

  return isFrenchCountry && isOverseasDepartmentCode
}

/**
 * @param {string} countryId
 * @param {string} postalCode
 * @return {boolean}
 */
function isSpainOverseasDepartmentCode(countryId, postalCode) {
  if (!countryId || !postalCode) {
    return false
  }

  // Canary Islands begin with 35 or 38
  // SOURCE: https://en.wikipedia.org/wiki/Postal_codes_in_Spain
  const departmentCodes = ['35', '38', '51', '52']
  const isSpainCountry = countryId === COUNTRY_CODE_ES
  let isOverseasDepartmentCode = false

  departmentCodes.forEach(code => {
    if (postalCode.startsWith(code)) {
      isOverseasDepartmentCode = true
    }
  })

  return isSpainCountry && isOverseasDepartmentCode
}
