import { ApiTotalsModel } from '@loavies/c2c-models'

import { mapUser } from '../api/node-api-mappers'
import { mapUserAddress } from '../api/microservice-api-mappers'

/**
 * @typedef {UserHandlerService}
 * @alias this.$userHandlerService
 */
export class UserHandlerService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
  }

  init() {
    this.$nodeApiService = this.context.$nodeApiService
    this.$microserviceApiService = this.context.$microserviceApiService
    this.$authenticationService = this.context.$authenticationService
    this.$marketplaceUserService = this.context.$marketplaceUserService
    this.$elasticUpdateService = this.context.$elasticUpdateService

    this.nodeApiInstance = this.$nodeApiService.instance
    this.microserviceApiInstance = this.$microserviceApiService.instance
  }

  /**
   * @param {loavies.models.user.CreateIdentityUserModel} createIdentityUserModel
   * @returns {Promise}
   */
  createUserAndLogin(createIdentityUserModel) {
    return this.microserviceApiInstance.post('/identity/user/new', createIdentityUserModel)
      .then(response => this.$authenticationService.setAuthenticationForApis(response.data))
  }

  /**
   * @param {string} userId
   * @returns {Promise}
   */
  increaseAmountOfStoreViewsByOne(userId) {
    return this.nodeApiInstance.patch(`/user/${userId}/public-data/amount-of-shop-views`)
  }

  /**
   * @param {string} userId
   * @returns {Promise}
   */
  increaseAmountOfFollowers(userId) {
    return this.nodeApiInstance.patch(`/user/${userId}/public-data/amount-of-followers/increase`, null, this.$nodeApiService.getAuthorizationConfig())
  }

  /**
   * @param {string} userId
   * @returns {Promise}
   */
  decreaseAmountOfFollowers(userId) {
    return this.nodeApiInstance.patch(`/user/${userId}/public-data/amount-of-followers/decrease`, null, this.$nodeApiService.getAuthorizationConfig())
  }

  /**
   * @param {number} [page]
   * @param {number} [perPage]
   * @returns {Promise<{
   *    followingUsers: loavies.models.user.UserModel[],
   *    totals: ApiTotalsModel
   * }>}
   */
  getFollowingUsers({ page, perPage } = {}) {
    const config = this.$nodeApiService.getAuthorizationConfig()
    const queryParameters = new URLSearchParams({
      ...page && { page },
      ...perPage && { perPage },
    }).toString()
    const formattedQueryParameters = queryParameters ? `?${queryParameters}` : ''

    return this.nodeApiInstance
      .get(`/user/following-users${formattedQueryParameters}`, config)
      .then(response => {
        const { data } = response

        return {
          followingUsers: data?.users ? data.users.map(user => mapUser(user)) : [],
          totals: new ApiTotalsModel(data.totals),
        }
      })
  }

  /**
   * @returns {Promise<loavies.models.user.UserAddressModel[]>}
   */
  getAddresses() {
    return this.microserviceApiInstance
      .get('/identity/user')
      .then(response => {
        if (!response.data.addresses?.length) {
          return []
        }

        return response.data.addresses.map(address => mapUserAddress(address))
      })
  }

  /**
   * @param {loavies.models.user.CreateIdentityUserAddressModel} address
   * @returns {Promise<loavies.models.user.UserAddressModel>}
   */
  createOrUpdateAddress(address) {
    return this.microserviceApiInstance
      .post('/identity/user/address', address)
      .then(response => mapUserAddress(response.data))
  }

  /**
   * @param {string} addressId
   * @return {Promise}
   */
  deleteAddress(addressId) {
    return this.microserviceApiInstance
      .delete(`/identity/user/address/${addressId}`)
  }

  /**
   * @params {loavies.models.user.UserEditFormModel} userEditForm
   * @returns {Promise<loavies.models.user.UserModel>}
   */
  async updateUserData(userEditForm) {
    const updatedUser = await this.$marketplaceUserService.updateEditableUserData(userEditForm)

    if (
      userEditForm.publicData?.storeName
      || userEditForm.avatar
    ) {

      await this.$elasticUpdateService.updateMyListings()
    }

    return updatedUser
  }

  /**
   * @param {boolean} storeIsClosed
   * @returns {Promise<loavies.models.user.UserModel>}
   */
  async updateStoreIsClosed(storeIsClosed) {
    const updatedUser = await this.$marketplaceUserService.setStoreIsClosed(storeIsClosed)

    await this.$elasticUpdateService.updateMyListings()

    return updatedUser
  }
}
