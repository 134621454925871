import {
  MAPPED_TRANSITION_NAMES_TO_TRANSACTION_STATES,
  TRANSACTION_STATES,
  USER_VISIBLE_TRANSACTION_STATES_PROVIDER,
  ROLE,
  TRANSITION_NAMES,
} from '@loavies/c2c-models'
import { MY_LISTING, MY_ORDER } from '~/lib/constants/routes'

/**
 * @typedef {TransactionHelperService}
 * @alias this.$transactionHelperService
 */
export class TransactionHelperService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.i18n = context.i18n
    this.localePath = context.localePath
  }

  init() {
    this.$currencyService = this.context.$currencyService
    this.$dateTimeService = this.context.$dateTimeService
    this.$staticDataService = this.context.$staticDataService
  }

  /**
   * @param {loavies.models.transaction.TransactionModel[]} transactions
   * @return {string[]}
   * @example ['pending-payment', 'item-sent']
   */
  getUniqueTransactionStates(transactions) {
    return [... new Set(transactions.map(transaction => transaction.state))]
      .filter(transactionState => USER_VISIBLE_TRANSACTION_STATES_PROVIDER.includes(transactionState))
  }

  /**
   * @param {string} transactionState
   * @return {string}
   */
  getTransactionStateTranslation(transactionState) {
    if (!transactionState) {
      // For develop purposes only. Old transactions can have
      // deprecated transitions which don't correspond to transaction states.
      return 'No transaction state'
    }

    return this.i18n.t(`transaction_state_${transactionState.replace(/-/g, '_')}`)
  }

  /**
   * @param transactionState
   * @return {string[]}
   * @example ['transition/delivered', 'transition/complete-by-customer']
   */
  getTransitionNamesByTransactionState(transactionState) {
    return Object.entries(MAPPED_TRANSITION_NAMES_TO_TRANSACTION_STATES)
      .filter(([key, value]) => value === transactionState) // eslint-disable-line
      .map(([key, value]) => key) // eslint-disable-line
  }

  /**
   * @param {string} transactionState
   * @param {string} userRole
   * @return {string}
   */
  getTransactionStateTitle(transactionState, userRole) {
    return userRole === ROLE.CUSTOMER ?
      this.getCustomerTransactionStateTitle(transactionState) :
      this.getProviderTransactionStateTitle(transactionState)
  }

  /**
   * @param {loavies.models.transaction.TransactionModel} transaction
   * @param {string} [transactionState]
   * @return {string}
   */
  getTransactionStateDescription(transaction, transactionState) {
    return transaction.userRole === ROLE.CUSTOMER ?
      this.getCustomerTransactionStateDescription(transaction, transactionState) :
      this.getProviderTransactionStateDescription(transaction, transactionState)
  }

  /**
   * @param {string} transactionState
   * @return {string}
   */
  getCustomerTransactionStateTitle(transactionState) {
    if (transactionState === TRANSACTION_STATES.PENDING_PAYMENT) {
      return this.i18n.t('order_transaction_actions_pending_payment_title')
    }

    if (transactionState === TRANSACTION_STATES.PAYMENT_COMPLETE) {
      return this.i18n.t('order_transaction_actions_payment_complete_title')
    }

    if (transactionState === TRANSACTION_STATES.NOT_DELIVERED) {
      return this.i18n.t('order_transaction_actions_not_delivered_title')
    }

    if (transactionState === TRANSACTION_STATES.ITEM_SENT) {
      return this.i18n.t('order_transaction_actions_item_sent_title')
    }

    if (transactionState === TRANSACTION_STATES.DELIVERED) {
      return this.i18n.t('order_transaction_actions_delivered_title')
    }

    if (
      transactionState === TRANSACTION_STATES.COMPLETED
      || transactionState === TRANSACTION_STATES.COMPLETED_AND_REVIEWED
    ) {
      return this.i18n.t('order_transaction_actions_completed_title')
    }

    if (transactionState === TRANSACTION_STATES.DISPUTE) {
      return this.i18n.t('order_transaction_actions_dispute_title')
    }

    if (transactionState === TRANSACTION_STATES.REFUNDED) {
      return this.i18n.t('order_transaction_actions_refunded_title')
    }

    return ''
  }

  /**
   * @param {loavies.models.transaction.TransactionModel} transaction
   * @param {string} [transactionState]
   * @return {string}
   */
  getCustomerTransactionStateDescription(transaction, transactionState) {
    const localTransactionState = transactionState ?? transaction.state

    if (localTransactionState === TRANSACTION_STATES.PENDING_PAYMENT) {
      return this.i18n.t('order_transaction_pending_payment_description')
    }

    if (localTransactionState === TRANSACTION_STATES.PAYMENT_COMPLETE) {
      return this.i18n.t('order_transaction_payment_complete_description')
    }

    if (localTransactionState === TRANSACTION_STATES.NOT_DELIVERED) {
      const hasBeenSent = transaction.transitionHistory.some(transition => transition.name === TRANSITION_NAMES.SEND_ITEM)

      if (hasBeenSent) {
        return this.i18n.t('order_transaction_not_delivered_after_sent_description', {
          amount: this.$currencyService.formatCentsToLocalePrice(transaction.payinTotal.amount),
        })
      }

      return this.i18n.t('order_transaction_not_delivered_description', {
        provider: transaction.provider.displayName,
        amount: this.$currencyService.formatCentsToLocalePrice(transaction.payinTotal.amount),
      })
    }

    if (localTransactionState === TRANSACTION_STATES.ITEM_SENT) {
      const formattedPlannedDeliveryTime = this.getFormattedPlannedDeliveryTime(transaction)

      return this.i18n.t('order_transaction_item_sent_description', {
        provider: transaction.provider.displayName,
        deliveryDate: formattedPlannedDeliveryTime,
      })
    }

    if (localTransactionState === TRANSACTION_STATES.DELIVERED) {
      return this.i18n.t('order_transaction_delivered_description')
    }

    if (localTransactionState === TRANSACTION_STATES.COMPLETED) {
      return this.i18n.t('order_transaction_completed_give_review_description', {
        provider: transaction.provider.displayName,
      })
    }

    if (localTransactionState === TRANSACTION_STATES.COMPLETED_AND_REVIEWED) {
      return this.i18n.t('order_transaction_completed_description')
    }

    if (localTransactionState === TRANSACTION_STATES.DISPUTE) {
      return this.i18n.t('order_transaction_dispute_description')
    }

    if (localTransactionState === TRANSACTION_STATES.REFUNDED) {
      return this.i18n.t('order_transaction_refunded_description', {
        provider: transaction.provider.displayName,
        amount: this.$currencyService.formatCentsToLocalePrice(transaction.payinTotal.amount),
      })
    }

    return ''
  }

  /**
   * @param {string} transactionState
   * @return {string}
   */
  getProviderTransactionStateTitle(transactionState) {
    if (transactionState === TRANSACTION_STATES.PAYMENT_COMPLETE) {
      return this.i18n.t('transaction_details_payment_complete_title')
    }

    if (transactionState === TRANSACTION_STATES.NOT_DELIVERED) {
      return this.i18n.t('transaction_details_not_delivered_title')
    }

    if (transactionState === TRANSACTION_STATES.ITEM_SENT) {
      return this.i18n.t('transaction_details_item_sent_title')
    }

    if (transactionState === TRANSACTION_STATES.DELIVERED) {
      return this.i18n.t('transaction_details_delivered_title')
    }

    if (
      transactionState === TRANSACTION_STATES.COMPLETED
      || transactionState === TRANSACTION_STATES.COMPLETED_AND_REVIEWED
    ) {
      return this.i18n.t('transaction_details_completed_title')
    }

    if (transactionState === TRANSACTION_STATES.DISPUTE) {
      return this.i18n.t('transaction_details_dispute_title')
    }

    if (transactionState === TRANSACTION_STATES.REFUNDED) {
      return this.i18n.t('transaction_details_refunded_title')
    }

    return ''
  }

  /**
   *
   * @param {loavies.models.transaction.TransactionModel} transaction
   * @param {string} [transactionState]
   * @return {string}
   */
  getProviderTransactionStateDescription(transaction, transactionState) {
    const global = this.$staticDataService.generalConfiguration.global
    const percentageBonusCredits = global.percentageBonusCredits
    const localTransactionState = transactionState ?? transaction.state

    if (localTransactionState === TRANSACTION_STATES.PAYMENT_COMPLETE) {
      const lastTransitionDateTime = new Date(transaction.lastTransitionedAt)
      const shipBeforeDateTime =  this.$dateTimeService.addWorkingDaysToDateTime(new Date(lastTransitionDateTime.getTime()), 5)
      const time = lastTransitionDateTime.getHours() > 20 ? `${lastTransitionDateTime.getHours()}:${lastTransitionDateTime.getMinutes()}` : '20:00'
      const formattedDateTime = `${this.$dateTimeService.intlDateTimeFormat(shipBeforeDateTime)} ${time}`

      return this.i18n.t('transaction_details_payment_complete_description', {
        shipBeforeDateTime: formattedDateTime,
      })
    }

    if (localTransactionState === TRANSACTION_STATES.NOT_DELIVERED) {
      const hasBeenSent = transaction.transitionHistory.some(transition => transition.name === TRANSITION_NAMES.SEND_ITEM)

      if (hasBeenSent) {
        return this.i18n.t('transaction_details_not_delivered_after_sent_description')
      }

      return this.i18n.t('transaction_details_not_delivered_description')
    }

    if (localTransactionState === TRANSACTION_STATES.ITEM_SENT) {
      const formattedPlannedDeliveryTime = this.getFormattedPlannedDeliveryTime(transaction)

      return this.i18n.t('transaction_details_item_sent_description', {
        deliveryDate: formattedPlannedDeliveryTime,
      })
    }

    if (localTransactionState === TRANSACTION_STATES.DELIVERED) {
      return this.i18n.t('transaction_details_delivered_description')
    }

    if (localTransactionState === TRANSACTION_STATES.COMPLETED) {
      const bonusMoney = (transaction.payoutTotal.amount / 100) * percentageBonusCredits

      return this.i18n.t('transaction_details_completed_description', {
        receivingMoney: this.$currencyService.formatCentsToLocalePrice(transaction.payoutTotal.amount),
        bonusMoney: this.$currencyService.formatCentsToLocalePrice(bonusMoney),
      })
    }

    if (localTransactionState === TRANSACTION_STATES.COMPLETED_AND_REVIEWED) {
      return this.i18n.t('transaction_details_completed_with_review_description')
    }

    if (localTransactionState === TRANSACTION_STATES.DISPUTE) {
      return this.i18n.t('transaction_details_dispute_description')
    }

    if (localTransactionState === TRANSACTION_STATES.REFUNDED) {
      return this.i18n.t('transaction_details_refunded_description', {
        refundAmount: this.$currencyService.formatCentsToLocalePrice(transaction.payinTotal.amount),
      })
    }

    return ''
  }

  /**
   * @param {loavies.models.transaction.TransactionModel} transaction
   * @returns {string}
   */
  getTransactionLink(transaction) {
    const userRole = transaction.userRole
    const transactionId = transaction.id
    const listingId = transaction.listing?.id ?? ''

    const path = userRole === ROLE.CUSTOMER
      ? `${MY_ORDER}/${transactionId}`
      : `${MY_LISTING}/${listingId}`

    return this.localePath(path)
  }

  /**
   * @param {loavies.models.transaction.TransactionModel} transaction
   * @returns {boolean}
   */
  transactionStateIsFinished(transaction) {
    return [
      TRANSACTION_STATES.COMPLETED,
      TRANSACTION_STATES.COMPLETED_AND_REVIEWED,
      TRANSACTION_STATES.REFUNDED,
    ].includes(transaction?.state)
  }

  /**
   * @param {loavies.models.transaction.TransactionModel} transaction
   * @returns {string}
   */
  getFormattedPlannedDeliveryTime(transaction) {
    const plannedDeliveryTimeframeStart =
      transaction.metaData?.parcels?.[0]?.status?.plannedDeliveryTimeframeStart
    ?? transaction.metaData?.parcels?.[0]?.status?.nextPlannedDeliveryTimeframeStart
    const plannedDeliveryTimeframeEnd =
      transaction.metaData?.parcels?.[0]?.status?.plannedDeliveryTimeframeEnd
    ?? transaction.metaData?.parcels?.[0]?.status?.nextPlannedDeliveryTimeframeEnd

    if (plannedDeliveryTimeframeStart) {
      const startDateTime = new Date(plannedDeliveryTimeframeStart)
      const endDateTime = new Date(plannedDeliveryTimeframeEnd)
      const frameStartTime = `${startDateTime.getHours()}:00`
      const frameEndTime = `${endDateTime.getHours()}:00`
      const formattedDate = this.$dateTimeService.intlDateTimeFormat(startDateTime)

      return this.i18n.t('expected_delivery_time_format', { date: formattedDate, startTime: frameStartTime, endTime: frameEndTime })
    }

    const fallBackDate = transaction.metaData?.parcels?.[0]?.status?.createdAt

    if (!fallBackDate) return this.i18n.t('unknown')

    const fallBackDateObject = new Date(fallBackDate)
    // If it's a friday next possible delivery date is monday
    const shipmentDays = fallBackDateObject.getDay() === 5 ? 3 : 2
    const expectedDeliveryDate = new Date(fallBackDateObject.setDate(fallBackDateObject.getDate() + shipmentDays))

    return this.$dateTimeService.intlDateTimeFormat(expectedDeliveryDate)
  }

  /**
   * @param {loavies.models.transaction.LineItemModel} lineItem
   * @return {string}
   */
  getLineItemCodeTranslation(lineItem) {
    const listingId = lineItem.getListingId()

    if (listingId) {
      // Used as a backup if listing can't be fetched
      return this.i18n.t('line_item_listing')
    }

    const regex = /line-item\/(.+)/
    const match = lineItem.code.match(regex)
    const translationToken = match?.[1]
      ? `line_item_${match?.[1].replace(/-/g, '_')}`
      : null

    return translationToken ? this.i18n.t(translationToken) : ''
  }
}
