
  import { mapState, mapActions } from 'vuex'

  import { OVERLAYS } from '~/lib/constants/overlays'

  export default {
    computed: {
      ...mapState({
        isMobile: state => state.isMobile,
        settings: state => state.overlay.settings,
        mobile: state => state.overlay.mobile,
        desktop: state => state.overlay.desktop,
        activeFlyIn: state => state.overlay.activeFlyIn,
        activeFlyInOptions: state => state.overlay.activeFlyInOptions,
        activeFlyInTransition: state => state.overlay.activeFlyInTransition,
        activeAppTray: state => state.overlay.activeAppTray,
        activeAppTrayOptions: state => state.overlay.activeAppTrayOptions,
        activeAppTrayTransition: state => state.overlay.activeAppTrayTransition,
        activeAppModal: state => state.overlay.activeAppModal,
        activeAppModalOptions: state => state.overlay.activeAppModalOptions,
        activeAppModalTransition: state => state.overlay.activeAppModalTransition,
      }),
      // Desktop/mobile key values get assigned in favor over the general settings
      desktopSettings() {
        return this.desktop ? { ...this.settings, ...this.desktop } : this.settings
      },
      mobileSettings() {
        return this.mobile ? { ...this.settings, ...this.mobile } : this.settings
      },
      overlaySettings() {
        return this.isMobile ? this.mobileSettings : this.desktopSettings
      },
    },
    watch: {
      '$route.path'() {
        this.closeFlyIn()
      },
      isMobile() {
        this.closeFlyIn()
      },
      overlaySettings: {
        immediate: true,
        handler() {
          this.setCorrectOverlay()
        },
      },
    },
    methods: {
      ...mapActions({
        setActiveFlyIn: 'overlay/setActiveFlyIn',
        setActiveFlyInOptions: 'overlay/setActiveFlyInOptions',
        setActiveFlyInTransition: 'overlay/setActiveFlyInTransition',
        closeFlyIn: 'overlay/closeFlyIn',
        closeAppTray: 'overlay/closeAppTray',
        closeAppModal: 'overlay/closeAppModal',
        disableScroll: 'disableScroll',
        setActiveAppModal: 'overlay/setActiveAppModal',
        setActiveAppModalTransition: 'overlay/setActiveAppModalTransition',
        setActiveAppModalOptions: 'overlay/setActiveAppModalOptions',
        setActiveAppTray: 'overlay/setActiveAppTray',
        setActiveAppTrayTransition: 'overlay/setActiveAppTrayTransition',
        setActiveAppTrayOptions: 'overlay/setActiveAppTrayOptions',
      }),
      setCorrectOverlay() {
        if (!this.overlaySettings) {
          return
        }

        if (this.overlaySettings.type === OVERLAYS.APP_FLY_IN) {
          this.setActiveFlyInTransition(this.overlaySettings?.transition ?? 'fly-in-transition')
          this.setActiveFlyInOptions(this.overlaySettings.options)
          this.setActiveFlyIn(this.overlaySettings.component)
        }

        if (this.overlaySettings.type === OVERLAYS.APP_TRAY) {
          this.setActiveAppTrayTransition(this.overlaySettings?.transition ?? 'app-tray-transition')
          this.setActiveAppTrayOptions(this.overlaySettings.options)
          this.setActiveAppTray(this.overlaySettings.component)
        }

        if (this.overlaySettings.type === OVERLAYS.APP_MODAL) {
          this.setActiveAppModalTransition(this.overlaySettings?.transition ?? 'fade')
          this.setActiveAppModalOptions(this.overlaySettings.options)
          this.setActiveAppModal(this.overlaySettings.component)
        }
      },
      disableBodyScroll(element) {
        this.disableScroll({ element })
      },
    },
  }
