import { AGGREGATION_FILTER_KEYS } from '~/lib/services/api/elastic/elastic-api-queries'

/**
 * @typedef {FiltersTranslationService}
 * @alias this.$filtersTranslationService
 */
export class FiltersTranslationService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.i18n = context.i18n
  }

  init() {
    this.$listingTranslationService = this.context.$listingTranslationService
  }

  /**
   * @param {loavies.models.listing.ListingFilter} filter
   * @return {string}
   */
  getFilterTranslation(filter) {
    return this.i18n.t(`listing_filter_${filter.key.split('.').pop().replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)}`)
  }

  /**
   * @param {loavies.models.listing.ListingFilterOption} filterOption
   * @return {string}
   */
  getFilterOptionTranslation(filterOption) {
    switch (filterOption.filterKey) {
      case AGGREGATION_FILTER_KEYS.MODEL: {
        return this.$listingTranslationService.getProductGroupTranslation(filterOption.key)
      }
      case AGGREGATION_FILTER_KEYS.COLOR: {
        return this.$listingTranslationService.getProductColorTranslation(filterOption.key)
      }
      case AGGREGATION_FILTER_KEYS.CONDITION:
      case AGGREGATION_FILTER_KEYS.SELLER_BADGES: {
        return this.i18n.t(filterOption.key)
      }
      case AGGREGATION_FILTER_KEYS.LABEL: {
        return this.$listingTranslationService.getListingLabelTranslation(filterOption.key)
      }

      default: {
        return filterOption.key
      }
    }
  }
}
