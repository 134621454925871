import {
  MarketplaceService,
  MarketplaceUserService,
  MarketplaceAuthenticationService,
  MarketplaceListingService,
  MarketplaceListingsService,
  MarketplaceReviewsService,
  MarketplaceImageService,
  MarketplaceTransitionService,
  MarketplaceTransactionsService,
} from '@loavies/c2c-marketplace-lib'

export default (context, inject) => {
  const services = {}
  const clientId = context.$config.sharetribeClientId
  const marketplaceService = new MarketplaceService(clientId)

  /**
   * @typedef MarketplaceNuxtContext
   * @type {Object}
   * @property {MarketplaceUserService} $marketplaceUserService
   * @property {MarketplaceAuthenticationService} $marketplaceAuthenticationService
   * @property {MarketplaceListingService} $marketplaceListingService
   * @property {MarketplaceListingsService} $marketplaceListingsService
   * @property {MarketplaceReviewsService} $marketplaceReviewsService
   * @property {MarketplaceImageService} $marketplaceImageService
   * @property {MarketplaceTransitionService} $marketplaceTransitionService
   * @property {MarketplaceTransactionsService} $marketplaceTransactionsService
   */

  /**
   * Important!
   * Make sure to add this name to the @alias tag in its
   * corresponding JSDoc block for correct IDE autocompletion
   */
  services.marketplaceImageService = new MarketplaceImageService(marketplaceService)
  services.marketplaceUserService = new MarketplaceUserService(marketplaceService)
  services.marketplaceAuthenticationService = new MarketplaceAuthenticationService(marketplaceService)
  services.marketplaceImageService = new MarketplaceImageService(marketplaceService)
  services.marketplaceListingService = new MarketplaceListingService(marketplaceService)
  services.marketplaceListingsService = new MarketplaceListingsService(marketplaceService)
  services.marketplaceReviewsService = new MarketplaceReviewsService(marketplaceService)
  services.marketplaceTransitionService = new MarketplaceTransitionService(marketplaceService)
  services.marketplaceTransactionsService = new MarketplaceTransactionsService(marketplaceService)

  const serviceKeys = Object.keys(services)

  // Inject services into the Vue context
  serviceKeys.forEach(key => {
    inject(key, services[key])
  })
}
