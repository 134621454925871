export default {
  /**
   * @param context
   * @return {Promise<void>}
   */
  resetState(context) {
    context.commit('RESET_STATE')
    this.$clientStorageService.setWishListIds([])
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingModel[]} listings
   */
  setWishlist(context, listings) {
    context.commit('SET_WISHLIST', listings)

    const ids = context.state.wishlist.map(listing => listing.id)
    this.$clientStorageService.setWishListIds(ids)
  },

  /**
   * Used to set the initial wishlist from the wishlist id's stored in the UserModel
   * @param context
   * @param {string[]} listingIds
   */
  async setWishlistByListingsIds(context, listingIds) {
    const listings = await this.$elasticApiService.getListingsByIds(listingIds)

    return context.dispatch('setWishlist', listings)
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingModel} listing
   */
  async addToWishlist(context, listing) {
    try {
      const wishlistIncludesListing = context.state.wishlist
        .findIndex(wishlistItem => wishlistItem.id === listing.id) !== -1

      if (wishlistIncludesListing) {
        return
      }

      context.commit('ADD_TO_WISHLIST', listing)

      const ids = context.state.wishlist.map(listing => listing.id)
      this.$clientStorageService.setWishListIds(ids)

      const isAuthenticated = context.rootGetters['user/isAuthenticated']

      if (isAuthenticated) {
        const updatedUser = await this.$marketplaceUserService.setWishList(context.state.wishlist.map(listing => listing.id))

        await context.dispatch('user/setUser', updatedUser, { root: true })
      }

      this.$bloomreach.wishlistUpdate(listing, 'add', context.state.wishlist)

      return this.$listingHandlerService.increaseListingWishlistCount(listing.id)
    } catch (error) {
      return context.dispatch('toast/showToastMessage', {
        text: error.message,
        icon: 'IconClose',
      }, { root: true })
    }
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingModel} listing
   */
  async removeFromWishlist(context, listing) {
    try {
      const filteredWishlist = context.state.wishlist.filter(wishlistItem => wishlistItem.id !== listing.id)
      const isAuthenticated = context.rootGetters['user/isAuthenticated']

      await context.dispatch('setWishlist', filteredWishlist)

      if (isAuthenticated) {
        const updatedUser = await this.$marketplaceUserService.setWishList(filteredWishlist.map(listing => listing.id))

        await context.dispatch('user/setUser', updatedUser, { root: true })
      }

      this.$bloomreach.wishlistUpdate(listing, 'remove', context.state.wishlist)

      return this.$listingHandlerService.decreaseListingWishlistCount(listing.id)
    } catch (error) {
      return context.dispatch('toast/showToastMessage', {
        text: error.message,
        icon: 'IconClose',
      }, { root: true })
    }
  },

  /**
   * @param context
   * @return {Promise<void>}
   */
  async synchronizeLocalAndUserWishlist(context) {
    const user = await this.$marketplaceUserService
      .getCurrentUser()
      .catch(() => null)

    if (!user) {
      return
    }

    const userWishlistIds = user.privateData?.wishlist ?? []
    const localWishlistIds = this.$clientStorageService.getWishListIds()

    if (localWishlistIds.length) {
      const combinedWishlistIds = userWishlistIds.concat(localWishlistIds.filter(item => userWishlistIds.indexOf(item) < 0))

      await context.dispatch('setWishlistByListingsIds', combinedWishlistIds)

      // Update wishlist of user
      await this.$marketplaceUserService.setWishList(combinedWishlistIds)
    } else {
      // If there are no local wishlist items, just set the user's wishlist
      await context.dispatch('setWishlistByListingsIds', userWishlistIds)
    }
  },
}
