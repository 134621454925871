export default function () {
  return {
    address: null,
    selectedPaymentMethod: null,
    paymentMethodData: null,
    newUserEmail: null,
    newUserEmailExists: false,
    newUserPassword: null,
  }
}
