import Vue from 'vue'
import defaultState from './state'

export default {
  /**
   * @param state
   */
  RESET_STATE(state) {
    Object.assign(state, defaultState())
  },

  /**
   * @param state
   * @param {loavies.models.transaction.TransactionModel[]} transactions
   */
  SET_SALE_TRANSACTIONS(state, transactions) {
    state.saleTransactions = transactions
  },

  /**
   * @param state
   * @param {loavies.models.transaction.TransactionModel[]} transactions
   */
  SET_ORDER_TRANSACTIONS(state, transactions) {
    state.orderTransactions = transactions
  },

  /**
   * @param state
   * @param {loavies.models.transaction.TransactionModel} transaction
   */
  SET_TRANSACTION(state, transaction) {
    const indexInOrderTransactions = state.orderTransactions.findIndex(orderTransaction => orderTransaction.id === transaction.id)
    const indexInSaleTransactions = state.saleTransactions.findIndex(saleTransaction => saleTransaction.id === transaction.id)

    if (indexInOrderTransactions > -1) {
      Vue.set(state.orderTransactions, indexInOrderTransactions, transaction)
    }

    if (indexInSaleTransactions > -1) {
      Vue.set(state.saleTransactions, indexInSaleTransactions, transaction)
    }
  },
}
