
  import AppAccordion from '@loavies/component-library/dist/app-accordion'
  import AppSelect from '@loavies/component-library/dist/app-select'

  import LocaleSwitch from '~/mixins/locale-switch'

  export default {
    components: {
      AppAccordion,
      AppSelect,
    },
    mixins: [LocaleSwitch],
    computed: {
      globalFooterData() {
        return this.$staticDataService.globalFooter
      },
      newsletterItem() {
        return this.globalFooterData?.newsletter
      },
      collapsableMenus() {
        return this.globalFooterData?.collapsableMenus
      },
      legalLinks() {
        return this.globalFooterData?.legalLinks
      },
    },
  }
