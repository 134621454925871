export default {
  /**
   * @param state
   * @param {string} currentRoutePath
   */
  SET_CURRENT_ROUTE_PATH(state, currentRoutePath) {
    state.currentRoutePath = currentRoutePath
  },

  /**
   * @param state
   * @param {string} previousRouteName
   */
  SET_PREVIOUS_ROUTE_NAME(state, previousRouteName) {
    state.previousRouteName = previousRouteName
  },

  /**
   * @param state
   * @param {string} previousRoutePath
   */
  SET_PREVIOUS_ROUTE_PATH(state, previousRoutePath) {
    state.previousRoutePath = previousRoutePath
  },

  /**
   * @param state
   * @param {string} previousCategoryRoutePath
   */
  SET_PREVIOUS_CATEGORY_ROUTE_PATH(state, previousCategoryRoutePath) {
    state.previousCategoryRoutePath = previousCategoryRoutePath
  },
}
