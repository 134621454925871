
  import { mapState, mapActions, mapGetters } from 'vuex'

  import IconPlusBorderBorderless from '@loavies/component-library/dist/icons/icon-plus-border-borderless'
  import IconArrowRight from '@loavies/component-library/dist/icons/icon-arrow-right'
  import IconWalletBorderless from '@loavies/component-library/dist/icons/icon-wallet-borderless'
  import IconLabelBorderless from '@loavies/component-library/dist/icons/icon-label-borderless'
  import IconDashedLabelBorderless from '@loavies/component-library/dist/icons/icon-dashed-label-borderless'
  import IconFileCheckmarkBorderless from '@loavies/component-library/dist/icons/icon-file-checkmark-borderless'
  import IconBellBorderless from '@loavies/component-library/dist/icons/icon-bell-borderless'
  import IconStarOutlineBorderless from '@loavies/component-library/dist/icons/icon-star-outline-borderless'
  import IconAccountLoggedOutBorderless from '@loavies/component-library/dist/icons/icon-account-logged-out-borderless'
  import IconCartCheckBorderless from '@loavies/component-library/dist/icons/icon-cart-check-borderless'
  import IconOff from '@loavies/component-library/dist/icons/icon-off'
  import IconFollowBorderless from '@loavies/component-library/dist/icons/icon-follow-borderless'
  import IconVacationBorderless from '@loavies/component-library/dist/icons/icon-vacation-borderless'
  import AppLabel from '@loavies/component-library/dist/app-label'

  import * as ROUTES from '~/lib/constants/routes'
  import { TABS } from '~/lib/constants/account-menu'
  import UnreadMessages from '~/mixins/unread-messages'

  export default {
    components: {
      IconPlusBorderBorderless,
      IconArrowRight,
      IconWalletBorderless,
      IconLabelBorderless,
      IconDashedLabelBorderless,
      IconFileCheckmarkBorderless,
      IconBellBorderless,
      IconStarOutlineBorderless,
      IconOff,
      IconAccountLoggedOutBorderless,
      IconCartCheckBorderless,
      IconFollowBorderless,
      IconVacationBorderless,
      AppLabel,
    },
    mixins: [UnreadMessages],
    props: {
      defaultTab: {
        type: String,
        default: TABS.ACCOUNT,
        validator(value) {
          return !value || Object.values(TABS).includes(value)
        },
      },
      borderlessList: {
        type: Boolean,
        default: false,
      },
      hideArrows: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['navigate'],
    data() {
      return {
        routes: { ...ROUTES },
        tab: this.defaultTab,
        accountTab: TABS.ACCOUNT,
        shopTab: TABS.SHOP,
        loaviesAccountLinks: {
          overview: `https://loavies.com/${this.$i18n.locale}/customer/account/`,
          orders: `https://loavies.com/${this.$i18n.locale}/customer/orders/`,
        },
      }
    },
    computed: {
      ...mapGetters({
        draftListings: 'user/draftListings',
        amountActiveListings: 'user/getActiveListingsCount',
      }),
      ...mapState({
        isUserInitialized: state => state.user.isInitialized,
        user: state => state.user.user,
        wallet: state => state.wallet.wallet,
      }),
      storeIsClosed() {
        return this.user.publicData.storeIsClosed ?? false
      },
      vacationModeSubtext() {
        return this.storeIsClosed ? this.$t('on') : this.$t('activate')
      },
      storeName() {
        if (!this.user) {
          return ''
        }

        return this.$userHelperService.getFormattedStoreName(this.user)
      },
    },
    methods: {
      ...mapActions({
        handleLogout: 'user/logout',
      }),
      setCurrentTab(tab) {
        this.tab = tab
      },
      async logout() {
        await this.handleLogout()

        await this.$router
          .push(this.localePath('/'))
          .catch(() => {})
      },
    },
  }
