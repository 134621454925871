export default {
  /**
   * @param state
   * @returns {boolean}
   */
  overlayIsActive(state) {
    return !!state.activeAppModal
      || !!state.activeAppTray
      || !!state.activeFlyIn
  },
}
