export default {
  /**
   * @param context
   * @param {string} componentName
   */
  setActiveAppModal(context, componentName) {
    context.commit('SET_ACTIVE_APP_MODAL', componentName)
  },

  /**
   * @param context
   * @param {string} transitionName
   */
  setActiveAppModalTransition(context, transitionName) {
    context.commit('SET_ACTIVE_APP_MODAL_TRANSITION', transitionName)
  },

  /**
   * @param context
   * @param {Object} options
   */
  setActiveAppModalOptions(context, options) {
    context.commit('SET_ACTIVE_APP_MODAL_OPTIONS', options)
  },

  /**
   * @param context
   * @param {string} componentName
   */
  setActiveAppTray(context, componentName) {
    context.commit('SET_ACTIVE_APP_TRAY', componentName)
  },

  /**
   * @param context
   * @param {string} transitionName
   */
  setActiveAppTrayTransition(context, transitionName) {
    context.commit('SET_ACTIVE_APP_TRAY_TRANSITION', transitionName)
  },

  /**
   * @param context
   * @param {Object} options
   */
  setActiveAppTrayOptions(context, options) {
    context.commit('SET_ACTIVE_APP_TRAY_OPTIONS', options)
  },

  /**
   * @param context
   * @param {string} componentName
   */
  setActiveFlyIn(context, componentName) {
    context.commit('SET_ACTIVE_FLY_IN', componentName)
  },

  /**
   * @param context
   * @param {string} transitionName
   */
  setActiveFlyInTransition(context, transitionName) {
    context.commit('SET_ACTIVE_FLY_IN_TRANSITION', transitionName)
  },

  /**
   * @param context
   * @param {Object} options
   */
  setActiveFlyInOptions(context, options) {
    context.commit('SET_ACTIVE_FLY_IN_OPTIONS', options)
  },

  /**
   * @param context
   * @param {Object} payload
   * @param {Object} payload.desktop - Specific settings for desktop
   * @param {Object} payload.mobile - Specific settings for mobile
   * @param {Object} payload.settings
   * @param {string} payload.settings.type - Type of overlay
   * @param {string} payload.settings.component - Specific component to show
   * @param {Object} payload.settings.options - Options for the overlay
   */
  setCurrentOverlay(context, payload) {
    context.commit('SET_CURRENT_OVERLAY', payload)
  },

  /**
   * @param context
   */
  closeAppModal(context) {
    context.commit('CLOSE_APP_MODAL')
    return enableScroll(context)
  },

  /**
   * @param context
   */
  closeAppTray(context) {
    context.commit('CLOSE_APP_TRAY')
    return enableScroll(context)
  },

  /**
   * @param context
   */
  closeFlyIn(context) {
    context.commit('CLOSE_FLY_IN')
    return enableScroll(context)
  },
}

/**
 * Only enable scroll if there is not another
 * AppModal/AppTray/FlyIn active.
 * @param context
 */
function enableScroll(context) {
  if (!context.getters.overlayIsActive) {
    return context.dispatch('enableScroll', null, { root: true })
  }
}
