/**
 * @typedef {FileApiService}
 * @alias this.$fileApiService
 */
export class FileApiService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
  }

  init() {
    this.$nodeApiService = this.context.$nodeApiService

    this.nodeApiInstance = this.$nodeApiService.instance
  }

  /**
   * @param {File} image
   * @return {Promise<string>}
    */
  async uploadUserBanner(image) {
    const formData = new FormData()
    formData.append('file', image)

    return this.nodeApiInstance
      .post(
        '/files/user/banner',
        formData,
        {
          headers: {
            ...this.$nodeApiService.getAuthorizationConfig().headers,
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then(response => response.data)
  }
}
