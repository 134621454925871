
  import { mapState, mapActions } from 'vuex'

  import AppButton from '@loavies/component-library/dist/app-button'
  import AppPopover from '@loavies/component-library/dist/app-popover'
  import AppAvatar from '@loavies/component-library/dist/app-avatar'

  import {
    FLY_IN_NAMES,
    OVERLAYS,
  } from '~/lib/constants/overlays'
  import { FALLBACK_AVATAR_IMAGE } from '~/lib/constants/user'

  export default {
    components: {
      AppButton,
      AppPopover,
      AppAvatar,
    },
    props: {
      showNotification: {
        type: Boolean,
        default: false,
      },
      isAuthenticated: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState({
        isMobile: state => state.isMobile,
      }),
      fallBackAvatar() {
        return FALLBACK_AVATAR_IMAGE
      },
    },
    methods: {
      ...mapActions({
        setCurrentOverlay: 'overlay/setCurrentOverlay',
      }),
      openAccountMenuFlyIn() {
        this.setCurrentOverlay({
          settings: {
            type: OVERLAYS.APP_FLY_IN,
            component: FLY_IN_NAMES.ACCOUNT_MENU_FLY_IN,
          },
        })
      },
      openAuthFlyIn() {
        this.setCurrentOverlay({
          settings: {
            type: OVERLAYS.APP_FLY_IN,
            component: FLY_IN_NAMES.AUTH_FLY_IN,
          },
        })
      },
    },
  }
