export default {

  /**
   * @param context
   * @param {string} query
   * @return {Promise<void>}
   */
  setQuery(context, query) {
    context.commit('SET_QUERY', query)
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingModel[]} listings
   * @param {loavies.models.api.ApiTotalsModel} totals
   * @return {Promise<void>}
   */
  setListingsAndTotals(context, { listings, totals }) {
    context.commit('SET_LISTINGS', listings)
    context.commit('SET_TOTALS', totals)
  },

  /**
   * @param context
   * @return {Promise<void>}
   */
  clearListingsAndTotals(context) {
    context.commit('CLEAR_LISTINGS')
    context.commit('CLEAR_TOTALS')
  },
}
