import { render, staticRenderFns } from "./header-only.vue?vue&type=template&id=551838d1"
import script from "./header-only.vue?vue&type=script&lang=js"
export * from "./header-only.vue?vue&type=script&lang=js"
import style0 from "./header-only.vue?vue&type=style&index=0&id=551838d1&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/opt/build/repo/components/app-header/app-header.vue').default,AppDesktopMenu: require('/opt/build/repo/components/app-desktop-menu/app-desktop-menu.vue').default,AppToastWrapper: require('/opt/build/repo/components/app-toast-wrapper/app-toast-wrapper.vue').default,AppNotificationsWrapper: require('/opt/build/repo/components/notifications/app-notifications-wrapper.vue').default,AppOverlay: require('/opt/build/repo/components/app-overlay/app-overlay.vue').default})
