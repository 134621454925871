import { ImageCacheService } from '@loavies/utils'

import { ClientStorageService } from '~/lib/services/client-storage-service'
import { ClientCookieService } from '~/lib/services/client-cookie-service'
import { MicroserviceApiService } from '~/lib/services/api/microservice-api-service'
import { ShipmentHandlerService } from '~/lib/services/shipment/shipment-handler-service'
import { UserHandlerService } from '~/lib/services/user/user-handler-service'
import { AuthenticationService } from '~/lib/services/api/authentication/authentication-service'
import { NodeApiService } from '~/lib/services/api/node-api-service'
import { ListingHandlerService } from '~/lib/services/listing/listing-handler-service'
import { FileApiService } from '~/lib/services/api/files/file-api-service'
import { ChatApiService } from '~/lib/services/api/chat/chat-api-service'
import { TransactionHelperService } from '~/lib/services/transaction/transaction-helper-service'
import { OrderHandlerService } from '~/lib/services/order/order-handler-service'
import { ReviewsHelperService } from '~/lib/services/reviews/reviews-helper-service'
import { UserHelperService } from '~/lib/services/user/user-helper-service'
import { EventsHelperService } from '~/lib/services/events/events-helper-service'
import { EventsHandlerService } from '~/lib/services/events/events-handler-service'
import { WardrobeHandlerService } from '~/lib/services/wardrobe/wardrobe-handler-service'
import { MicroserviceEventService } from '~/lib/services/microservice-event/microservice-event-service'
import { PostalServicePointService } from '~/lib/services/postal-service-point/postal-service-point-service'
import { CustomerCareApiService } from '~/lib/services/api/customer-care/customer-care-api-service'
import { PaymentHandlerService } from '~/lib/services/payment/payment-handler-service'
import { PaymentHelperService } from '~/lib/services/payment/payment-helper-service'
import { WalletHandlerService } from '~/lib/services/wallet/wallet-handler-service'
import { WalletHelperService } from '~/lib/services/wallet/wallet-helper-service'
import { NavigationHelperService } from '~/lib/services/navigation/navigation-helper-service'
import { NewsletterHelperService } from '~/lib/services/newsletter/newsletter-helper-service'
import { LocalizationService } from '~/lib/services/localization/localization-service'
import { ElasticUpdateService } from '~/lib/services/api/elastic/elastic-update-service'

export default async (context, inject) => {
  /**
   * @typedef ClientNuxtContext
   * @type {Object}
   * @property {Object} $bloomreach
   * @property {ImageCacheService} $imageCacheService
   * @property {ClientStorageService} $clientStorageService
   * @property {ClientCookieService} $clientCookieService
   * @property {MicroserviceApiService} $microserviceApiService
   * @property {ShipmentHandlerService} $shipmentHandlerService
   * @property {UserHandlerService} $userHandlerService
   * @property {AuthenticationService} $authenticationService
   * @property {NodeApiService} $nodeApiService
   * @property {ListingHandlerService} $listingHandlerService
   * @property {FileApiService} $fileApiService
   * @property {ChatApiService} $chatApiService
   * @property {TransactionHelperService} $transactionHelperService
   * @property {OrderHandlerService} $orderHandlerService
   * @property {ReviewsHelperService} $reviewsHelperService
   * @property {UserHelperService} $userHelperService
   * @property {EventsHelperService} $eventsHelperService
   * @property {EventsHandlerService} $eventsHandlerService
   * @property {WardrobeHandlerService} $wardrobeHandlerService
   * @property {MicroserviceEventService} $microserviceEventService
   * @property {PostalServicePointService} $postalServicePointService
   * @property {CustomerCareApiService} $customerCareApiService
   * @property {PaymentHandlerService} $paymentHandlerService
   * @property {PaymentHelperService} $paymentHelperService
   * @property {WalletHandlerService} $walletHandlerService
   * @property {WalletHelperService} $walletHelperService
   * @property {NavigationHelperService} $navigationHelperService
   * @property {NewsletterHelperService} $newsletterHelperService
   * @property {LocalizationService} $localizationService
   * @property {ElasticUpdateService} $elasticUpdateService
   */

  /**
   * @typedef {ServerNuxtContext, MarketplaceNuxtContext, ClientNuxtContext} NuxtContext
   */

  const services = {}

  services.imageCacheService = new ImageCacheService()
  services.nodeApiService = new NodeApiService(context)
  services.elasticUpdateService = new ElasticUpdateService(context)
  services.fileApiService = new FileApiService(context)
  services.transactionHelperService = new TransactionHelperService(context)
  services.reviewsHelperService = new ReviewsHelperService()
  services.userHelperService = new UserHelperService(context)
  services.chatApiService = new ChatApiService(context)
  services.eventsHelperService = new EventsHelperService()
  services.eventsHandlerService = new EventsHandlerService(context)
  services.clientStorageService = new ClientStorageService()
  services.clientCookieService = new ClientCookieService()
  services.microserviceApiService = new MicroserviceApiService(context)
  services.shipmentHandlerService = new ShipmentHandlerService(context)
  services.authenticationService = new AuthenticationService(context)
  services.userHandlerService = new UserHandlerService(context)
  services.wardrobeHandlerService = new WardrobeHandlerService(context)
  services.listingHandlerService = new ListingHandlerService(context)
  services.postalServicePointService = new PostalServicePointService(context)
  services.customerCareApiService = new CustomerCareApiService(context)
  services.paymentHandlerService = new PaymentHandlerService(context)
  services.paymentHelperService = new PaymentHelperService(context)
  services.orderHandlerService = new OrderHandlerService(context)
  services.walletHandlerService = new WalletHandlerService(context)
  services.walletHelperService = new WalletHelperService(context)
  services.navigationHelperService = new NavigationHelperService(context)
  services.microserviceEventService = new MicroserviceEventService(context)
  services.newsletterHelperService = new NewsletterHelperService(context)
  services.localizationService = new LocalizationService(context)

  const serviceKeys = Object.keys(services)

  // Inject services into the Vue context
  serviceKeys.forEach(key => {
    inject(key, services[key])
  })

  // Run init in each service
  for (let i = 0; i < serviceKeys.length; i++) {
    const key = serviceKeys[i]
    await services[key].init?.()
  }
}
