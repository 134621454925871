export default {

  /**
   * @param state
   * @param {string} query
   * @return {void}
   */
  SET_QUERY(state, query) {
    state.query = query
  },

  /**
   * @param state
   * @param {loavies.models.listing.ListingModel[]} listings
   * @return {void}
   */
  SET_LISTINGS(state, listings) {
    state.listings = listings
  },

  /**
   * @param state
   * @param {loavies.models.api.ApiTotalsModel} totals
   * @return {void}
   */
  SET_TOTALS(state, totals) {
    state.totals = totals
  },

  /**
   * @param state
   * @return {void}
   */
  CLEAR_LISTINGS(state) {
    state.listings = []
  },

  /**
   * @param state
   * @return {void}
   */
  CLEAR_TOTALS(state) {
    state.totals = {}
  },

}
