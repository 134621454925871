/**
 * @typedef {LocalizationService}
 * @alias this.$localizationService
 */
export class LocalizationService {
  // https://www.netlify.com/docs/redirects/#geoip-and-language-based-redirects
  #netlifyLocalizationCookieName = 'nf_country'

  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
    this.i18n = context.i18n
    this.localePath = context.localePath
  }

  init() {
    this.$clientCookieService = this.context.$clientCookieService
    this.$staticDataService = this.context.$staticDataService
  }

  /**
   * @param {string} locale
   * @param {Object} route
   */
  async switchLocale({ currentRoute, locale }) {
    this.$clientCookieService.set(this.#netlifyLocalizationCookieName, locale)

    window.location = await this.#getLocation({ currentRoute, locale })
  }

  /**
   * @param {Object} currentRoute
   * @param {string} locale
   * @returns {Promise<string>}
   */
  async #getLocation({ currentRoute, locale } = {}) {
    const currentLocale = this.i18n.locale
    const currentRouteName = currentRoute.name.split('___')[0]
    const currentGlobalNavigation = await this.$staticDataService.getGlobalNavigation(currentLocale)
    const newGlobalNavigation = await this.$staticDataService.getGlobalNavigation(locale)

    let categorySlug
    let categoryIndex
    let newGlobalNavigationItem
    let subcategoryIndex
    let subCategorySlug

    if (currentRoute.params.category) {
      categoryIndex = currentGlobalNavigation.navigation.findIndex(item => item.slug === currentRoute.params.category)
      newGlobalNavigationItem = newGlobalNavigation.navigation[categoryIndex]
      categorySlug = newGlobalNavigationItem.slug
    }

    if (currentRoute.params.subcategory && categoryIndex) {
      subcategoryIndex = currentGlobalNavigation.navigation[categoryIndex].subcategories.findIndex(item => item.slug === currentRoute.params.subcategory)
      subCategorySlug = newGlobalNavigationItem.subcategories[subcategoryIndex].slug
    }

    return this.localePath({
      name: currentRouteName,
      params: {
        ...currentRoute.params,
        ...( categorySlug && { category: categorySlug }),
        ...( subCategorySlug && { subcategory: subCategorySlug }),
      },
      query: {
        ...currentRoute.query,
      },
    }, locale)
  }
}
