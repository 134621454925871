import { render, staticRenderFns } from "./error.vue?vue&type=template&id=70ba7f9d"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"
import style0 from "./error.vue?vue&type=style&index=0&id=70ba7f9d&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/opt/build/repo/components/app-header/app-header.vue').default,ErrorState: require('/opt/build/repo/components/error-state/error-state.vue').default})
