
  import { TalkJsIncomingMessageModel, ROLE } from '@loavies/c2c-models'
  import { mapGetters } from 'vuex'

  import { CHAT_DETAIL } from '~/lib/constants/routes'
  import { CHAT_MESSAGE_TYPE } from '~/lib/constants/chat-message-types'
  import { PLATFORM_TITLE } from '~/lib/constants/default'

  export default {
    props: {
      chatMessage: {
        type: TalkJsIncomingMessageModel,
        required: true,
      },
    },
    emits: [
      'close',
    ],
    computed: {
      ...mapGetters({
        getTransactionById: 'transactions/getTransactionById',
      }),
      transaction() {
        return this.getTransactionById(this.chatMessage.transactionId)
      },
      oppositeUser() {
        return this.transaction?.userRole === ROLE.CUSTOMER ? this.transaction?.provider : this.transaction?.customer
      },
      formattedTitle() {
        return this.chatMessage.senderId ? this.oppositeUser?.displayName : PLATFORM_TITLE
      },
      formattedText() {
        return this.chatMessage.type === CHAT_MESSAGE_TYPE.MEDIA ? this.$t('chat_received_media') : this.chatMessage.text
      },
      link() {
        return this.localePath(`${CHAT_DETAIL}/${this.transaction?.id}`)
      },
      imageUrl() {
        return this.chatMessage.senderId ? this.oppositeUser?.image?.variants.w200.url : undefined
      },
    },
  }
