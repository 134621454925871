import { TalkJsChatModel, ChatMessageModel } from '@loavies/c2c-models'

/**
 * @param {Array} talkJsChats
 * @return {loavies.models.chat.TalkJsChatModel[]}
 */
export function mapTalkJsChats(talkJsChats) {
  return talkJsChats.map(talkJsChat => mapTalkJsChat(talkJsChat))
}

/**
 * @param {Object} talkJsChat
 * @return {loavies.models.chat.TalkJsChatModel}
 */
export function mapTalkJsChat(talkJsChat) {
  return new TalkJsChatModel({
    id: talkJsChat.id,
    subject: talkJsChat.subject,
    topicId: talkJsChat.topicId,
    photoUrl: talkJsChat.photoUrl,
    welcomeMessages: talkJsChat.welcomeMessages,
    custom: talkJsChat.custom,
    lastMessage: talkJsChat.lastMessage ? mapChatMessage(talkJsChat.lastMessage) : null,
    participants: talkJsChat.participants,
    createdAt: talkJsChat.createdAt,
  })
}

/**
 * @param {Object} message
 * @return {loavies.models.chat.ChatMessageModel}
 */
export function mapChatMessage(message) {
  return new ChatMessageModel({
    id: message.id,
    attachment: message.attachment,
    createdAt: message.createdAt,
    custom: message.custom,
    origin: message.origin,
    readBy: message.readBy,
    text: message.text,
    type: message.type,
    senderId: message.senderId,
  })
}
