export default context => {
  (async () => {
    try {
      const isAuthenticated = await context.store.getters['user/isAuthenticated']

      if (!isAuthenticated) return

      const userHasTransactions = context.store.getters['transactions/getAllTransactions'].length > 0

      // If user has no transactions, it has no chats, so a new chat session + user is not needed yet.
      if (!userHasTransactions) return

      await context.$chatApiService.createNewSession(context.store.state.user.user)
      await context.store.dispatch('chats/setChats', {}, { root: true })

      context.$chatApiService.setOnMessage(message => context.store.dispatch('chats/setChats', { singleNewMessage: message }, { root: true }))
    } catch (error) {
      console.warn(error)
    }
  })()
}
