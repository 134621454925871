
  export default {
    props: {
      balance: {
        type: Number,
        required: true,
      },
    },
    computed: {
      formattedBalance() {
        return this.$currencyService.formatCentsToLocalePrice(this.balance)
      },
    },
  }
