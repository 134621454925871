
  import AppNotification from '@loavies/component-library/dist/app-notification'

  import { NOTIFICATION_ANIMATION_TYPES } from '~/lib/constants/events'

  export default {
    components: {
      AppNotification,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: '',
      },
      link: {
        type: String,
        default: null,
      },
      imageUrl: {
        type: String,
        default: null,
      },
    },
    emits: [
      'close',
    ],
    data() {
      return {
        touchPositionStart: null,
      }
    },
    methods: {
      handleClick() {
        if (this.link) {
          this.$emit('close', NOTIFICATION_ANIMATION_TYPES.FADE)
          return this.$router.push(this.link)
        }
      },
      onTouchStart(event) {
        this.touchPositionStart = event.touches[0].clientX
      },
      onTouchMove(event) {
        if (!this.$refs.notification) {
          return
        }

        event.preventDefault() // Prevent page scrolling

        const touchPosition = event.touches[0].clientX
        const delta = touchPosition - this.touchPositionStart

        this.$refs.notification.$el.style.transform = `translateX(${delta}px)`
      },
      onTouchEnd(event) {
        if (!this.$refs.notification) {
          return
        }

        const swipeThresholdToClose = 48 // px
        const touchPositionEnd = event.changedTouches[0].clientX
        const delta = touchPositionEnd - this.touchPositionStart

        this.$refs.notification.$el.style.transform = ''

        if (touchPositionEnd > (this.touchPositionStart + swipeThresholdToClose)) {
          this.$refs.notification.$el.style.right = `${-1 * delta}px`

          this.handleClose(NOTIFICATION_ANIMATION_TYPES.RIGHT)
          this.touchPositionStart = null
        } else if (touchPositionEnd < (this.touchPositionStart - swipeThresholdToClose)) {
          this.$refs.notification.$el.style.left = `${delta}px`

          this.handleClose(NOTIFICATION_ANIMATION_TYPES.LEFT)
          this.touchPositionStart = null
        }
      },
      handleClose(direction = NOTIFICATION_ANIMATION_TYPES.RIGHT) {
        this.$emit('close', direction)
      },
    },
  }
