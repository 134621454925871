import { render, staticRenderFns } from "./app-notifications-wrapper.vue?vue&type=template&id=477b1e3a"
import script from "./app-notifications-wrapper.vue?vue&type=script&lang=js"
export * from "./app-notifications-wrapper.vue?vue&type=script&lang=js"
import style0 from "./app-notifications-wrapper.vue?vue&type=style&index=0&id=477b1e3a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppNotificationWrapper: require('/opt/build/repo/components/notifications/app-notification-wrapper.vue').default,AppTransactionNotification: require('/opt/build/repo/components/notifications/app-transaction-notification.vue').default,AppChatNotification: require('/opt/build/repo/components/notifications/app-chat-notification.vue').default})
