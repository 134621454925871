import TalkJs from 'talkjs'
import debounce from 'lodash.debounce'

import { mapTalkJsChats } from './chat-api-mappers'

/**
 * @typedef {ChatApiService}
 * @alias this.$chatApiService
 */
export class ChatApiService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.i18n = context.i18n
    this.chatAppId = context.$config.chatAppId

    this.session = null
    this.conversation = null
    this.sessionUser = null
  }

  init() {
    this.$nodeApiService = this.context.$nodeApiService
    this.$userHelperService = this.context.$userHelperService

    this.nodeApiInstance = this.$nodeApiService.instance
  }

  /**
   * @return {Promise<loavies.models.chat.TalkJsChatModel[]>} Array with chats the current user is participating in
   */
  async getMyTalkJsChats() {
    const config = this.$nodeApiService.getAuthorizationConfig()

    return this.nodeApiInstance
      .get('/chat/mine', config)
      .then(response => mapTalkJsChats(response.data))
  }

  /**
   * @param {loavies.models.user.UserModel} user
   * @param {string} conversationId
   * @param {Object} [options] set chatbox options
   * @return {TalkJs.Chatbox} a Talk chatbox
   */
  async getChatBox(user, conversationId, options = { showChatHeader: false }) {
    try {
      await this.createSession(user)
      await this.createConversation(conversationId)
      this.setParticipant(this.sessionUser)

      const chatBox = this.session.createChatbox(options)

      chatBox.select(this.conversation)

      return chatBox
    } catch (error) {
      return Promise.reject(Error(error))
    }
  }

  /**
   * @param {loavies.models.user.UserModel} user
   * @return {Promise<TalkJs.Session>} a Talk session. Can contain multiple chat boxes
   */
  async createNewSession(user) {
    if (this.session) {
      this.session.destroy()
    }

    return this.createSession(user)
  }

  /**
   * @param {loavies.models.user.UserModel} user
   * @return {Promise<TalkJs.Session>} a Talk session. Can contain multiple chat boxes
   */
  async createSession(user) {
    this.sessionUser = this.getTalkJsUser(user)

    if (!this.sessionUser) {
      return Promise.reject(Error('No valid TalkJsUser present'))
    }

    try {
      const signature = await this.getSignature()
      this.session = this.getTalkJsSession(this.sessionUser, signature)
    } catch (error) {
      return Promise.reject(Error('Session could not be created'))
    }

    return this.session
  }

  /**
   * @param {string} transactionId is used for chat id
   * @return {Promise<TalkJs.Conversation>} a Talk conversation. Can contain multiple chat boxes
   */
  async createConversation(transactionId) {
    this.conversation = await this.session.getOrCreateConversation(transactionId)
  }

  /**
   * @param {TalkJs.User} talkJsUser
   * @return {void}
   */
  setParticipant(talkJsUser) {
    this.conversation.setParticipant(talkJsUser)
  }

  /**
   * @param {loavies.models.user.UserModel} user
   * @return {TalkJs.User}
   */
  getTalkJsUser(user) {
    return new TalkJs.User({
      id: user.id,
      name: this.$userHelperService.getFormattedDisplayName(user),
      photoUrl: user.image?.variants?.w200?.url,
      role: 'default',
    })
  }

  /**
   * @param {TalkJs.User} talkJsUser
   * @param {string} signature
   * @return {TalkJs.Session}
   */
  getTalkJsSession(talkJsUser, signature) {
    return new TalkJs.Session({
      appId: this.chatAppId,
      me: talkJsUser,
      signature: signature,
    })
  }

  /**
   * @return {Promise<string>}
   */
  getSignature() {
    const config = this.$nodeApiService.getAuthorizationConfig()

    return this.nodeApiInstance
      .get('/chat/signature', config)
      .then(response => response.data)
      .catch(error => {
        console.error(error)

        return Promise.reject(Error(error))
      })
  }

  /**
   * @param {boolean} isEnabled
   * @return {Promise<void>}
   */
  async setBrowserNotifications(isEnabled) {
    if (!this.session) return Promise.reject(Error('Missing session'))

    return this.session
      .setDesktopNotificationEnabled(isEnabled, { alertOnFailure: false })
      .catch(error => this.handleBrowserNotificationError(error))
  }

  /**
   * @param {TalkJs.TalkError} error
   * @return {Promise<Error>}
   */
  handleBrowserNotificationError(error) {
    // TalkJS returns an error code 0 or 1, for more info
    // see talk.types.d.ts in the talkjs node module.
    const TALKJS_BROWSER_NOTIFICATION_ERROR_TOKENS = {
      0: 'browser_notification_error_permission_denied',
      1: 'browser_notification_error_not_supported',
      FALLBACK: 'browser_notification_error',
    }
    const errorMessageToken = TALKJS_BROWSER_NOTIFICATION_ERROR_TOKENS[error.code] ?? TALKJS_BROWSER_NOTIFICATION_ERROR_TOKENS.FALLBACK

    return Promise.reject(Error(this.i18n.t(errorMessageToken)))
  }

  /**
   * @param {Function} onMessage
   * @return {void}
   */
  setOnMessage(onMessage) {
    if (!this.session) return

    this.session.onMessage(debounce(message => onMessage(message), 1000))
  }

  /**
   * @param {string} id TransactionId
   * @return {void}
   */
  async createChat(id) {
    const config = this.$nodeApiService.getAuthorizationConfig()

    return this.nodeApiInstance
      .post('/chat/create', {
          id,
        },
        config
      )
  }
}
