/**
 * @typedef {WalletHelperService}
 * @alias this.$walletHelperService
 */
export class WalletHelperService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.i18n = context.i18n
  }

  /**
   * @param {string} transferType
   * @returns {string}
   */
  getWalletTransferTitleTranslation(transferType) {
    return this.i18n.t(`wallet_transfer_${transferType.toLowerCase()}_title`)
  }
}
