import { mapPostalServicePoints } from '~/lib/services/api/microservice-api-mappers'

/**
 * @typedef {PostalServicePointService}
 * @alias this.$postalServicePointService
 */
export class PostalServicePointService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
  }

  init() {
    this.$microServiceApiService = this.context.$microserviceApiService

    this.microServiceApiInstance = this.$microServiceApiService.instance
  }

  /**
   * @param {string} countryCode
   * @param {string} postalCode
   * @return {Promise<loavies.models.postal.PostalServicePointModel[]>}
   */
  getServicePoints({
    countryCode = 'nl',
    postalCode,
  } = {}) {
    return this.microServiceApiInstance
      .get(`/transport/service-points/${countryCode}/${postalCode}`)
      .then(response => mapPostalServicePoints(response.data))
  }
}
