import Router from 'vue-router'

import { locales } from '~/static/data/cms/app.json'

export const LISTINGS_PAGE_NAME = 'listings'
export const CONTENT_PAGE_NAME = 'content'

const ListingsLayout = () => import('~/pages/listings').then(module => module.default)
const ListingsPage = () => import('~/pages/listings/_slug').then(module => module.default)
const AccountListingDetailPage = () => import('~/pages/account/listing/_id/index').then(module => module.default)
const AccountListingEditPage = () => import('~/pages/account/listing/_id/edit').then(module => module.default)
const AccountChatsPage = () => import('~/pages/account/chats/index').then(module => module.default)
const ContentPage = () => import('~/pages/content/index').then(module => module.default)

export async function createRouter(ssrContext, createDefaultRouter, routerOptions) {
  const routes = [
    // Listing routes
    await getListingsRoutes(),
    ...await Promise.all(locales.map(locale => getListingsRoutes(locale.code))),

    // Account listing details routes
    getAccountListingDetailsRoute(),
    ...locales.map(locale => getAccountListingDetailsRoute(locale.code)),

    // Account listing edit routes
    getAccountListingEditRoute(),
    ...locales.map(locale => getAccountListingEditRoute(locale.code)),

    // Content page routes
    ...await getContentPageRoutes(),

    // Filter out all custom routes
    ...routerOptions.routes.filter(route =>
      route.name !== LISTINGS_PAGE_NAME
      && route.name !== CONTENT_PAGE_NAME
      && !route.name?.startsWith(`${LISTINGS_PAGE_NAME}___`)
      && !route.name?.startsWith(`${CONTENT_PAGE_NAME}___`)
    ),
  ]

  // Account chat detail page gets added here
  // This page gets added as a child route of /account
  addChatDetailPage(routes)
  locales.forEach(locale => addChatDetailPage(routes, locale.code))

  // Earlier added /account child routes get removed here
  // - Account listing details routes
  // - Account listing edit routes
  removeAccountChildRoutes(routes)
  locales.forEach(locale => removeAccountChildRoutes(routes, locale.code))

  const router = new Router({
    ...routerOptions,
    routes,
  })

  router.beforeEach((to, from, next) => {
    // Used for Bloomreach page_visit tracking. By default, Nuxt doesn't
    // update document.referrer after navigation.
    if (process.client && from.name) {
      Object.defineProperty(
        document,
        'referrer',
        {
          configurable: true,
          get: () => {
            return window.location.origin + from.path
          },
        }
      )
    }

    next()
  })

  return router
}

/**
 * @param {string} [localeCode]
 * @return {Object}
 */
async function getListingsRoutes(localeCode) {
  const { navigation } = await import(`~/static/data/cms/${localeCode ? localeCode : 'en'}/global-navigation.json`).then(module => module.default)
  const mappedListingCategories = navigation.map(item => item.slug)

  const formattedLocaleCode = localeCode ? `/${localeCode}` : ''

  return {
    path: `${formattedLocaleCode}/:category(${mappedListingCategories.join('|')})`,
    component: ListingsLayout,
    children: [
      {
        name: formatRouteName('listings-category', localeCode),
        path: '',
        component: ListingsPage,
      },
      {
        name: formatRouteName('listings-subcategory', localeCode),
        path: ':subcategory',
        component: ListingsPage,
      },
    ],
  }
}

/**
 * @returns {Promise<Object[]>}
 */
async function getContentPageRoutes() {
  const routes = await import('~/static/data/cms/pages/index.json').then(module => module.default)

  return routes.map(route => {
    const routeParts = route.split('/')
    const path = route.replace('/content', '')
    const slug = routeParts[3]
    const name = formatRouteName(slug, routeParts[1])

    return {
      name,
      path,
      component: ContentPage,
      props: {
        slug,
        isContentPage: true,
      },
    }
  })
}

/**
 * @param {string} [localeCode]
 * @return {Object}
 */
function getAccountListingDetailsRoute(localeCode) {
  const formattedLocaleCode = localeCode ? `/${localeCode}` : ''

  return {
    name: formatRouteName('account-listing-id', localeCode),
    path: `${formattedLocaleCode}/account/listing/:id`,
    component: AccountListingDetailPage,
  }
}

/**
 * @param {string} [localeCode]
 * @return {Object}
 */
function getAccountListingEditRoute(localeCode) {
  const formattedLocaleCode = localeCode ? `/${localeCode}` : ''

  return {
    name: formatRouteName('account-listing-id-edit', localeCode),
    path: `${formattedLocaleCode}/account/listing/:id/edit`,
    component: AccountListingEditPage,
  }
}

/**
 * @param {Array} routes
 * @param {string} [localeCode]
 * @return {void}
 */
function removeAccountChildRoutes(routes, localeCode) {
  const path = '/account'
  const formattedPath = localeCode ? `/${localeCode}${path}` : path
  const routeToChange = routes
    .find(route => route.path === formattedPath)

  routeToChange.children =
    routeToChange
      .children
      .filter(childRoute =>
        childRoute.name !== formatRouteName('account-listing-id', localeCode)
        && childRoute.name !== formatRouteName('account-listing-id-edit', localeCode)
      )
}

/**
 * @param {Array} routes
 * @param {string} [localeCode]
 * @return {void}
 */
function addChatDetailPage(routes, localeCode) {
  const accountPath = '/account'
  const formattedPath = localeCode ? `/${localeCode}${accountPath}` : accountPath
  const accountRoute = routes.find(route => route.path === formattedPath)

  accountRoute.children.push({
    path: 'chat/:id',
    name: formatRouteName('account-chat-id', localeCode),
    component: AccountChatsPage,
  })
}

/**
 * @param {string} routeName
 * @param {string} [localeCode]
 * @return {string}
 */
function formatRouteName(routeName, localeCode) {
  return localeCode ? `${routeName}___${localeCode}` : routeName
}
