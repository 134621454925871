import { CreateListingModel, PRODUCT_STATUS, PriceModel } from '@loavies/c2c-models'

/**
 * @typedef {ListingHandlerService}
 * @alias this.$listingHandlerService
 */
export class ListingHandlerService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }

  init() {
    this.$bloomreach = this.context.$bloomreach
    this.$marketplaceListingService = this.context.$marketplaceListingService
    this.$nodeApiService = this.context.$nodeApiService
    this.$wardrobeHandlerService = this.context.$wardrobeHandlerService
    this.$elasticUpdateService = this.context.$elasticUpdateService

    this.nodeApiInstance = this.$nodeApiService.instance
  }

  /**
   * @param {string} listingId
   * @returns {Promise}
   */
  increaseListingTimesWatched(listingId) {
    return this.nodeApiInstance.patch(`/listing/${listingId}/public-data/times-watched`)
  }

  /**
   * @param {string} listingId
   * @returns {Promise}
   */
  increaseListingTimesShared(listingId) {
    return this.nodeApiInstance.patch(`/listing/${listingId}/public-data/times-shared`)
  }

  /**
   * @param {string} listingId
   * @returns {Promise}
   */
  increaseListingWishlistCount(listingId) {
    return this.nodeApiInstance.patch(`/listing/${listingId}/public-data/wishlist-count/increase`)
  }

  /**
   * @param {string} listingId
   * @returns {Promise}
   */
  decreaseListingWishlistCount(listingId) {
    return this.nodeApiInstance.patch(`/listing/${listingId}/public-data/wishlist-count/decrease`)
  }

  /**
   * @param {string} id
   * @param {number} amount
   * @returns {Promise<loavies.models.listing.ListingModel>}
   */
  async updatePrice({ id, amount }) {
    const updatedListing = await this.$marketplaceListingService.updatePrice({ id, amount })

    await this.store.dispatch('user/setListing', updatedListing)
    await this.$elasticUpdateService.updateListingInElastic(id)

    return updatedListing
  }

  /**
   * @param {string} id
   * @param {string} condition
   * @returns {Promise<loavies.models.listing.ListingModel>}
   */
  async updateCondition({
    id,
    condition,
  }) {
    const updatedListing = await this.$marketplaceListingService.updateCondition({ id, condition })

    await this.store.dispatch('user/setListing', updatedListing)
    await this.$elasticUpdateService.updateListingInElastic(id)

    return updatedListing
  }

  /**
   * @param {string} id
   * @param {string} description
   * @returns {Promise<loavies.models.listing.ListingModel>}
   */
  async updateDescription({
    id,
    description,
  }) {
    if (!description) return Promise.reject()

    const updatedListing = await this.$marketplaceListingService.updateDescription({ id, description })

    await this.store.dispatch('user/setListing', updatedListing)
    await this.$elasticUpdateService.updateListingInElastic(id)

    return updatedListing
  }

  /**
   * @param {string} id
   * @param {string} [labelId]
   * @return {Promise<loavies.models.listing.ListingModel>} updated listing
   */
  async updateLabel({
    id,
    labelId,
  }) {
    const updatedListing = await this.$marketplaceListingService.updateLabel({
      id,
      labelId: labelId ? labelId : null,
    })

    await this.store.dispatch('user/setListing', updatedListing)
    await this.$elasticUpdateService.updateListingInElastic(id)

    return updatedListing
  }

  /**
   * @param {string} id
   * @param {loavies.models.image.ImageModel[]|string[]} images
   * @returns {Promise<loavies.models.listing.ListingModel>}
   */
  async updateImages({
    id,
    images,
  }) {
    const updatedListing = await this.$marketplaceListingService.updateImages({ id, images })

    await this.store.dispatch('user/setListing', updatedListing)
    await this.$elasticUpdateService.updateListingInElastic(id)

    return updatedListing
  }

  /**
   * @param {string} id
   * @param {loavies.models.base.PriceModel} originalPrice
   * @param {number} [discountPercentage]
   * @param {loavies.models.base.PriceModel} [newPrice]
   * @return {Promise<loavies.models.listing.ListingModel>} updated listing
   */
  async updateDiscount({
    id,
    originalPrice,
    discountPercentage,
    newPrice,
  }) {
    const updatedListing = await this.$marketplaceListingService.updateDiscount({
      id,
      originalPrice,
      discountPercentage,
      newPrice,
    })

    await this.store.dispatch('user/setListing', updatedListing)
    await this.$elasticUpdateService.updateListingInElastic(id)

    return updatedListing
  }

  /**
   * @param {string} id
   * @param shopIndex
   * @return {Promise<loavies.models.listing.ListingModel>}
   */
  async updateShopIndex({
    id,
    shopIndex,
  }) {
    const updatedListing = await this.$marketplaceListingService.updateShopIndex({ id, shopIndex })

    await this.store.dispatch('user/setListing', updatedListing)
    await this.$elasticUpdateService.updateListingInElastic(id)

    return updatedListing
  }

  /**
   * @param {string} id
   * @return {Promise<loavies.models.listing.ListingModel>}
   */
  async openListing(id) {
    const openedListing = await this.$marketplaceListingService.openListing(id)

    await this.store.dispatch('user/setListing', openedListing)
    await this.$elasticUpdateService.updateListingInElastic(id)

    this.$bloomreach.listingUpdate(openedListing)

    return openedListing
  }

  /**
   * @param {loavies.models.product.ProductModel} product
   * @return {Promise<loavies.models.listing.ListingModel>}
   */
  async createDraftListingFromProduct(product) {
    const createListing = new CreateListingModel({
      title: product.title,
      publicData: {
        productGroup: product.productGroup,
        size: product.size,
        color: product.color,
        magentoSku: product.sku,
      },
      privateData: {
        productId: product.id,
        productOriginalImages: product.images,
        productOriginalPrice: new PriceModel({
          amount: product.purchasePrice?.amount,
          currency: product.purchasePrice?.currency,
        }),
      },
    })

    const draftListing = await this.$marketplaceListingService.createDraftListing(createListing)

    await this.$wardrobeHandlerService.updateProductStatus(product.id, PRODUCT_STATUS.DRAFT)
    await this.store.dispatch('user/setListing', draftListing)

    this.$bloomreach.listingUpdate(draftListing)

    return draftListing
  }

  /**
   * @param {string} listingId
   * @return {Promise<loavies.models.listing.ListingModel>}
   */
  async publishDraftListing(listingId) {
    const listing = await this.$marketplaceListingService.publishDraftListing(listingId)

    await this.store.dispatch('user/setListing', listing)

    if (listing.privateData?.productId) {
      await this.$wardrobeHandlerService.updateProductStatus(listing.privateData.productId, PRODUCT_STATUS.LISTED)
    }

    await this.$elasticUpdateService.updateListingInElastic(listingId)

    this.$bloomreach.listingUpdate(listing)

    return listing
  }

  /**
   * @param {loavies.models.listing.ListingModel} listing
   * @return {Promise}
   */
  async discardDraftListing(listing) {
    try {
      await this.$marketplaceListingService.discardDraftListing(listing.id)
      await this.$wardrobeHandlerService.updateProductStatus(listing.privateData.productId, PRODUCT_STATUS.AVAILABLE)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * @param {loavies.models.listing.ListingModel} listing
   * @return {Promise<loavies.models.listing.ListingModel>}
   */
  async duplicateListing(listing) {
    const duplicatedListing = await this.$marketplaceListingService.createListingFromExistingListing(listing)

    await this.store.dispatch('user/setListing', duplicatedListing)
    await this.$elasticUpdateService.updateListingInElastic(duplicatedListing.id)

    return duplicatedListing
  }

  /**
   * @param {string} listingId
   * @return {Promise<loavies.models.listing.ListingModel>}
   */
  async closeListing(listingId) {
    const closedListing = await this.$marketplaceListingService.closeListing(listingId)

    await this.store.dispatch('user/setListing', closedListing)
    await this.$elasticUpdateService.deleteListingInElastic(listingId)

    if (closedListing.privateData?.productId) {
      await this.$wardrobeHandlerService.updateProductStatus(closedListing.privateData.productId, PRODUCT_STATUS.AVAILABLE)
    }

    this.$bloomreach.listingUpdate(closedListing)

    return closedListing
  }

  /**
   * @param {string} listingId
   * @return {Promise<boolean>}
   */
  async listingIsEditable(listingId) {
    const listing = await this.$marketplaceListingService.getOwnListingById(listingId)

    return listing?.stock?.quantity !== 0 ?? false
  }
}
