import { PAGE_TITLE_ADDITION, PLATFORM_TITLE } from '~/lib/constants/default'

/**
 * @typedef {PageHelperService}
 * @alias this.$pageHelperService
 */
export class PageHelperService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.config = context.$config
  }

  /**
   * @param {string} pageTitle
   * @returns {string}
   */
  getFormattedPageTitle(pageTitle) {
    return `${pageTitle}${PAGE_TITLE_ADDITION}`
  }

  /**
   * @param {Object} pageSeo
   * @param {Object} route
   * @returns {Object}
   */
  formatSeoForPage(pageSeo, route) {
    const baseUrl = this.config.baseURL
    const siteName = 'reloaved.com'
    const defaultTitle = PLATFORM_TITLE
    const defaultDescription = 'RELOAVED'
    const defaultShareImage = {
      path: '/img/reloaved-logo.svg',
      width: 300,
      height: 33,
    }

    const pageTitle = pageSeo?.title || defaultTitle
    const metaTitle = pageSeo?.metaTitle || pageTitle
    const shareImage = pageSeo?.image || Object.assign(defaultShareImage, {
      url: `${baseUrl}${defaultShareImage.path}`,
    })
    const description = pageSeo?.description || defaultDescription

    return {
      title: pageTitle,
      meta: [
        { hid: 'description', name: 'description', content: description },
        /* Facebook */
        { hid: 'og:url', property: 'og:url', content: `${baseUrl}${route.path}` },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:title', property: 'og:title', content: metaTitle },
        { hid: 'og:description', property: 'og:description', content: description },
        { hid: 'og:image', property: 'og:image', content: shareImage.url },
        { hid: 'og:image:width', property: 'og:image:width', content: shareImage.width },
        { hid: 'og:image:height', property: 'og:image:height', content: shareImage.height },
        { hid: 'og:site_name', property: 'og:site_name', content: siteName },
      ],
    }
  }
}
