import { LISTING_STATES } from '@loavies/c2c-models'
import { sortListingsByShopIndex } from '@loavies/c2c-marketplace-lib'

export default {
  /**
   * @param state
   * @returns {boolean}
   */
  isAuthenticated(state) {
    return Boolean(state.user)
  },

  /**
   * @param state
   * @returns {number}
   */
  amountOfFinishedOnboardingSteps(state) {
    if (!state.user) {
      return 0
    }

    return [
      state.user.publicData?.storeName,
      state.user.bio,
      state.user.displayName,
      state.user.image,
      state.user.publicData?.banner,
    ]
      .filter(item => item !== null && item !== undefined)
      .length
  },

  /**
   * @param state
   * @return {loavies.models.listing.ListingModel[]}
   */
  draftListings(state) {
    return state.listings.filter(listing => listing.state === LISTING_STATES.DRAFT)
  },

  /**
   * @param state
   * @return {loavies.models.listing.ListingModel[]}
   */
  publishedListings(state) {
    return sortListingsByShopIndex(state.listings.filter(listing => listing.state === LISTING_STATES.PUBLISHED))
  },

  /**
   * @param state
   * @return {loavies.models.listing.ListingModel[]}
   */
  closedListings(state) {
    return state.listings.filter(listing => listing.state === LISTING_STATES.CLOSED)
  },

  /**
   * @param state
   * @return {function(string): loavies.models.listing.ListingModel}
   */
  getOwnListingById: state => listingId => {
    return state.listings.find(listing => listing.id === listingId)
  },

  /**
   * @param state
   * @return {function(*): boolean}
   */
  isFollowingUser: state => id => {
    return state.user?.publicData.followingUsers.some(user => user === id) ?? false
  },

  /**
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   * @return {number}
   */
  getActiveListingsCount(state, getters, rootState, rootGetters) {
    const activeListings = getters.publishedListings.reduce((accumulator, listing) => {
      const hasActiveTransaction = rootGetters['transactions/getActiveSalesTransactions'].some(transaction => transaction.listing?.id === listing.id)

      if (hasActiveTransaction) {
        accumulator.push(listing)

        return accumulator
      }

      const hasTransaction = rootState.transactions.saleTransactions.some(transaction => transaction.listing?.id === listing.id)

      if (!hasTransaction) {
        accumulator.push(listing)
      }

      return accumulator
    }, [])

    return activeListings.length
  },
}
