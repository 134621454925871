import axios from 'axios'

/**
 * @typedef StaticDataService
 * @alias this.$staticDataService
 */
export class StaticDataService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.i18n = context.i18n

    this.cmsApp = null
    this.version = null
    this.generalConfiguration = null
    this.globalFooter = null
    this.globalNavigation = null
    this.overlaysConfiguration = null
    this.listingLabels = null
    this.createListing = null
    this.translatedProductGroups = null
    this.translatedProductColors = null
    this.generalOnboardingWizard = null
    this.globalSocials = null
  }

  async init() {
    this.cmsApp =  await this.getCmsApp()
    this.version = this.cmsApp.version

    const [
      generalConfiguration,
      globalFooter,
      globalNavigation,
      overlaysConfiguration,
      listingLabels,
      createListing,
      translatedProductGroups,
      translatedProductColors,
      generalOnboardingWizard,
      globalSocials,
    ] = await Promise.all([
      this.getGeneralConfiguration(),
      this.getGlobalFooter(),
      this.getGlobalNavigation(),
      this.getOverlaysConfiguration(),
      this.getListingLabels(),
      this.getCreateListing(),
      this.getTranslatedProductGroups(),
      this.getTranslatedProductColors(),
      this.getGeneralOnboardingWizard(),
      this.getGlobalSocials(),
    ])

    this.generalConfiguration = generalConfiguration
    this.globalFooter = globalFooter
    this.globalNavigation = globalNavigation
    this.overlaysConfiguration = overlaysConfiguration
    this.listingLabels = listingLabels
    this.createListing = createListing
    this.translatedProductGroups = translatedProductGroups
    this.translatedProductColors = translatedProductColors
    this.generalOnboardingWizard = generalOnboardingWizard
    this.globalSocials = globalSocials
  }

  /**
   * @returns {Promise<Object>}
   */
  async getCmsApp() {
    return process.server
      ? JSON.parse(require('fs').readFileSync('static/data/cms/app.json', 'utf8'))
      : await axios.get('/data/cms/app.json').then(response => response.data)
  }

  /**
   * @returns {Promise<Object>}
   */
  async getGeneralConfiguration() {
    return process.server
      ? JSON.parse(require('fs').readFileSync('static/data/cms/general-configuration.json', 'utf8'))
      : await axios.get(`/data/cms/general-configuration.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @returns {Promise<Object>}
   */
  async getGlobalFooter() {
    return process.server
      ? JSON.parse(require('fs').readFileSync(`static/data/cms/${this.i18n.locale}/global-footer.json`, 'utf8'))
      : await axios.get(`/data/cms/${this.i18n.locale}/global-footer.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @params {string} [locale]
   * @returns {Promise<Object>}
   */
  async getGlobalNavigation(locale) {
    return process.server
      ? JSON.parse(require('fs').readFileSync(`static/data/cms/${locale ? locale : this.i18n.locale}/global-navigation.json`, 'utf8'))
      : await axios.get(`/data/cms/${locale ? locale : this.i18n.locale}/global-navigation.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @returns {Promise<Object>}
   */
  async getOverlaysConfiguration() {
    return process.server
      ? JSON.parse(require('fs').readFileSync(`static/data/cms/${this.i18n.locale}/overlays-configuration.json`, 'utf8'))
      : await axios.get(`/data/cms/${this.i18n.locale}/overlays-configuration.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @returns {Promise<Object>}
   */
  async getListingLabels() {
    return process.server
      ? JSON.parse(require('fs').readFileSync(`static/data/cms/${this.i18n.locale}/listing-labels.json`, 'utf8'))
      : await axios.get(`/data/cms/${this.i18n.locale}/listing-labels.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @returns {Promise<Object>}
   */
  async getCreateListing() {
    return process.server
      ? JSON.parse(require('fs').readFileSync(`static/data/cms/${this.i18n.locale}/create-listing.json`, 'utf8'))
      : await axios.get(`/data/cms/${this.i18n.locale}/create-listing.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @returns {Promise<Object>}
   */
  async getTranslatedProductGroups() {
    return process.server
      ? JSON.parse(require('fs').readFileSync('static/data/cms/translated-product-groups.json', 'utf8'))
      : await axios.get(`/data/cms/translated-product-groups.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @returns {Promise<Object>}
   */
  async getTranslatedProductColors() {
    return process.server
      ? JSON.parse(require('fs').readFileSync('static/data/cms/translated-product-colors.json', 'utf8'))
      : await axios.get(`/data/cms/translated-product-colors.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @returns {Promise<Object>}
   */
  async getGeneralOnboardingWizard() {
    return process.server
      ? JSON.parse(require('fs').readFileSync(`static/data/cms/${this.i18n.locale}/general-onboarding-wizard.json`, 'utf8'))
      : await axios.get(`/data/cms/${this.i18n.locale}/general-onboarding-wizard.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @returns {Promise<Object>}
   */
  async getGlobalSocials() {
    return process.server
      ? JSON.parse(require('fs').readFileSync(`static/data/cms/${this.i18n.locale}/global-socials.json`, 'utf8'))
      : await axios.get(`/data/cms/${this.i18n.locale}/global-socials.json?_=${this.version}`).then(response => response.data)
  }
}
