/**
 * @typedef {PaymentHelperService}
 * @alias this.$paymentHelperService
 */
export class PaymentHelperService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.config = context.$config
    this.i18n = context.i18n
    this.baseUrl = this.config.adyenImageBaseUrl
  }
  /**
   * @param {loavies.models.payment.PaymentMethodModel} paymentMethod
   * @return {string}
   */
  getPaymentMethodImage(paymentMethod) {
    return `${this.baseUrl}/${paymentMethod.type}.svg`
  }

  /**
   * @param {loavies.models.payment.PaymentMethodModel} paymentMethod
   * @return {string}
   */
  getPaymentMethodDescription(paymentMethod) {
    return this.i18n.t(`payment_method_description_${paymentMethod.type}`)
  }

  /**
   * @param {loavies.models.payment.PaymentMethodIssuerModel} paymentMethodIssuer
   * @return {string}
   */
  getPaymentMethodIssuerImage(paymentMethodIssuer) {
    return `${this.baseUrl}/${paymentMethodIssuer.type}/${paymentMethodIssuer.id}.svg`
  }
}
