import defaultState from './state'
import Vue from 'vue'

export default {
  /**
   * @param state
   */
  RESET_STATE(state) {
    Object.assign(state, defaultState())
  },

  /**
   * @param state
   * @param {Array<loavies.models.chat.ChatModel>} chats
   */
  SET_CHATS(state, chats) {
    state.chats = chats
  },

  /**
   * @param state
   * @param {Array<loavies.models.chat.TalkJsIncomingMessageModel[]>} chatMessages
   */
  SET_NOTIFIABLE_CHATS(state, chatMessages) {
    state.notifiableChatMessages = chatMessages
  },

  /**
   * @param state
   * @param {loavies.models.chat.TalkJsIncomingMessageModel} chatMessage
   */
  ADD_NOTIFIABLE_CHAT(state, chatMessage) {
    state.notifiableChatMessages.push(chatMessage)
  },

  /**
   * @param state
   * @param {string} id
   * @param {string} userId
   */
  SET_CHAT_MESSAGE_READ(state, { id, userId }) {
    const chat = state.chats.find(chat => chat.id === id)

    if (chat && userId) {
      chat.talkJsChat.lastMessage?.readBy?.push(userId)
    }
  },

  /**
   * @param state
   * @param {boolean} hasError
   */
  SET_HAS_ERROR(state, hasError) {
    state.hasError = hasError
  },

  /**
   * @param state
   * @param {loavies.models.chat.TalkJsIncomingMessageModel} talkJsIncomingMessage
   */
  UPDATE_STORE_CHAT(state, talkJsIncomingMessage) {
    const chat = state.chats.find((chat, index) => {
      if (chat.id === talkJsIncomingMessage.transactionId) {
        return {
          chat,
          index,
        }
      }
    })

    if (!chat) return

    chat.talkJsChat.lastMessage = talkJsIncomingMessage

    if (chat.index > -1) {
      Vue.set(state.chats, chat.index, chat)
    }
  },
}
