import { disableBodyScroll, clearAllBodyScrollLocks } from '~/lib/vendor/body-scroll-lock'

export const SHOW_LARGE_PRODUCT_CARDS_COOKIE_KEY = 'showLargeProductCards'

export default {
  /**
   * @param context
   * @param [payload]
   * @param {HTMLElement} payload.element
   * @param {object} payload.options - Options for body-scroll-lock
   * @see https://github.com/willmcpo/body-scroll-lock#options
   */
  disableScroll(context, payload) {
    disableBodyScroll(payload?.element, payload?.options)
  },

  enableScroll() {
    clearAllBodyScrollLocks()
  },

  /**
   * @param context
   * @param {boolean} isDesktop
   */
  setIsDesktop(context, isDesktop) {
    context.commit('SET_IS_DESKTOP', isDesktop)
  },

  /**
   * @param context
   * @param {boolean} isMobile
   */
  setIsMobile(context, isMobile) {
    context.commit('SET_IS_MOBILE', isMobile)
  },

  /**
   * @param context
   * @param {boolean} isHoverSupported
   */
  setIsHoverSupported(context, isHoverSupported) {
    context.commit('SET_IS_HOVER_SUPPORTED', isHoverSupported)
  },

  /**
   * @param context
   * @param {boolean} mediaQueryInitialized
   */
  setMediaQueryInitialized(context, mediaQueryInitialized) {
    context.commit('SET_MEDIA_QUERY_INITIALIZED', mediaQueryInitialized)
  },

  /**
   * @param context
   * @param {boolean} isIOS
   */
  setIsIOS(context, isIOS) {
    context.commit('SET_IS_IOS', isIOS)
  },

  /**
   * @param context
   * @param {boolean} showErrors
   */
  setShowErrors(context, showErrors) {
    context.commit('SET_SHOW_ERRORS', showErrors)
  },

  /**
   * @param context
   * @param {boolean} showLargeProductCards
   */
  setShowLargeProductCards(context, showLargeProductCards) {
    this.$clientCookieService.set(SHOW_LARGE_PRODUCT_CARDS_COOKIE_KEY, showLargeProductCards)
    context.commit('SET_SHOW_LARGE_PRODUCT_CARDS', showLargeProductCards)
  },
}
