import {
  CreateIdentityUserModel,
  CreateIdentityUserAddressModel,
  ORDER_VERIFICATION_RESULT_CODES,
} from '@loavies/c2c-models'

import { mapOrderVerification } from '~/lib/services/api/node-api-mappers'

export default {
  /**
   * @param context
   * @return {Promise<void>}
   */
  resetState(context) {
    context.commit('RESET_STATE')
  },

  /**
   * @param context
   * @param {loavies.models.payment.PaymentMethodModel|loavies.models.payment.PaymentMethodIssuerModel} selectedPaymentMethod
   * @return {Promise<void>}
   */
  setSelectedPaymentMethod(context, selectedPaymentMethod) {
    context.commit('SET_SELECTED_PAYMENT_METHOD', selectedPaymentMethod)
  },

  /**
   * @param context
   * @param {Object} paymentMethodData
   * @return {Promise<void>}
   */
  setPaymentMethodData(context, paymentMethodData) {
    context.commit('SET_PAYMENT_METHOD_DATA', paymentMethodData)
  },

  /**
   * @param context
   * @param {loavies.models.user.UserAddressModel} address
   * @return {Promise<void>}
   */
  setAddress(context, address) {
    context.commit('SET_ADDRESS', address)
  },

  /**
   * @param context
   * @param {string} newUserEmail
   * @return {Promise<void>}
   */
  setNewUserEmail(context, newUserEmail) {
    context.commit('SET_NEW_USER_EMAIL', newUserEmail)
  },

  /**
   * @param context
   * @param {boolean} newUserEmailExists
   * @return {Promise<void>}
   */
  setNewUserEmailExists(context, newUserEmailExists) {
    context.commit('SET_NEW_USER_EMAIL_EXISTS', newUserEmailExists)
  },

  /**
   * @param context
   * @param {string} newUserPassword
   * @return {Promise<void>}
   */
  setNewUserPassword(context, newUserPassword) {
    context.commit('SET_NEW_USER_PASSWORD', newUserPassword)
  },

  /**
   * @param context
   * @param {loavies.models.listing.ListingModel} listing
   * @return {Promise<void>}
   */
  async order(context, listing) {
    try {
      const isAuthenticated = context.rootGetters['user/isAuthenticated']
      const newUserEmailExists = context.state.newUserEmailExists
      const latestListing = await this.$marketplaceListingsService.getActiveListingById(listing.id)
      const storeIsClosed = latestListing.author.publicData.storeIsClosed

      if (!isAuthenticated && newUserEmailExists) {
        return Promise.reject(Error(this.app.i18n.t('account_already_exists')))
      }

      if (storeIsClosed) {
        return Promise.reject(Error(this.app.i18n.t('user_store_is_closed', {
          storeName: this.$userHelperService.getFormattedStoreName(latestListing.author),
        })))
      }

      // Create user before ordering
      // User is not initialized in the store
      // because there should be a redirect to the payment provider
      if (!isAuthenticated) {
        await this.$userHandlerService
          .createUserAndLogin(new CreateIdentityUserModel({
            email: context.state.newUserEmail,
            password: context.state.newUserPassword,
            firstName: context.state.address.firstName,
            lastName: context.state.address.lastName,
          }))
          .catch(error => {
            // Fallback for user already existing
            if (error?.response?.status === 409) {
              return Promise.reject(Error(this.app.i18n.t('account_already_exists')))
            }

            return Promise.reject(error)
          })
      }

      // Create new address or use existing address
      const addressIsNew = !context.state.address.id
      const deliveryAddress = addressIsNew
        ? await this.$userHandlerService.createOrUpdateAddress(new CreateIdentityUserAddressModel(context.state.address))
        : context.state.address

      // Create order
      const order = await this.$orderHandlerService.createOrder({
        listingId: listing.id,
        deliveryAddress,
        selectedPaymentMethod: context.state.selectedPaymentMethod,
      })
        .catch(error => {
          // If a listing is already being bought by someone else
          if (error?.response?.data?.data?.errors?.[0]?.code === 'transaction-listing-insufficient-stock') {
            return Promise.reject(Error(this.app.i18n.t('ad_unavailable')))
          }

          return Promise.reject(error)
        })

      // Pay order
      return this.$orderHandlerService.payOrder({
        order,
        paymentMethodData: context.state.paymentMethodData,
        returnUrl: `${window.location.origin}${this.app.localePath('/order-confirmation')}`,
      })
        .then(response => {
          // Redirect to payment provider if action is needed
          if (response?.action?.url && response?.action?.method === 'GET') {
            window.location = response.action.url
            return
          }

          if (response?.action?.url && response?.action?.method === 'POST') {
            const form = document.createElement('form')
            document.body.appendChild(form)
            form.method = 'post'
            form.action = response.action.url

            for (let key in response.action.data) {
              const input = document.createElement('input')
              input.type = 'hidden'
              input.name = key
              input.value = response.action.data[key]
              form.appendChild(input)
            }

            form.submit()

            return
          }

          // Redirect order confirm if order is authorized
          if (response?.resultCode === ORDER_VERIFICATION_RESULT_CODES.AUTHORISED) {
            const orderVerification = mapOrderVerification(response)

            this.$orderHandlerService.setOrderVerificationInStorage(orderVerification)

            return this.$router.replace(this.app.localePath(`/order-confirmation?transactionId=${orderVerification.transactionId}`))
          }

          // We reject the order otherwise
          if (response?.resultCode === ORDER_VERIFICATION_RESULT_CODES.CANCELLED) {
            return Promise.reject(Error(this.app.i18n.t('payment_was_cancelled')))
          }

          if (response?.resultCode === ORDER_VERIFICATION_RESULT_CODES.ERROR) {
            return Promise.reject(Error(this.app.i18n.t('payment_error')))
          }

          if (response?.resultCode === ORDER_VERIFICATION_RESULT_CODES.REFUSED) {
            return Promise.reject(Error(this.app.i18n.t('payment_refused')))
          }

          return Promise.reject(Error(this.app.i18n.t('something_went_wrong')))
        })
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
