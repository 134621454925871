
  import { mapState, mapGetters } from 'vuex'

  import AppButton from '@loavies/component-library/dist/app-button'
  import IconArrowLeftBorderless from '@loavies/component-library/dist/icons/icon-arrow-left-borderless'

  export default {
    components: {
      AppButton,
      IconArrowLeftBorderless,
    },
    props: {
      fallbackPath: {
        type: String,
        default: null,
      },
    },
    computed: {
      ...mapState({
        previousRoutePath: state => state.routes.previousRoutePath,
        isMobile: state => state.isMobile,
      }),
      ...mapGetters({
        currentRoutePathIsListingPage: 'routes/currentRoutePathIsListingPage',
      }),
      allListingsCategory() {
        const categories = this.$staticDataService.globalNavigation?.navigation

        return categories?.find(category => category.showAllListings)
      },
    },
    methods: {
      goBack() {
        if (this.previousRoutePath) {
          return this.$router.go(-1)
        }

        if (this.currentRoutePathIsListingPage) {
          return this.$router.push(this.localePath(this.allListingsCategory.link))
        }

        if (this.fallbackPath) {
          return this.redirectToFallbackPath()
        }

        return this.$router.push(this.localePath('/'))
      },
      redirectToFallbackPath() {
        return this.$router.push(this.localePath(this.fallbackPath))
      },
    },
  }
