
  import { mapState } from 'vuex'
  import AppToast from '@loavies/component-library/dist/app-toast'

  import IconClose from '@loavies/component-library/dist/icons/icon-close'
  import IconCheckmark from '@loavies/component-library/dist/icons/icon-checkmark'

  export default {
    components: {
      AppToast,
      IconClose,
      IconCheckmark,
    },
    computed: {
      ...mapState({
        toastIsOpen: state => state.toast.isOpen,
        toastText: state => state.toast.text,
        toastIcon: state => state.toast.icon,
      }),
    },
  }
