/**
 * @typedef {RoutesHelperService}
 * @alias this.$routesHelperService
 */
export class RoutesHelperService {
  /**
   * @param {string} routeName
   * @returns {boolean}
   */
  isMyOrdersChildRoute(routeName) {
    return routeName.includes('account-order-transactionId')
  }

  /**
   * @param {string} routeName
   * @returns {boolean}
   */
  isMyReviewsChildRoute(routeName) {
    return routeName.includes('account-reviews')
  }

  /**
   * @param {string} routeName
   * @returns {boolean}
   */
  isInboxChildRoute(routeName) {
    return routeName.includes('account-chat-id')
  }

  /**
   * @param {string} routeName
   * @returns {boolean}
   */
  isMyAccountChildRoute(routeName) {
    return routeName.includes('account-edit')
  }
}
