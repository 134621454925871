/**
 * @typedef {ElasticUpdateService}
 * @alias this.$elasticUpdateService
 */
export class ElasticUpdateService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store
  }

  init() {
    this.$nodeApiService = this.context.$nodeApiService

    this.nodeApiInstance = this.$nodeApiService.instance
  }

  /**
   * @param {string} listingId
   * @return {Promise}
   */
  async updateListingInElastic(listingId) {
    if (!this.store.getters['transactions/getListingIsActiveByListingId'](listingId)) {
      return
    }

    return this.nodeApiInstance
      .post(`/listing/elastic/${listingId}`,
        null,
        this.$nodeApiService.getAuthorizationConfig()
      )
  }

  /**
   * @param {string} listingId
   * @return {Promise}
   */
  async deleteListingInElastic(listingId) {
    return this.nodeApiInstance
      .delete(`/listing/elastic/${listingId}`,
        null,
        this.$nodeApiService.getAuthorizationConfig()
      )
      .catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Error trying to delete listing in Elastic', error)
        }
      })
  }

  /**
   * @return {Promise}
   */
  updateMyListings() {
    return this.nodeApiInstance
      .patch('/listings/elastic/mine',
        null,
        this.$nodeApiService.getAuthorizationConfig()
      )
  }
}
