export default {
  /**
   * @param context
   * @return {Promise<void>}
   */
  resetState(context) {
    context.commit('RESET_STATE')
  },

  /**
   * @param context
   * @return {Promise<void>}
   */
  async fetchLatestTransactionsForUser(context) {
    if (!context.rootGetters['user/isAuthenticated']) {
      return Promise.reject(Error('Not authenticated'))
    }

    try {
      await Promise.all([
        context.dispatch('fetchSaleTransactionsForUser'),
        context.dispatch('fetchOrderTransactionsForUser'),
      ])
    } catch (error) {
      return Promise.reject(Error(error))
    }
  },

  /**
   * @param context
   * @return {Promise<void>}
   */
  async fetchSaleTransactionsForUser(context) {
    if (!context.rootGetters['user/isAuthenticated']) {
      return Promise.reject(Error('Not authenticated'))
    }

    try {
      const userId = context.rootState.user.user.id
      const saleTransactions = await this.$marketplaceTransactionsService.getMySaleTransactions(userId)

      context.commit('SET_SALE_TRANSACTIONS', saleTransactions)
    } catch (error) {
      return Promise.reject(Error(error))
    }
  },

  /**
   * @param context
   * @return {Promise<void>}
   */
  async fetchOrderTransactionsForUser(context) {
    if (!context.rootGetters['user/isAuthenticated']) {
      return Promise.reject(Error('Not authenticated'))
    }

    try {
      const userId = context.rootState.user.user.id
      const orderTransactions = await this.$marketplaceTransactionsService.getMyOrderTransactions(userId)

      context.commit('SET_ORDER_TRANSACTIONS', orderTransactions)
    } catch (error) {
      return Promise.reject(Error(error))
    }
  },

  /**
   * @param context
   * @param {string} transactionId
   * @return {Promise<loavies.models.transaction.TransactionModel>}
   */
  async updateTransactionById(context, transactionId) {
    try {
      const updatedTransaction = await this.$marketplaceTransactionsService.getTransactionById(transactionId)

      context.commit('SET_TRANSACTION', updatedTransaction)

      return updatedTransaction
    } catch (error) {
      return Promise.reject(Error(error))
    }
  },
}
