import { ListingSortOption } from '@loavies/c2c-models'

// Objects in Vuex can only be POJO's, hence the map.
export const SORT_OPTIONS = [
  new ListingSortOption({
    key: 'publicData.publishedAt',
    order: 'desc',
    translationKey: 'listing_sorting_newest',
  }),
  new ListingSortOption({
    key: 'publicData.timesWatched',
    order: 'desc',
    translationKey: 'listing_sorting_most_popular',
  }),
  new ListingSortOption({
    key: 'publicData.wishlistCount',
    order: 'desc',
    translationKey: 'listing_sorting_most_likes',
  }),
  new ListingSortOption({
    key: 'price.amount',
    order: 'asc',
    translationKey: 'listing_sorting_price_asc',
  }),
  new ListingSortOption({
    key: 'price.amount',
    order: 'desc',
    translationKey: 'listing_sorting_price_desc',
  }),
].map(object => {
  return { ...object }
})
export const DEFAULT_SORT_OPTION = SORT_OPTIONS[0]

export default () => ({
  listings: null,
  filters: [],
  availableFilters: [],
  activeFilters: [],
  sortOptions: SORT_OPTIONS,
  activeSortOption: DEFAULT_SORT_OPTION,
  priceFilters: [],
  activePriceFilters: [],
  totals: null,
  activeCategory: null,
  recentlyWatchedListings: [],
  listingsPageContent: [],
})
