export default {
  /**
   * @param context
   * @return {Promise<void>}
   */
  resetState(context) {
    context.commit('RESET_STATE')
  },

  /**
   * @param context
   * @param {loavies.models.wallet.WalletModel} wallet
   * @return {Promise<void>}
   */
  setWallet(context, wallet) {
    context.commit('SET_WALLET', wallet)
  },

  /**
   * @param context
   * @param {loavies.models.wallet.CreateWalletModel} createWallet
   */
  async createWallet(context, createWallet) {
    try {
      await this.$walletHandlerService.activateWallet(createWallet)
      await context.dispatch('fetchWallet')

      return context.dispatch('toast/showToastMessage', {
        text: this.app.i18n.t('your_wallet_is_activated'),
        icon: 'IconCheckmark',
      }, { root: true })
    } catch (error) {
      return Promise.reject(error)
    }
  },

  /**
   * @param context
   * @param {loavies.models.wallet.UpdateWalletUserModel} updateWalletUser
   */
  async updateWalletUser(context, updateWalletUser) {
    try {
      await this.$walletHandlerService.updateWalletUser(updateWalletUser)
      await context.dispatch('fetchWallet')

      return context.dispatch('toast/showToastMessage', {
        text: this.app.i18n.t('settings_saved'),
        icon: 'IconCheckmark',
      }, { root: true })
    } catch (error) {
      await context.dispatch('toast/showToastMessage', {
        text: error.message,
        icon: 'IconClose',
      }, { root: true })

      return Promise.reject(error)
    }
  },

  /**
   * @param context
   * @returns {Promise<void>}
   */
  async fetchWallet(context) {
    try {
      const wallet = await this.$walletHandlerService.getWallet()

      if (wallet) {
        return context.dispatch('setWallet', wallet)
      }
    } catch {
      return context.dispatch('setWallet', null)
    }
  },
}
