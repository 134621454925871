
  export default {
    props: {
      error: {
        type: Object,
        required: true,
      },
    },
    methods: {
      goToHome() {
        this.$router.push(this.localePath('/'))
      },
    },
  }
