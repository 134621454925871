export default {
  /**
   * @param state
   * @param {string} componentName
   */
  SET_ACTIVE_APP_MODAL(state, componentName) {
    state.activeAppModal = componentName
  },

  /**
   * @param state
   * @param {string} transitionName
   */
  SET_ACTIVE_APP_MODAL_TRANSITION(state, transitionName) {
    state.activeAppModalTransition = transitionName
  },

  /**
   * @param state
   * @param {Object} options
   */
  SET_ACTIVE_APP_MODAL_OPTIONS(state, options) {
    state.activeAppModalOptions = options
  },

  /**
   * @param state
   * @param {string} componentName
   */
  SET_ACTIVE_APP_TRAY(state, componentName) {
    state.activeAppTray = componentName
  },

  /**
   * @param state
   * @param {string} transitionName
   */
  SET_ACTIVE_APP_TRAY_TRANSITION(state, transitionName) {
    state.activeAppTrayTransition = transitionName
  },

  /**
   * @param state
   * @param {Object} options
   */
  SET_ACTIVE_APP_TRAY_OPTIONS(state, options) {
    state.activeAppTrayOptions = options
  },

  /**
   * @param state
   * @param {string} componentName
   */
  SET_ACTIVE_FLY_IN(state, componentName) {
    state.activeFlyIn = componentName
  },

  /**
   * @param state
   * @param {string} transitionName
   */
  SET_ACTIVE_FLY_IN_TRANSITION(state, transitionName) {
    state.activeFlyInTransition = transitionName
  },

  /**
   * @param state
   * @param {Object} options
   */
  SET_ACTIVE_FLY_IN_OPTIONS(state, options) {
    state.activeFlyInOptions = options
  },

  /**
   * @param state
   * @param {Object} desktop - Specific settings for desktop
   * @param {Object} mobile - Specific settings for mobile
   * @param {Object} settings
   * @param {string} settings.type - Type of overlay
   * @param {string} settings.component - Specific component to show
   * @param {Object} settings.options - Options for the overlay
   */
  SET_CURRENT_OVERLAY(state, { desktop, mobile, settings }) {
    state.desktop = desktop
    state.mobile = mobile
    state.settings = settings
  },

  /**
   * @param state
   */
  CLOSE_APP_MODAL(state) {
    state.activeAppModal = null
    state.activeAppModalTransition = null
    state.activeAppModalOptions = null
    state.desktop = null
    state.mobile = null
    state.settings = null
  },

  /**
   * @param state
   */
  CLOSE_APP_TRAY(state) {
    state.activeAppTray = null
    state.activeAppTrayTransition = null
    state.activeAppTrayOptions = null
    state.desktop = null
    state.mobile = null
    state.settings = null
  },

  /**
   * @param state
   */
  CLOSE_FLY_IN(state) {
    state.activeFlyIn = null
    state.activeFlyTransition = null
    state.activeFlyInOptions = null
    state.desktop = null
    state.mobile = null
    state.settings = null
  },
}
