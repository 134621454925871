import defaultState from './state'
import mutations from './mutations'
import getters from './getters'
import actions from './actions'

function state() {
  return defaultState()
}

export default {
  state,
  mutations,
  getters,
  actions,
}
