
  import { mapState } from 'vuex'

  import AppButton from '@loavies/component-library/dist/app-button'
  import AppIconTextBlock from '@loavies/component-library/dist/app-icon-text-block'
  import IconClose from '@loavies/component-library/dist/icons/icon-close'

  export default {
    components: {
      AppButton,
      AppIconTextBlock,
      IconClose,
    },
    props: {
      title: {
        type: String,
        default: null,
      },
      text: {
        type: String,
        default: null,
      },
      buttonText: {
        type: String,
        default: null,
      },
      errors: {
        type: Array,
        default: () => [],
      },
    },
    emits: ['retry'],
    computed: {
      ...mapState({
        showErrors: state => state.showErrors,
      }),
      translatedTitle() {
        return this.title ?? this.$t('error_state_title')
      },
      translatedText() {
        return this.text ?? this.$t('error_state_text')
      },
      translatedButtonText() {
        return this.buttonText ?? this.$t('try_again')
      },
    },
    methods: {
      getTranslatedErrorMessage(error) {
        return error.messageTranslationKey ? this.$t(error.messageTranslationKey) : error
      },
    },
  }
