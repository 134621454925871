
  import { TransactionEventModel, TransactionModel } from '@loavies/c2c-models'

  export default {
    props: {
      event: {
        type: TransactionEventModel,
        required: true,
      },
      transaction: {
        type: TransactionModel,
        required: true,
      },
    },
    emits: [
      'close',
    ],
    computed: {
      formattedTitle() {
        return this.$transactionHelperService.getTransactionStateTitle(this.event.transactionState, this.transaction.userRole)
      },
      formattedText() {
        return this.$transactionHelperService.getTransactionStateDescription(this.transaction, this.event.transactionState)
      },
      link() {
        return this.$transactionHelperService.getTransactionLink(this.transaction)
      },
    },
  }
