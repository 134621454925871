/**
 * @typedef {ListingTranslationService}
 * @alias this.$listingTranslationService
 */
export class ListingTranslationService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.i18n = context.i18n
  }

  init() {
    this.$staticDataService = this.context.$staticDataService

    this.listingLabels = this.$staticDataService.listingLabels
    this.translatedProductGroups = this.$staticDataService.translatedProductGroups
    this.translatedProductColors = this.$staticDataService.translatedProductColors
  }

  /**
   * @param {string} id
   * @return {string}
   */
  getListingLabelTranslation(id) {
    return this.listingLabels.find(label => label.id === id)?.text ?? id
  }

  /**
   * @param {string} productGroupKey
   * @return {string}
   */
  getProductGroupTranslation(productGroupKey) {
    return this.translatedProductGroups[productGroupKey]?.[this.i18n.locale] ?? this.translatedProductGroups.searchKey ?? productGroupKey
  }

  /**
   * @param {string} productColorKey
   * @return {string}
   */
  getProductColorTranslation(productColorKey) {
    return this.translatedProductColors[productColorKey]?.[this.i18n.locale] ?? this.translatedProductColors.searchKey ?? productColorKey
  }
}
