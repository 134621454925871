import { CurrencyService, DateTimeService } from '@loavies/utils'

import { StaticDataService } from '~/lib/services/core/static-data-service'
import { ElasticApiService } from '~/lib/services/api/elastic/elastic-api-service'
import { DatoApiService } from '~/lib/services/api/dato/dato-api-service'
import { RoutesHelperService } from '~/lib/services/routes/routes-helper-service'
import { FiltersTranslationService } from '~/lib/services/filters/filters-translation-service'
import { FilterQueryHelperService } from '~/lib/services/filters/filter-query-helper-service'
import { ResponsiveImageService } from '~/lib/services/image/responsive-image-service'
import { PageHelperService } from '~/lib/services/page/page-helper-service'
import { ListingTranslationService } from '~/lib/services/listing/listing-translation-service'
import { MagentoApiService } from '~/lib/services/api/magento/magento-api-service'

export default async (context, inject) => {
  /**
   * @typedef ServerNuxtContext
   * @type {Object}
   * @property store
   * @property {Object} env
   * @property {Object} i18n
   * @property {Object} app
   * @property {Object} $sentry
   * @property {Object} $config
   * @property {CurrencyService} $currencyService
   * @property {DateTimeService} $dateTimeService
   * @property {StaticDataService} $staticDataService
   * @property {RoutesHelperService} $routesHelperService
   * @property {ListingTranslationService} $listingTranslationService
   * @property {FiltersTranslationService} $filtersTranslationService
   * @property {FilterQueryHelperService} $filterQueryHelperService
   * @property {DatoApiService} $datoApiService
   * @property {ElasticApiService} $elasticApiService
   * @property {ResponsiveImageService} $responsiveImageService
   * @property {PageHelperService} $pageHelperService
   * @property {MagentoApiService} $magentoApiService
   */

  const services = {}

  services.currencyService = new CurrencyService(context.i18n.locale)
  services.dateTimeService = new DateTimeService(context.i18n.locale)
  services.staticDataService = new StaticDataService(context)
  services.routesHelperService = new RoutesHelperService()
  services.listingTranslationService = new ListingTranslationService(context)
  services.filtersTranslationService = new FiltersTranslationService(context)
  services.filterQueryHelperService = new FilterQueryHelperService(context)
  services.datoApiService = new DatoApiService(context)
  services.elasticApiService = new ElasticApiService(context)
  services.responsiveImageService = new ResponsiveImageService()
  services.pageHelperService = new PageHelperService(context)
  services.magentoApiService = new MagentoApiService(context)

  const serviceKeys = Object.keys(services)

  // Inject services into the Vue context
  serviceKeys.forEach(key => {
    inject(key, services[key])
  })

  // Run init in each service
  for (let i = 0; i < serviceKeys.length; i++) {
    const key = serviceKeys[i]
    await services[key].init?.()
  }
}
