export const HOMEPAGE = '/'
export const MY_WARDROBE = '/account/wardrobe'
export const MY_ORDERS = '/account/orders'
export const MY_ORDER = '/account/order'
export const LISTING = '/listing'
export const LISTINGS = '/listings'
export const CHAT_INBOX = '/account/chats'
export const CHAT_DETAIL = '/account/chat'
export const NOTIFICATIONS = '/account/notifications'
export const VACATION_MODE = '/account/vacation'
export const MY_ACCOUNT = '/account'
export const WALLET = '/account/wallet'
export const MY_LISTING = '/account/listing'
export const MY_LISTINGS = '/account/listings'
export const DRAFTS = '/account/drafts'
export const WISHLIST = '/wishlist'
export const REVIEWS = '/account/reviews'
export const FAQ = '/faq'
export const FOLLOWING = '/account/following'
export const SHOP = '/shop'
export const CHECKOUT = '/checkout'
export const LOGIN = '/login'
export const REGISTER = '/register'
export const FORGOT_PASSWORD = '/forgot-password'
export const TERMS_AND_CONDITIONS = '/terms-and-conditions'
export const PRIVACY_POLICY = '/privacy-policy'
