import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      unreadChatMessages: 'chats/unreadChatMessages',
      unreadTransactionEvents: 'events/unreadUserTransactionEvents',
    }),
    amountUnreadMessages() {
      return this.unreadChatMessages.length + this.unreadTransactionEvents.length
    },
    amountUnreadMessagesToDisplay() {
      return this.amountUnreadMessages > 3 ? '3+' : `${this.amountUnreadMessages}`
    },
  },
}
