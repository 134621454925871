import defaultState from './state'

export default {
  /**
   * @param state
   * @return {void}
   */
  RESET_STATE(state) {
    Object.assign(state, defaultState())
  },

  /**
   * @param state
   * @param {loavies.models.user.UserAddressModel} address
   * @return {void}
   */
  SET_ADDRESS(state, address) {
    state.address = address
  },

  /**
   * @param state
   * @param {loavies.models.payment.PaymentMethodModel|loavies.models.payment.PaymentMethodIssuerModel} selectedPaymentMethod
   */
  SET_SELECTED_PAYMENT_METHOD(state, selectedPaymentMethod) {
    state.selectedPaymentMethod = selectedPaymentMethod
  },

  /**
   * @param state
   * @param {Object} paymentMethodData
   */
  SET_PAYMENT_METHOD_DATA(state, paymentMethodData) {
    state.paymentMethodData = paymentMethodData
  },

  /**
   * @param state
   * @param {string} newUserEmail
   * @return {void}
   */
  SET_NEW_USER_EMAIL(state, newUserEmail) {
    state.newUserEmail = newUserEmail
  },

  /**
   * @param state
   * @param {boolean} newUserEmailExists
   * @return {void}
   */
  SET_NEW_USER_EMAIL_EXISTS(state, newUserEmailExists) {
    state.newUserEmailExists = newUserEmailExists
  },

  /**
   * @param state
   * @param {string} newUserPassword
   * @return {void}
   */
  SET_NEW_USER_PASSWORD(state, newUserPassword) {
    state.newUserPassword = newUserPassword
  },
}
