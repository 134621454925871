/*
* TODO: KEEP ORDER ALPHABETICAL FOR EASY MAINTENANCE
*/

export const OVERLAYS = {
  APP_MODAL: 'AppModal',
  APP_TRAY: 'AppTray',
  APP_FLY_IN: 'AppFlyIn',
}

export const APP_MODAL_NAMES = {
  ACCOUNT_LISTING_REMOVE_APP_MODAL: 'AccountListingRemoveAppModal',
  ACCOUNT_ONBOARDING_APP_MODAL: 'AccountOnboardingAppModal',
  CONFIRM_CLOSE_ACCOUNT_ONBOARDING_APP_MODAL: 'ConfirmCloseAccountOnboardingAppModal',
  EDIT_BIO_APP_MODAL: 'EditBioAppModal',
  EDIT_DISPLAY_NAME_APP_MODAL: 'EditDisplayNameAppModal',
  EDIT_STORE_NAME_APP_MODAL: 'EditStoreNameAppModal',
  GENERAL_ONBOARDING_WIZARD_APP_MODAL: 'GeneralOnboardingWizardAppModal',
  IMAGE_GALLERY_OVERLAY_APP_MODAL: 'ImageGalleryOverlayAppModal',
  LISTING_DISCOUNT_APP_MODAL: 'ListingDiscountAppModal',
  LISTING_LABEL_APP_MODAL: 'ListingLabelAppModal',
  ORDER_CUSTOMER_CONFIRM_APP_MODAL: 'OrderCustomerConfirmAppModal',
  ORDER_CUSTOMER_DISPUTE_APP_MODAL: 'OrderCustomerDisputeAppModal',
  ORDER_CUSTOMER_RESOLVE_DISPUTE_APP_MODAL: 'OrderCustomerResolveDisputeAppModal',
  ORDER_CUSTOMER_START_DISPUTE_APP_MODAL: 'OrderCustomerStartDisputeAppModal',
  ORDER_SELLER_REFUND_ITEM_APP_MODAL: 'OrderSellerRefundItemAppModal',
  PHOTO_TIPS_APP_MODAL: 'PhotoTipsAppModal',
  SEARCH_LISTINGS_APP_MODAL: 'SearchListingsAppModal',
  SEARCH_POSTAL_SERVICE_POINT_APP_MODAL: 'SearchPostalServicePointAppModal',
  SHIPMENT_INSTRUCTIONS_APP_MODAL: 'ShipmentInstructionsAppModal',
  SHIPMENT_LABEL_APP_MODAL: 'ShipmentLabelAppModal',
  WRITE_REVIEW_APP_MODAL: 'WriteReviewAppModal',
  CONTACT_CUSTOMER_CARE_APP_MODAL: 'ContactCustomerCareAppModal',
  USER_ADDRESSES_OVERVIEW_MODAL: 'UserAddressesOverviewModal',
  CHECKOUT_SELECT_PAYMENT_METHOD_MODAL: 'CheckoutSelectPaymentMethodModal',
  REPORT_USER_APP_MODAL: 'ReportUserAppModal',
  WALLET_ACTIVATION_APP_MODAL: 'WalletActivationAppModal',
  WALLET_EDIT_APP_MODAL: 'WalletEditAppModal',
  WALLET_INFORMATION_APP_MODAL: 'WalletInformationAppModal',
  WALLET_PAYOUT_APP_MODAL: 'WalletPayoutAppModal',
  CONFIRM_APP_MODAL: 'ConfirmModal',
}

export const APP_TRAY_NAMES = {
  ACCOUNT_CHANGE_GRID_LAYOUT_APP_TRAY: 'AccountChangeGridLayoutAppTray',
  ACCOUNT_LISTING_REMOVE_APP_TRAY: 'AccountListingRemoveAppTray',
  CONFIRM_CLOSE_ACCOUNT_ONBOARDING_APP_TRAY: 'ConfirmCloseAccountOnboardingAppTray',
  ACCOUNT_MORE_MENU_APP_TRAY: 'AccountMoreMenuAppTray',
  EDIT_BIO_APP_TRAY: 'EditBioAppTray',
  EDIT_DISPLAY_NAME_APP_TRAY: 'EditDisplayNameAppTray',
  EDIT_STORE_NAME_APP_TRAY: 'EditStoreNameAppTray',
  GENERAL_ONBOARDING_WIZARD_APP_TRAY: 'GeneralOnboardingWizardAppTray',
  LISTING_DISCOUNT_APP_TRAY: 'ListingDiscountAppTray',
  LISTING_FILTERS_APP_TRAY: 'ListingFiltersAppTray',
  LISTING_LABEL_APP_TRAY: 'ListingLabelAppTray',
  LISTING_MORE_MENU_APP_TRAY: 'ListingMoreMenuAppTray',
  ORDER_CUSTOMER_CONFIRM_APP_TRAY: 'OrderCustomerConfirmAppTray',
  ORDER_CUSTOMER_DISPUTE_APP_TRAY: 'OrderCustomerDisputeAppTray',
  ORDER_CUSTOMER_RESOLVE_DISPUTE_APP_TRAY: 'OrderCustomerResolveDisputeAppTray',
  ORDER_CUSTOMER_START_DISPUTE_APP_TRAY: 'OrderCustomerStartDisputeAppTray',
  ORDER_SELLER_REFUND_ITEM_APP_TRAY: 'OrderSellerRefundItemAppTray',
  PHOTO_TIPS_APP_TRAY: 'PhotoTipsAppTray',
  SEARCH_LISTINGS_APP_TRAY: 'SearchListingsAppTray',
  SEARCH_POSTAL_SERVICE_POINT_APP_TRAY: 'SearchPostalServicePointAppTray',
  SHIPMENT_INSTRUCTIONS_APP_TRAY: 'ShipmentInstructionsAppTray',
  SHIPMENT_LABEL_APP_TRAY: 'ShipmentLabelAppTray',
  SHOP_MORE_MENU_APP_TRAY: 'ShopMoreMenuAppTray',
  WRITE_REVIEW_APP_TRAY: 'WriteReviewAppTray',
  USER_ADDRESSES_OVERVIEW_APP_TRAY: 'UserAddressesOverviewAppTray',
  CONTACT_CUSTOMER_CARE_APP_TRAY: 'ContactCustomerCareAppTray',
  CHECKOUT_SELECT_PAYMENT_METHOD_APP_TRAY: 'CheckoutSelectPaymentMethodAppTray',
  USER_REVIEWS_SUMMARY_MORE_MENU_APP_TRAY: 'UserReviewsSummaryMoreMenuAppTray',
  REPORT_USER_APP_TRAY: 'ReportUserAppTray',
  WALLET_ACTIVATION_APP_TRAY: 'WalletActivationAppTray',
  WALLET_EDIT_APP_TRAY: 'WalletEditAppTray',
  WALLET_INFORMATION_APP_TRAY: 'WalletInformationAppTray',
  WALLET_PAYOUT_APP_TRAY: 'WalletPayoutAppTray',
  NEWSLETTER_SIGNUP_FORM_APP_TRAY: 'NewsletterSignupFormAppTray',
}

export const FLY_IN_NAMES = {
  AUTH_FLY_IN: 'AuthFlyIn',
  ACCOUNT_MENU_FLY_IN: 'AccountMenuFlyIn',
  LISTING_FILTERS_FLY_IN: 'ListingFiltersFlyIn',
  MOBILE_NAVIGATION_FLY_IN: 'MobileNavigationFlyIn',
  SEARCH_LISTINGS_FLY_IN: 'SearchListingsFlyIn',
}
