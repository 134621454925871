export default {
  /**
   * @returns {loavies.models.review.ReviewModel[]}
   */
  givenReviews(state, getters, rootState) {
    return getReviewsFromTransactions(rootState.transactions.orderTransactions)
  },

  /**
   * @returns {loavies.models.review.ReviewModel[]}
   */
  receivedReviews(state, getters, rootState) {
    return getReviewsFromTransactions(rootState.transactions.saleTransactions)
  },
}

/**
 * @param {loavies.models.transaction.TransactionModel[]} transactions
 * @return {loavies.models.review.ReviewModel[]}
 */
function getReviewsFromTransactions(transactions) {
  return transactions.reduce((acc, transaction) => {
    if (transaction.reviews.length) {
      acc.push(...transaction.reviews)
    }
    return acc
  }, [])
}
