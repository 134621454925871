import {
  ListingModel,
  ApiTotalsModel,
  PriceModel,
  ImageModel,
  ImageVariantsModel,
  ImageVariantModel,
  ListingPublicDataModel,
  UserModel,
  UserPublicDataModel,
  ListingFilter,
  ListingFilterOption,
  ListingPriceFilter,
  ListingDiscountModel,
} from '@loavies/c2c-models'

import { AGGREGATION_FILTER_KEYS, AGGREGATION_PRICE_FILTERS } from './elastic-api-queries'

/**
 * @param {number} page
 * @param {number} perPage
 * @param {Object} totals
 * @return {loavies.models.api.ApiTotalsModel}
 */
export function mapApiTotals({ page, perPage, totalItems }) {
  const totalPages = Math.ceil(totalItems / perPage)

  return new ApiTotalsModel({
    totalItems,
    totalPages,
    page: page,
    perPage,
    paginationLimit: totalPages,
  })
}

/**
 * @param {Array} listings
 * @return {loavies.models.listing.ListingModel[]}
 */
export function mapListings(listings) {
  return listings.map(listing => mapListing(listing))
}

/**
 * @param {Object} listing
 * @return {loavies.models.listing.ListingModel}
 */
export function mapListing(listing) {
  return new ListingModel({
    ...listing,
    createdAt: new Date(listing.createdAt),
    price: new PriceModel(listing.price),
    images: listing.images.map(image => mapImage(image)),
    publicData: mapListingPublicData(listing.publicData),
    author: new UserModel({
      ...listing.author,
      createdAt: new Date(listing.author.createdAt),
      image: listing.author.image ? mapImage(listing.author.image) : null,
      publicData: new UserPublicDataModel(listing.author.publicData),
    }),
  })
}

/**
 * @param {Object} image
 * @return {loavies.models.image.ImageModel}
 */
export function mapImage(image) {
  return new ImageModel({
    ...image,
    variants: new ImageVariantsModel(Object
      .entries(image.variants)
      .reduce((acc, variant) => {
        const key = variant[0]
        const value = variant[1]

        acc[key] = new ImageVariantModel(value)

        return acc
      }, {})),
  })
}

/**
 * @param {Object} publicData
 * @return {loavies.models.listing.ListingPublicDataModel}
 */
export function mapListingPublicData(publicData) {
  return new ListingPublicDataModel({
    ...publicData,
    discount: publicData.discount && mapListingDiscount(publicData.discount),
  })
}

/**
 * @param {Object} discount
 * @return {loavies.models.listing.ListingDiscountModel}
 */
export function mapListingDiscount(discount) {
  return new ListingDiscountModel({
    ...discount,
    originalPrice: new PriceModel(discount.originalPrice),
  })
}

/**
 * @param {Object} aggregations
 * @return {loavies.models.listing.ListingFilter[]}
 */
export function mapListingFilters(aggregations) {
  return Object.values(AGGREGATION_FILTER_KEYS).map(key => mapListingFilter(key, aggregations[key]))
}

/**
 * @param {string} key
 * @param {Object} aggregation
 * @return {loavies.models.listing.ListingFilter}
 */
export function mapListingFilter(key, aggregation) {
  return new ListingFilter({
    key,
    options: aggregation[key]
      // When filters are active
      ? aggregation[key].buckets.map(bucket => mapListingFilterOptions(key, bucket))
      : aggregation.buckets.map(bucket => mapListingFilterOptions(key, bucket)),
  })
}

/**
 * @param {string} filterKey
 * @param {Object} bucket
 * @return {loavies.models.listing.ListingFilterOption}
 */
export function mapListingFilterOptions(filterKey, bucket) {
  return new ListingFilterOption({
    filterKey,
    key: bucket.key,
    count: bucket.doc_count,
  })
}

/**
 * @param {Object} aggregations
 * @return {loavies.models.listing.ListingPriceFilter[]}
 */
export function mapPriceFilters(aggregations) {
  return AGGREGATION_PRICE_FILTERS.map(priceFilter => new ListingPriceFilter({
    key: priceFilter.key,
    type: priceFilter.type,
    field: priceFilter.field,
    amount: aggregations[priceFilter.key].value,
  }))
}
