import { ChatModel } from '@loavies/c2c-models'
import { mapTalkJsIncomingMessage, mapChatModelToTalkJsIncomingMessage } from '@loavies/c2c-marketplace-lib'

export default {
  /**
   * @param context
   * @return {Promise<void>}
   */
  resetState(context) {
    context.commit('RESET_STATE')
  },

  /**
   * @param context
   * @param {Object} [singleNewMessage]
   * @param {boolean} [notify]
   */
  async setChats(context, { notify = true, singleNewMessage }) {
    try {
      if (singleNewMessage && notify) {
        const talkJsIncomingMessage = mapTalkJsIncomingMessage(singleNewMessage)
        context.commit('UPDATE_STORE_CHAT', talkJsIncomingMessage)

        if (talkJsIncomingMessage.isByMe) return

        context.commit('ADD_NOTIFIABLE_CHAT', talkJsIncomingMessage)
        return
      }

      const tokenDetails = this.$marketplaceAuthenticationService.getTokenDetails()
      const userId = context.rootState.user.user.id
      let transactions = context.rootGetters['transactions/getAllTransactions']

      if (!tokenDetails) return

      const activeTalkJsChats = await this.$chatApiService.getMyTalkJsChats()

      if (transactionsMissing(activeTalkJsChats, transactions)) {
        transactions = await this.$marketplaceTransactionsService.queryAll({ userId })
      }

      if (!transactions || !activeTalkJsChats) return

      const chats = transactions
        .reduce((chats, transaction) => {
          const activeTalkJsChat = activeTalkJsChats.find(activeChat => activeChat.id === transaction.id)

          if (activeTalkJsChat) {
            chats.push(new ChatModel({
              id: transaction.id,
              talkJsChat: activeTalkJsChat,
              transaction,
            }))
          }

          return chats
        }, [])
        .sort((a, b) => (a.talkJsChat.lastMessage?.createdAt > b.talkJsChat.lastMessage?.createdAt) ? -1 : 1)

      context.commit('SET_CHATS', chats)

      if (!notify) return

      const notifiableChats = context.getters.unreadChatMessages

      if (!notifiableChats.length) return

      context.commit('SET_NOTIFIABLE_CHATS', notifiableChats.map(chat => mapChatModelToTalkJsIncomingMessage(chat)))
    } catch (error) {
      await context.dispatch('setHasError', true)
      return Promise.reject(Error(error))
    }
  },

  /**
   * @param context
   * @param {boolean} chatBrowserNotificationsEnabled
   * @return {Promise}
   */
  async setChatBrowserNotificationsEnabled(context, chatBrowserNotificationsEnabled) {
    const currentUserChatBrowserNotificationsEnabled = context.rootState.user.user?.privateData.chatBrowserNotificationsEnabled

    try {
      await this.$chatApiService.setBrowserNotifications(chatBrowserNotificationsEnabled)

      // Only update user if settings have changed to prevent update spam
      if (currentUserChatBrowserNotificationsEnabled !== chatBrowserNotificationsEnabled) {
        await this.$marketplaceUserService
          .updateChatBrowserNotificationsEnabled(chatBrowserNotificationsEnabled)
          .then(user => context.dispatch('user/setUser', user, { root: true }))
      }
    } catch (error) {
      // Only update user if settings have changed to prevent update spam
      if (currentUserChatBrowserNotificationsEnabled !== false) {
        // If browser notification could not be set, the browser could not support
        // notifications or user has blocked notifications in their browser.
        // In that case chatBrowserNotificationsEnabled should always be set to false.
        await this.$marketplaceUserService
          .updateChatBrowserNotificationsEnabled(false)
          .then(user => context.dispatch('user/setUser', user, { root: true }))
      }

      return context.dispatch('toast/showToastMessage', {
        text: error.message,
        icon: 'IconClose',
      }, { root: true })
    }
  },

  /**
   * @param context
   * @param {string} id
   * @return {Promise}
   */
  setChatMessageRead(context, id) {
    if (!context.getters.hasUnreadChatMessages) return Promise.resolve()

    const userId = context.rootState.user.user?.id

    context.commit('SET_CHAT_MESSAGE_READ', { id, userId })
  },

  /**
   * @param context
   * @param {boolean} hasError
   */
  setHasError(context, hasError) {
    context.commit('SET_HAS_ERROR', hasError)
  },

  /**
   * @param context
   * @param {Array} payload
   */
  updateNotifiableChats(context, payload = []) {
    context.commit('SET_NOTIFIABLE_CHATS', payload)
  },
}

/**
 * @param {loavies.models.chat.TalkJsChatModel[]} activeTalkJsChats
 * @param {loavies.models.transaction.TransactionModel[]} transactions
 * @return {boolean}
 */
function transactionsMissing(activeTalkJsChats, transactions) {
  const chatIds = activeTalkJsChats.map(chat => chat.id)
  const transactionIds = transactions.map(transaction => transaction.id)

  return chatIds.some(chatId => !transactionIds.includes(chatId))
}
