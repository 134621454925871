import {
  TRANSACTION_STATES,
} from '@loavies/c2c-models'

/**
 * @typedef {EventsHelperService}
 * @alias this.$eventsHelperService
 */
export class EventsHelperService {
  /**
   * This method maps all given events and returns only
   * the latest event for every unique transaction id.
   * @param {loavies.models.event.TransactionEventModel[]} transactionEvents
   * @returns {loavies.models.event.TransactionEventModel[]}
   */
  getLatestEventsForUniqueTransactions(transactionEvents) {
    return Object.values(transactionEvents
      .reduce((acc, event) => {
        if (!acc[event.resourceId]) {
          acc[event.resourceId] = event

          return acc
        }

        if (event.sequenceId > acc[event.resourceId].sequenceId) {
          acc[event.resourceId] = event
          return acc
        }

        return acc
      }, {}))
  }

  /**
   * @param {loavies.models.event.TransactionEventModel} event
   * @param {string} userId
   * @returns {boolean}
   */
  isOwnActionsEvent({ event, userId }) {
    if (event.providerId === userId) {
      return providerEventActions.includes(event.transactionState)
    }

    if (event.customerId === userId) {
      return customerEventActions.includes(event.transactionState)
    }

    return false
  }
}

// These events should not be notified if the user is the customer himself
const customerEventActions = [
  TRANSACTION_STATES.DISPUTE,
  TRANSACTION_STATES.COMPLETED_AND_REVIEWED,
]

// These events should not be notified if the user is the provider himself
const providerEventActions = [
  TRANSACTION_STATES.ITEM_SENT,
]
