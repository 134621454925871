import { mapTransactionEvent } from '../api/node-api-mappers'

/**
 * @typedef {EventsHandlerService}
 * @alias this.$eventsHandlerService
 */
export class EventsHandlerService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
  }

  init () {
    this.$nodeApiService = this.context.$nodeApiService

    this.nodeApiInstance = this.$nodeApiService.instance
  }

  /**
   * @param {number} [fromSequenceId]
   * @param {string} [fromDateTimeString] ISO 8601 Date Time String
   * @returns {Promise<loavies.models.event.EventModel[]>}
   */
  getUserTransactionEvents({ fromSequenceId, fromDateTimeString } = {}) {
    const config = this.$nodeApiService.getAuthorizationConfig()
    const queryParameters = new URLSearchParams({
      ...fromSequenceId && { fromSequenceId },
      ...fromDateTimeString && { fromDateTimeString },
    }).toString()
    const formattedQueryParameters = queryParameters ? `?${queryParameters}` : ''

    return this.nodeApiInstance
      .get(`/event/user-transaction-events${formattedQueryParameters}`, config)
      .then(response => response.data?.events)
      .then(events => events.map(event => mapTransactionEvent(event)))
  }
}
