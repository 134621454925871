import { render, staticRenderFns } from "./app-desktop-menu.vue?vue&type=template&id=84c77e4e"
var script = {}
import style0 from "./app-desktop-menu.vue?vue&type=style&index=0&id=84c77e4e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalNavigation: require('/opt/build/repo/components/global-navigation/global-navigation.vue').default})
