import { render, staticRenderFns } from "./account-button.vue?vue&type=template&id=396024e7"
import script from "./account-button.vue?vue&type=script&lang=js"
export * from "./account-button.vue?vue&type=script&lang=js"
import style0 from "./account-button.vue?vue&type=style&index=0&id=396024e7&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserAvatar: require('/opt/build/repo/components/user-avatar/user-avatar.vue').default,AccountMenu: require('/opt/build/repo/components/account/account-menu/account-menu.vue').default})
