import Vue from 'vue'
import defaultState from './state'

export default {
  /**
   * @param state
   */
  RESET_STATE(state) {
    Object.assign(state, defaultState())
  },

  /**
   * @param state
   * @param {loavies.models.listing.ListingModel[]} listings
   */
  SET_LISTINGS(state, listings) {
    state.listings = listings
  },

  /**
   * @param state
   * @param {loavies.models.listing.ListingFilter[]} filters
   */
  SET_FILTERS(state, filters) {
    state.filters = filters
  },

  /**
   * @param state
   * @param {loavies.models.listing.ListingFilter[]} availableFilters
   */
  SET_AVAILABLE_FILTERS(state, availableFilters) {
    state.availableFilters = availableFilters
  },

  /**
   * @param state
   * @param {loavies.models.listing.ListingFilter[]} activeFilters
   */
  SET_ACTIVE_FILTERS(state, activeFilters) {
    state.activeFilters = activeFilters
  },

  /**
   * @param state
   * @param {loavies.models.listing.ListingFilter} activeFilter
   */
  ADD_ACTIVE_FILTER(state, activeFilter) {
    state.activeFilters.push(activeFilter)
  },

  /**
   * @param state
   * @param {number} index
   * @param {loavies.models.listing.ListingFilter} activeFilter
   */
  REPLACE_ACTIVE_FILTER_BY_INDEX(state, { index, activeFilter }) {
    Vue.set(state.activeFilters, index, activeFilter)
  },

  /**
   * @param state
   * @param {number} index
   */
  DELETE_ACTIVE_FILTER_BY_INDEX(state, index) {
    Vue.delete(state.activeFilters, index)
  },

  /**
   * @param state
   * @param {loavies.models.listing.ListingSortOption} sortOption
   */
  SET_ACTIVE_SORT_OPTION(state, sortOption) {
    state.activeSortOption = sortOption
  },

  /**
   * @param state
   * @param {loavies.models.listing.ListingPriceFilter} priceFilters
   */
  SET_PRICE_FILTERS(state, priceFilters) {
    state.priceFilters = priceFilters
  },

  /**
   * @param state
   * @param {loavies.models.listing.ListingPriceFilter} priceFilters
   */
  SET_ACTIVE_PRICE_FILTERS(state, priceFilters) {
    state.activePriceFilters = priceFilters
  },

  /**
   * @param state
   * @param {loavies.models.listing.ListingPriceFilter} activePriceFilter
   */
  ADD_ACTIVE_PRICE_FILTER(state, activePriceFilter) {
    state.activePriceFilters.push(activePriceFilter)
  },

  /**
   * @param state
   * @param {number} index
   * @param {loavies.models.listing.ListingPriceFilter} activePriceFilter
   */
  REPLACE_ACTIVE_PRICE_FILTER_BY_INDEX(state, { index, activePriceFilter }) {
    Vue.set(state.activePriceFilters, index, activePriceFilter)
  },

  /**
   * @param state
   * @param {number} index
   */
  DELETE_ACTIVE_PRICE_FILTER_BY_INDEX(state, index) {
    Vue.delete(state.activePriceFilters, index)
  },

  /**
   * @param state
   * @param {loavies.models.api.ApiTotalsModel} totals
   */
  SET_TOTALS(state, totals) {
    state.totals = totals
  },

  /**
   * @param state
   * @param {string|null} activeCategory
   */
  SET_ACTIVE_CATEGORY(state, activeCategory) {
    state.activeCategory = activeCategory
  },

  /**
   * @param state
   * @param {loavies.models.listing.ListingModel[]} recentlyWatchedListings
   */
  SET_RECENTLY_WATCHED_LISTINGS(state, recentlyWatchedListings) {
    const ids = recentlyWatchedListings.map(listing => listing.id)
    this.$clientStorageService.setRecentlyWatchedListingIds(ids)

    state.recentlyWatchedListings = recentlyWatchedListings
  },

  /**
   * @param state
   * @param {loavies.models.listing.ListingModel} listing
   */
  ADD_TO_RECENTLY_WATCHED_LISTINGS(state, listing) {
    const ids = state.recentlyWatchedListings.map(listing => listing.id)
    this.$clientStorageService.setRecentlyWatchedListingIds([...ids, listing.id])

    state.recentlyWatchedListings.unshift(listing)
  },

  /**
   * @param state
   * @param {Array<Object>} content
   */
  SET_LISTING_PAGE_CONTENT(state, content) {
    state.listingsPageContent = content
  },
}
