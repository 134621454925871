import { render, staticRenderFns } from "./app-footer.vue?vue&type=template&id=5b64ebe1"
import script from "./app-footer.vue?vue&type=script&lang=js"
export * from "./app-footer.vue?vue&type=script&lang=js"
import style0 from "./app-footer.vue?vue&type=style&index=0&id=5b64ebe1&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewsletterSignup: require('/opt/build/repo/components/newsletter/newsletter-signup.vue').default,AppFooterMenuItems: require('/opt/build/repo/components/app-footer-menu-items/app-footer-menu-items.vue').default,LoaviesLogo: require('/opt/build/repo/components/loavies-logo/loavies-logo.vue').default,SocialIcons: require('/opt/build/repo/components/social-icons/social-icons.vue').default,SmartLink: require('/opt/build/repo/components/smart-link/smart-link.vue').default})
