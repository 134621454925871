import { ListingFilter, ListingFilterOption, ListingPriceFilter } from '@loavies/c2c-models'
import { SORT_OPTIONS, DEFAULT_SORT_OPTION } from '~/store/listings/state'

/**
 * @typedef {FilterQueryHelperService}
 * @alias this.$filterQueryHelperService
 */
export class FilterQueryHelperService {
  constructor(context) {
    /** @type {ServerNuxtContext} */
    this.context = context
    this.router = context.app.router
  }

  /**
   * @param {loavies.models.listing.ListingSortOption} [sortOption]
   * @param {string} [path]
   * @return {Promise<void>}
   */
  setSortOptionInQuery({ sortOption, path }) {
    if (!sortOption || sortOption.key === DEFAULT_SORT_OPTION.key) {
      return this.removeSortFromQuery()
    }

    return this.router.push({
      ...(path && { path }),
      query: {
        ...this.router.currentRoute.query,
        ...this.formatQueryFromSortOption(sortOption),
      },
    })
  }

  /**
   * @param {loavies.models.listing.ListingFilter[]} [filters]
   * @param {string} [path]
   * @return {Promise<void>}
   */
  async setFiltersInQuery({ filters, path }) {
    const newFiltersQuery = this.formatQueryFromFilters(filters)

    if (newFiltersQuery) {
      await this.router.push({
        ...(path && { path }),
        query: {
          ...this.router.currentRoute.query,
          ...newFiltersQuery,
        },
      })
    } else {
      return this.removeFiltersFromQuery()
    }
  }

  /**
   * @param {loavies.models.listing.ListingPriceFilter} priceFilter
   * @param {string} [path]
   * @return {Promise<void>}
   */
  setPriceFilterInQuery({ priceFilter, path }) {
    return this.setPriceFiltersInQuery({
      priceFilters: [priceFilter],
      path,
    })
  }

  /**
   * @param {loavies.models.listing.ListingPriceFilter[]} [priceFilters]
   * @param {string} [path]
   * @return {Promise<void>}
   */
  async setPriceFiltersInQuery({ priceFilters, path }) {
    const newPriceFiltersQuery = this.formatQueryFromPriceFilters(priceFilters)

    if (newPriceFiltersQuery) {
      await this.router.push({
        ...(path && { path }),
        query: {
          ...this.router.currentRoute.query,
          ...newPriceFiltersQuery,
        },
      })
    } else {
      return this.removePriceFiltersFromQuery()
    }
  }

  /**
   * @param {string} key
   * @return {Promise<void>}
   */
  async removeKeyFromQuery(key) {
    const mappedQuery = { ...this.router.currentRoute.query }

    if (mappedQuery[key]) {
      delete mappedQuery[key]

      await this.router.push({
        query: {
          ...mappedQuery,
        },
      })
    }
  }

  /**
   * @return {Promise<void>}
   */
  async removeSortFromQuery() {
    return this.removeKeyFromQuery('sort')
  }

  /**
   * @return {Promise<void>}
   */
  async removeFiltersFromQuery() {
    return this.removeKeyFromQuery('filters')
  }

  /**
   * @return {Promise<void>}
   */
  async removePriceFiltersFromQuery() {
    return this.removeKeyFromQuery('priceFilters')
  }

  /**
   * @param {loavies.models.listing.ListingSortOption} [sortOption]
   * @return {Object}
   */
  formatQueryFromSortOption(sortOption) {
    if (!sortOption || sortOption.key === DEFAULT_SORT_OPTION.key) {
      return
    }

    const formattedSortOption = {
      key: sortOption.key,
      order: sortOption.order,
    }

    return { sort: encodeURIComponent(JSON.stringify(formattedSortOption)) }
  }

  /**
   * @param {loavies.models.listing.ListingPriceFilter[]} [priceFilters]
   * @return {Object}
   */
  formatQueryFromPriceFilters(priceFilters) {
    if (!priceFilters?.length) {
      return
    }

    return { priceFilters: encodeURIComponent(JSON.stringify(priceFilters)) }
  }

  /**
   * @param {loavies.models.listing.ListingFilter[]} [filters]
   * @return {Object}
   */
  formatQueryFromFilters(filters) {
    if (!filters?.length) {
      return
    }

    const formattedFilters = filters.reduce((acc, filter) => {
      acc[filter.key] = filter.options
        .map(option => option.key)
        .join(',')

      return acc
    }, {})

    return { filters: encodeURIComponent(JSON.stringify(formattedFilters)) }
  }

  /**
   * @param {loavies.models.listing.ListingFilterOption} filterOption
   */
  formatQueryStringFromFilterOption(filterOption) {
    const formattedFilter = {
      [filterOption.filterKey]: filterOption.key,
    }

    return { filters: encodeURIComponent(JSON.stringify(formattedFilter)) }
  }

  /**
   * @param {string} queryString
   * @return {Object|loavies.models.listing.ListingSortOption} ListingSortOption are stored as POJO's in Vuex
   */
  formatSortOptionFromQueryString(queryString) {
    if (!queryString) {
      return DEFAULT_SORT_OPTION
    }

    const data = JSON.parse(decodeURIComponent(queryString))

    return SORT_OPTIONS.find(sortOption =>
      sortOption.key === data.key
      && sortOption.order === data.order
    )
  }

  /**
   * @param {string} queryString
   * @return {loavies.models.listing.ListingFilter[]}
   */
  formatFiltersFromQueryString(queryString) {
    if (!queryString) {
      return []
    }

    const data = JSON.parse(decodeURIComponent(queryString))
    const mappedFilters = []

    Object.entries(data).forEach(([key, value]) => {
      mappedFilters.push(new ListingFilter({
        key,
        options: value.split(',').map(entry => new ListingFilterOption({
          filterKey: key,
          key: entry,
          count: 0, // Doesn't matter
        })),
      }))
    })

    return mappedFilters
  }

  /**
   * @param {string} queryString
   * @return {loavies.models.listing.ListingPriceFilter[]}
   */
  formatPriceFiltersFromQueryString(queryString) {
    if (!queryString) {
      return []
    }

    const data = JSON.parse(decodeURIComponent(queryString))

    return data.map(item => new ListingPriceFilter(item))
  }
}
