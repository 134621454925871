import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      desktopMatchMedia: process.client && window.matchMedia('(min-width: 1024px)'),
      mobileMatchMedia: process.client && window.matchMedia('(max-width: 1023px)'),
      hoverMatchMedia: process.client && window.matchMedia('(hover: hover)'),
    }
  },
  computed: {
    ...mapState({
      isDesktop: state => state.isDesktop,
      isMobile: state => state.isMobile,
      isHoverSupported: state => state.isHoverSupported,
    }),
  },
  mounted() {
    this.desktopMatchMedia.addListener(this.onDesktopMatchMedia)
    this.mobileMatchMedia.addListener(this.onMobileMatchMedia)
    this.hoverMatchMedia.addListener(this.onHoverMatchMedia)
    this.onDesktopMatchMedia(this.desktopMatchMedia)
    this.onMobileMatchMedia(this.mobileMatchMedia)
    this.onHoverMatchMedia(this.hoverMatchMedia)
    this.setMediaQueryInitialized(true)
    this.handleIsIOS()
  },
  beforeDestroy() {
    this.desktopMatchMedia.removeListener(this.onDesktopMatchMedia)
    this.mobileMatchMedia.removeListener(this.onMobileMatchMedia)
    this.hoverMatchMedia.removeListener(this.onHoverMatchMedia)
  },
  methods: {
    ...mapActions({
      setIsDesktop: 'setIsDesktop',
      setIsMobile: 'setIsMobile',
      setIsHoverSupported: 'setIsHoverSupported',
      setMediaQueryInitialized: 'setMediaQueryInitialized',
      setIsIOS: 'setIsIOS',
    }),
    onDesktopMatchMedia(event) {
      if (this.isDesktop !== event.matches) {
        this.setIsDesktop(event.matches)
      }
    },
    onMobileMatchMedia(event) {
      if (this.isMobile !== event.matches) {
        this.setIsMobile(event.matches)
      }
    },
    onHoverMatchMedia(event) {
      if (this.isHoverSupported !== event.matches) {
        this.setIsHoverSupported(event.matches)
      }
    },
    handleIsIOS() {
      this.setIsIOS(!!navigator.userAgent.match(/iPhone|iPod|iPad/))
    },
  },
}
