
  export default {
    props: {
      link: {
        type: Object,
        required: true,
      },
    },
    computed: {
      linkComponent() {
        return this.link.internalUrl ? 'nuxt-link' : 'a'
      },
      linkUrl() {
        return this.link.internalUrl
          ? this.localePath(this.link.internalUrl)
          : this.link.externalUrl ?? ''
      },
      componentBindings() {
        if (this.link.internalUrl) {
          return {
            to: this.linkUrl,
          }
        }

        return {
          href: this.link.externalUrl ?? '',
          target: '_blank',
          rel: 'nofollow norefferer',
        }
      },
    },
  }
