import defaultState from './state'

export default {
  /**
   * @param state
   */
  RESET_STATE(state) {
    Object.assign(state, defaultState())
  },

  /**
   * @param state
   * @param {loavies.models.listing.ListingModel[]} listings
   */
  SET_WISHLIST(state, listings) {
    state.wishlist = listings
  },

  /**
   * @param state
   * @param {loavies.models.listing.ListingModel} listing
   */
  ADD_TO_WISHLIST(state, listing) {
    state.wishlist.unshift(listing)
  },
}
