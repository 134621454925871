import {
  TransactionEventModel,
  EventAuditDataModel,
  UserModel,
  ImageModel,
  ImageVariantsModel,
  ImageVariantModel,
  UserPublicDataModel,
  TransactionModel,
  HistoricalTransitionModel,
  LineItemModel,
  PriceModel,
  TransactionProtectedDataModel,
  UserAddressModel,
  TransitionModel,
  ListingModel,
  ListingPublicDataModel,
  ListingDiscountModel,
  ListingPrivateDataModel,
  ReviewModel,
  UserMetaDataModel,
  OrderModel,
  OrderAmountModel,
  OrderVerificationModel,
} from '@loavies/c2c-models'

import {
  mapMetaData,
  mapSelectedPaymentMethod,
} from '@loavies/c2c-marketplace-lib'

/**
 * @param {Object} event
 * @returns {loavies.models.event.TransactionEventModel}
 */
export function mapTransactionEvent(event) {
  return new TransactionEventModel({
    ...event,
    createdAt: new Date(event.createdAt),
    auditData: new EventAuditDataModel(event.auditData),
  })
}

/**
 * @param {Object} user
 * @return {loavies.models.user.UserModel}
 */
export function mapUser(user) {
  return new UserModel({
    ...user,
    createdAt: new Date(user.createdAt),
    image: user.image ? mapImage(user.image) : null,
    publicData: new UserPublicDataModel(user.publicData),
    metaData: new UserMetaDataModel(user.metaData),
  })
}

/**
 * @param {Object} image
 * @return {loavies.models.image.ImageModel}
 */
export function mapImage(image) {
  return new ImageModel({
    ...image,
    variants: new ImageVariantsModel(Object
      .entries(image.variants)
      .reduce((acc, variant) => {
        const key = variant[0]
        const value = variant[1]

        acc[key] = new ImageVariantModel(value)

        return acc
      }, {})),
  })
}

/**
 * @param {Object} transaction
 * @return {loavies.models.transaction.TransactionModel}
 */
export function mapTransaction(transaction) {
  return new TransactionModel({
    ...transaction,
    createdAt: new Date(transaction.createdAt),
    lastHistoricalTransition: new HistoricalTransitionModel({
      ...transaction.lastHistoricalTransition,
      createdAt: new Date(transaction.lastHistoricalTransition.createdAt),
    }),
    lastTransitionedAt: new Date(transaction.lastTransitionedAt),
    lineItems: transaction.lineItems.map(lineItem => mapLineItem(lineItem)),
    metaData: mapMetaData(transaction.metaData),
    payinTotal: new PriceModel(transaction.payinTotal),
    payoutTotal: new PriceModel(transaction.payoutTotal),
    protectedData: new TransactionProtectedDataModel({
      deliveryAddress: transaction.protectedData.deliveryAddress && mapUserAddress(transaction.protectedData.deliveryAddress),
      selectedPaymentMethod: transaction.protectedData.selectedPaymentMethod && mapSelectedPaymentMethod(transaction.protectedData.selectedPaymentMethod),
    }),
    transitionHistory: transaction.transitionHistory.map(historicalTransition => mapHistoricalTransition(historicalTransition)),
    nextTransitions: transaction.nextTransitions?.map(nextTransition => new TransitionModel(nextTransition)),
    listing: transaction.listing && mapListing(transaction.listing),
    customer: mapUser(transaction.customer),
    provider: mapUser(transaction.provider),
    reviews: transaction.reviews.map(review => mapReview(review)),
  })
}

/**
 * @param {Object} lineItem
 * @return {loavies.models.transaction.LineItemModel}
 */
export function mapLineItem(lineItem) {
  return new LineItemModel({
    ...lineItem,
    unitPrice: new PriceModel(lineItem.unitPrice),
    lineTotal: new PriceModel(lineItem.lineTotal),
  })
}

/**
 * @param historicalTransition
 * @return {loavies.models.transaction.HistoricalTransitionModel}
 */
export function mapHistoricalTransition(historicalTransition) {
  return new HistoricalTransitionModel({
    ...historicalTransition,
    createdAt: new Date(historicalTransition.createdAt),
  })
}

/**
 * @param {Object} listing
 * @return {loavies.models.listing.ListingModel}
 */
export function mapListing(listing) {
  return new ListingModel({
    ...listing,
    createdAt: new Date(listing.createdAt),
    price: new PriceModel(listing.price),
    images: listing.images?.map(image => mapImage(image)),
    publicData: listing.publicData && mapListingPublicData(listing.publicData),
    privateData: listing.privateData && mapListingPrivateData(listing.privateData),
    author: listing.author && mapUser(listing.author),
  })
}

/**
 * @param {Object} privateData
 * @return {loavies.models.listing.ListingPrivateDataModel}
 */
export function mapListingPrivateData(privateData) {
  return new ListingPrivateDataModel({
    ...privateData,
    productOriginalPrice: privateData.productOriginalPrice
      && new PriceModel({
        amount: privateData.productOriginalPrice.amount,
        currency: privateData.productOriginalPrice.currency,
      }),
  })
}

/**
 * @param {Object} publicData
 * @return {loavies.models.listing.ListingPublicDataModel}
 */
export function mapListingPublicData(publicData) {
  return new ListingPublicDataModel({
    ...publicData,
    discount: publicData.discount && mapListingDiscount(publicData.discount),
  })
}

/**
 * @param {Object} discount
 * @return {loavies.models.listing.ListingDiscountModel}
 */
export function mapListingDiscount(discount) {
  return new ListingDiscountModel({
    ...discount,
    originalPrice: new PriceModel(discount.originalPrice),
  })
}

/**
 * @param {Object} review
 * @return {loavies.models.review.ReviewModel}
 */
export function mapReview(review) {
  return new ReviewModel({
    ...review,
    createdAt: new Date(review.createdAt),
    author: mapUser(review.author),
    subject: mapUser(review.subject),
  })
}

/**
 * For backwards compatability we check countryCode and postalCode
 * @param {Object} address
 * @return {loavies.models.user.UserAddressModel}
 */
export function mapUserAddress(address) {
  return new UserAddressModel({
    id: address.id,
    firstName: address.firstName,
    lastName: address.lastName,
    countryId: address.countryId || address.countryCode,
    postcode: address.postcode || address.postalCode,
    city: address.city,
    street: address.street,
    number: address.number,
    addition: address.addition,
    additionalAddressLine: address.details || address.additionalAddressLine,
    defaultShipping: address.defaultShipping || address.isDefaultShippingAddress,
    isBusinessAddress: address.isBusinessAddress,
  })
}

/**
 * @param {Object} order
 * @returns {loavies.models.payment.OrderModel}
 */
export function mapOrder(order) {
  return new OrderModel({
    id: order.id,
    reference: order.reference,
    amount: mapOrderAmount(order.amount),
    transactionId: order.transactionId,
    updatedAt: order.updatedAt,
    createdAt: order.createdAt,
  })
}

/**
 * @param {Object} orderAmount
 * @returns {loavies.models.payment.OrderAmountModel}
 */
export function mapOrderAmount(orderAmount) {
  return new OrderAmountModel({
    total: orderAmount.total,
    remaining: orderAmount.remaining,
    currency: orderAmount.currency,
  })
}

/**
 * @param {Object} orderVerification
 * @returns {loavies.models.payment.OrderVerificationModel}
 */
export function mapOrderVerification(orderVerification) {
  return new OrderVerificationModel({
    resultCode: orderVerification.resultCode,
    transactionId: orderVerification.merchantReference,
    paymentMethod: orderVerification.paymentMethod?.type,
    totalPaid: orderVerification?.amount ? new PriceModel({
      amount: orderVerification.amount.value,
      currency: orderVerification.amount.currency,
    }) : null,
  })
}
