import { DEFAULT_SORT_OPTION } from './state'

export default {
  /**
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   * @return {function(loavies.models.listing.ListingModel): boolean}
   */
   checkIfOwnListing: (state, getters, rootState, rootGetters) => listing => {
     if (!rootGetters['user/isAuthenticated']) {
       return false
     }

     return listing.author.id === rootState.user.user.id
   },

  /**
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   * @return {loavies.models.listing.ListingModel[]}
   */
  getActiveListings(state, getters, rootState, rootGetters) {
    if (!rootGetters['user/isAuthenticated']) {
      return []
    }

    const publishedListings = rootGetters['user/publishedListings']

    return publishedListings?.filter(listing => rootGetters['transactions/getListingIsActiveByListingId'](listing.id))
  },

  /**
   * @param state
   * @return {function(loavies.models.listing.ListingFilter): loavies.models.listing.ListingFilter}
   */
  getAvailableFilterByFilter: state => filter => {
    return state.availableFilters?.find(availableFilter => availableFilter.key === filter.key)
  },

  getAvailableFilterOptionCount: (state, getters) => (filter, filterOption) => {
    return getters.getAvailableFilterByFilter(filter)
      ?.options.find(option => option.key === filterOption.key)
      ?.count ?? 0
  },

  /**
   * @param state
   * @return {boolean}
   */
  activeCategoryIsPrimaryCategory: state => {
    return state.activeCategory?.hasOwnProperty('subcategories') ?? false
  },

  /**
   * @param state
   * @return {function(loavies.models.listing.ListingFilter): loavies.models.listing.ListingFilter}
   */
  getActiveFilterByFilter: state => filter => {
    return state.activeFilters?.find(activeFilter => activeFilter.key === filter.key)
  },

  /**
   * @param state
   * @param getters
   * @return {function(
   *   loavies.models.listing.ListingFilter,
   *   loavies.models.listing.ListingFilterOption
   * ): loavies.models.listing.ListingFilterOption}
   */
  isActiveFilterOption: (state, getters) => (filter, filterOption) => {
    return getters.getActiveFilterByFilter(filter)
      ?.options.find(option => option.key === filterOption.key)
  },

  /**
   * @param state
   * @param getters
   * @return {function(loavies.models.listing.ListingFilter): number}
   */
  getActiveFilterOptionCount: (state, getters) => filter => {
    return getters.getActiveFilterByFilter(filter)?.options?.length ?? 0
  },

  /**
   * @param state
   * @param getters
   * @return {number}
   */
  getAllActiveFiltersOptionsCount(state, getters) {
    return state.activeFilters.reduce((acc, activeFilter) => {
      acc += getters.getActiveFilterOptionCount(activeFilter)

      return acc
    }, 0)
  },

  /**
   * @param state
   * @return {function(loavies.models.listing.ListingSortOption): boolean}
   */
  getIsActiveSortOption: state => sortOption => {
    if (!state.activeSortOption) {
      return false
    }

    return state.activeSortOption.key === sortOption.key
      && state.activeSortOption.order === sortOption.order
  },

  /**
   * @param state
   * @return {boolean}
   */
  defaultSortOptionIsActive(state) {
    return state.activeSortOption?.key === DEFAULT_SORT_OPTION.key
  },

  /**
   * @param state
   * @return {function(loavies.models.listing.ListingPriceFilter): null|number}
   */
  getActivePriceFilterAmount: state => filter => {
    return state.activePriceFilters
      .find(activePriceFilter => activePriceFilter.key === filter.key)
      ?.amount ?? null
  },

  /**
   * @param state
   * @return {boolean}
   */
  hasFilters(state) {
    return state.filters.some(filter => filter.options.length)
  },
}

