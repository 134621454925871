import { PRODUCT_STATUS } from '@loavies/c2c-models'

import { mapProduct } from '~/lib/services/api/microservice-api-mappers'

/**
 * @typedef {WardrobeHandlerService}
 * @alias this.$wardrobeHandlerService
 */
export class WardrobeHandlerService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.locale = context.i18n.locale
  }

  init() {
    this.$microserviceApiService = this.context.$microserviceApiService

    this.microserviceApiInstance = this.$microserviceApiService.instance
  }

  /**
   * @returns {Promise<loavies.models.product.ProductModel[]>}
   */
  getProducts() {
    return this.microserviceApiInstance
      .get('/wardrobe/user/products')
      .then(response => response.data.products.map(product => mapProduct(product)))
      .then(products => products.filter(product => this.productIsValid(product)))
  }

  /**
   * @param {string} productId
   * @param {'sold'|'available'|'listed'|'draft'|'notInPossession'} status
   * @return {Promise}
   */
  updateProductStatus(productId, status) {
    return this.microserviceApiInstance
      .patch(`/wardrobe/user/product/${productId}`, { status })
  }

  /**
   * @param {loavies.models.product.ProductModel} product
   * @return {boolean}
   */
  productIsValid(product) {
    const validProductStates = [
      PRODUCT_STATUS.AVAILABLE,
      PRODUCT_STATUS.DRAFT,
    ]

    return Boolean(
      product.color
      && product.productGroup
      && product.size
      && validProductStates.includes(product.status)
    )
  }
}
