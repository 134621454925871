import {
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
} from '~/lib/constants/routes'

/**
 * @typedef {NavigationHelperService}
 * @alias this.$navigationHelperService
 */
export class NavigationHelperService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.localePath = context.localePath
  }

  openTermsAndConditionsTab() {
    window.open(`${window.location.origin}${this.localePath(TERMS_AND_CONDITIONS)}`, '_blank').focus()
  }

  openPrivacyPolicyTab() {
    window.open(`${window.location.origin}${this.localePath(PRIVACY_POLICY)}`, '_blank').focus()
  }
}
