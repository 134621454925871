
  import { mapGetters, mapState } from 'vuex'

  import IconPlusBorderless from '@loavies/component-library/dist/icons/icon-plus-borderless'
  import IconMinBorderless from '@loavies/component-library/dist/icons/icon-min-borderless'
  import HorizontalCarousel from '@loavies/component-library/dist/horizontal-carousel'

  export default {
    components: {
      IconPlusBorderless,
      IconMinBorderless,
      HorizontalCarousel,
    },
    props: {
      horizontal: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        expandedCategoryName: null,
      }
    },
    computed: {
      ...mapState({
        activeCategory: state => state.listings.activeCategory,
        isMobile: state => state.isMobile,
      }),
      ...mapGetters({
        activeCategoryIsPrimaryCategory: 'listings/activeCategoryIsPrimaryCategory',
      }),
      categories() {
        return this.$staticDataService.globalNavigation?.navigation
      },
      menuComponent() {
        return this.isMobile || !this.horizontal ? 'nav' : 'horizontal-carousel'
      },
    },
    watch: {
      activeCategory: {
        handler() {
          if (!this.activeCategory) {
            return
          }

          if (this.activeCategoryIsPrimaryCategory) {
            return this.expandedCategoryName = this.activeCategory.name
          }

          this.expandedCategoryName = this.activeCategory.parentName
        },
        immediate: true,
      },
    },
    methods: {
      getIconComponent(categoryName) {
        return this.expandedCategoryName === categoryName ? 'IconMinBorderless' : 'IconPlusBorderless'
      },
      setExpandedCategoryName(categoryName) {
        if (this.expandedCategoryName === categoryName) {
          this.expandedCategoryName = null
        } else {
          this.expandedCategoryName = categoryName
        }
      },
    },
  }
