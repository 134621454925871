
  import { mapState, mapActions, mapGetters } from 'vuex'

  import AppButton from '@loavies/component-library/dist/app-button'
  import AppAvatar from '@loavies/component-library/dist/app-avatar'
  import IconMenuHamburger from '@loavies/component-library/dist/icons/icon-menu-hamburger'
  import IconSearch from '@loavies/component-library/dist/icons/icon-search'
  import IconWishlist from '@loavies/component-library/dist/icons/icon-wishlist'
  import IconArrowLeftBorderless from '@loavies/component-library/dist/icons/icon-arrow-left-borderless'
  import AppSpinner from '@loavies/component-library/dist/app-spinner'

  import {
    OVERLAYS,
    FLY_IN_NAMES,
    APP_TRAY_NAMES,
  } from '~/lib/constants/overlays'
  import UnreadMessages from '~/mixins/unread-messages'

  export default {
    components: {
      AppButton,
      AppAvatar,
      IconMenuHamburger,
      IconSearch,
      IconWishlist,
      IconArrowLeftBorderless,
      AppSpinner,
    },
    mixins: [UnreadMessages],
    props: {
      clean: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState({
        wishlist: state => state.wishlist.wishlist,
        previousRoutePath: state => state.routes.previousRoutePath,
        isMobile: state => state.isMobile,
      }),
      ...mapGetters({
        isAuthenticated: 'user/isAuthenticated',
        hasUnreadChatMessages: 'chats/hasUnreadChatMessages',
        hasUnreadEvents: 'events/hasUnreadTransactionEvents',
        currentRoutePathIsListingPage: 'routes/currentRoutePathIsListingPage',
      }),
      amountOfWishlistItems() {
        return this.wishlist.length
      },
    },
    methods: {
      ...mapActions({
        setCurrentOverlay: 'overlay/setCurrentOverlay',
      }),
      goBack() {
        if (this.previousRoutePath) {
          return this.$router.go(-1)
        }

        return this.$router.push(this.localePath('/'))
      },
      openNavigationFlyIn() {
        this.setCurrentOverlay({
          settings: {
            type: OVERLAYS.APP_FLY_IN,
            component: FLY_IN_NAMES.MOBILE_NAVIGATION_FLY_IN,
          },
        })
      },
      openSearchFlyIn() {
        this.setCurrentOverlay({
          desktop: {
            type: OVERLAYS.APP_FLY_IN,
            component: FLY_IN_NAMES.SEARCH_LISTINGS_FLY_IN,
          },
          mobile: {
            type: OVERLAYS.APP_TRAY,
            component: APP_TRAY_NAMES.SEARCH_LISTINGS_APP_TRAY,
          },
        })
      },
    },
  }
