
  import { mapState } from 'vuex'

  import AppAvatar from '@loavies/component-library/dist/app-avatar'
  import { FALLBACK_AVATAR_IMAGE } from '~/lib/constants/user'

  export default {
    components: {
      AppAvatar,
    },
    computed: {
      ...mapState({
        user: state => state.user.user,
      }),
      image() {
        return this.user?.image?.variants.w200
      },
      displayName() {
        return this.user?.displayName ?? ''
      },
      imageUrl() {
        return this.image?.url ?? FALLBACK_AVATAR_IMAGE
      },
    },
  }
