
  import { mapActions, mapGetters, mapState } from 'vuex'
  import debounce from 'lodash.debounce'
  import { TransactionEventModel, TalkJsIncomingMessageModel } from '@loavies/c2c-models'

  import { NOTIFICATION_ANIMATION_TYPES } from '~/lib/constants/events'
  import { CHAT_DETAIL } from '~/lib/constants/routes'

  const DEFAULT_ANIMATION_TYPE = NOTIFICATION_ANIMATION_TYPES.FADE
  const DEBOUNCE_TIME = 5000

  export default {
    data() {
      return {
        animationType: DEFAULT_ANIMATION_TYPE,
        localNotifiableEvents: [],
        lastFetchedTimeStamp: null,
        resetLocalNotifiableEventsTimeout: null,
        setDefaultAnimationTypeTimeout: null,
        setLocalNotifiableEventsDebounce: debounce(() => this.setLocalNotifiableEvents(), DEBOUNCE_TIME),
      }
    },
    computed: {
      ...mapState({
        user: state => state.user.user,
        notifiableChatMessages: state => state.chats.notifiableChatMessages,
      }),
      ...mapGetters({
        isAuthenticated: 'user/isAuthenticated',
        notifiableUserTransactionEvents: 'events/notifiableUserTransactionEvents',
        lastEventSequenceId: 'events/lastEventSequenceId',
        getTransactionById: 'transactions/getTransactionById',
      }),
      lastEventSequenceIdNotificationShown() {
        return this.user.privateData?.lastEventSequenceIdNotificationShown
      },
      transitionName() {
        return `notification-${this.animationType}`
      },
      groupedTransactionEventsTitle() {
        return this.$t('new_notifications_title', { count: this.localNotifiableEvents.length })
      },
      groupedTransactionEventsText() {
        return this.$t('new_notifications_text')
      },
      localNotifiableTransactionEvents() {
        return this.localNotifiableEvents
          .filter(event =>
            event instanceof TransactionEventModel
            && !this.$eventsHelperService.isOwnActionsEvent({ event, userId: this.user.id })
          )
      },
      localNotifiableChatMessages() {
        return this.localNotifiableEvents.filter(event =>
          event instanceof TalkJsIncomingMessageModel
          && !this.isCurrentChatEvent(event)
        )
      },
    },
    watch: {
      notifiableChatMessages: {
        immediate: true,
        async handler() {
          if (!this.isAuthenticated || !this.notifiableChatMessages?.length) {
            return
          }

          this.setLocalNotifiableEventsDebounce()
        },
      },
      notifiableUserTransactionEvents: {
        immediate: true,
        async handler() {
          if (!this.isAuthenticated || !this.notifiableUserTransactionEvents?.length) {
            return
          }

          this.setLocalNotifiableEventsDebounce()
        },
      },
    },
    methods: {
      ...mapActions({
        updateUserEventSequenceIdNotificationShown: 'events/updateUserEventSequenceIdNotificationShown',
        updateNotifiableChats: 'chats/updateNotifiableChats',
      }),
      setLocalNotifiableEvents() {
        this.lastFetchedTimeStamp = new Date().getTime()

        this.localNotifiableEvents = [
          ...this.$eventsHelperService.getLatestEventsForUniqueTransactions(this.notifiableUserTransactionEvents),
          ...this.notifiableChatMessages,
        ]

        this.setResetLocalNotifiableEventsTimeout()
      },
      handleClose(event, type) {
        clearTimeout(this.setDefaultAnimationTypeTimeout)

        // 'fade', left' or 'right' can be passed
        this.animationType = type

        // If no event was passed to the function
        // it means that a stacked notification was closed
        this.localNotifiableEvents = event
          ? this.localNotifiableEvents.filter(event => event.id !== event.id)
          : []

        this.setDefaultAnimationTypeTimeout = setTimeout(() => this.animationType = DEFAULT_ANIMATION_TYPE, 500)
      },
      async resetLocalNotifiableEvents() {
        this.localNotifiableEvents = []

        this.updateNotifiableChats()

        if (this.lastEventSequenceIdNotificationShown !== this.lastEventSequenceId) {
          // User gets updated with the latest event sequence id notification shown
          // in store and Sharetribe.
          await this.updateUserEventSequenceIdNotificationShown()
        }
      },
      setResetLocalNotifiableEventsTimeout() {
        this.resetLocalNotifiableEventsTimeout = setTimeout(() => this.resetLocalNotifiableEvents(), DEBOUNCE_TIME)
      },
      clearResetLocalNotifiableEventsTimeout() {
        clearTimeout(this.resetLocalNotifiableEventsTimeout)
      },
      isCurrentChatEvent(event) {
        const isChatRoute = this.$route.fullPath.includes(CHAT_DETAIL)

        if (!isChatRoute) return

        return this.$route.params.id === event.transactionId
      },
    },
  }
