import { SHIPMENT_CAPABILITY } from '~/lib/constants/shipment-capability'
import { mapShipmentCapability } from '~/lib/services/api/microservice-api-mappers'

/**
 * @typedef {ShipmentHandlerService}
 * @alias this.$shipmentHandlerService
 */
export class ShipmentHandlerService {
  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
  }

  init() {
    this.$nodeApiService = this.context.$nodeApiService

    this.nodeApiInstance = this.$nodeApiService.instance
  }

  /**
   * @param {string} transactionId
   * @param {loavies.models.user.UserAddressModel} senderAddress
   * @param {loavies.models.user.UserAddressModel} deliveryAddress
   * @returns {Promise}
   */
  async createShipment({ transactionId, senderAddress, deliveryAddress }) {
    const capability = await this.getCapabilityFromAddress({ senderAddress, deliveryAddress })

    return this.nodeApiInstance
      .post('/transport/create-shipment', {
        transactionId,
        senderAddress,
        capability,
      })
      .catch(error => this.$nodeApiService.handleValidationErrors(error))
  }

  /**
   * @param {loavies.models.user.UserAddressModel} senderAddress
   * @param {loavies.models.user.UserAddressModel} deliveryAddress
   * @returns {Promise<loavies.models.shipment.ShipmentCapabilityModel>}
   */
  getCapabilityFromAddress({ senderAddress, deliveryAddress }) {
    const fromCountryId = senderAddress.countryId || senderAddress.countryCode
    const toCountryId = deliveryAddress.countryId || deliveryAddress.countryCode

    return this.nodeApiInstance
      .get(`/transport/capabilities/from/${fromCountryId}/to/${toCountryId}`)
      .then(response => {
        const defaultCapabilityService = response.data?.find(capability => capability.service === SHIPMENT_CAPABILITY.DOOR)

        if (!defaultCapabilityService) return

        return mapShipmentCapability(defaultCapabilityService)
      })
  }
}
