export default {
  /**
   * @param context
   * @returns {Promise<void>}
   */
  resetState(context) {
    context.commit('RESET_STATE')
  },

  /**
   * @param context
   * @returns {Promise<void>}
   */
  async fetchLatestUserTransactionEvents(context) {
    if (!context.rootGetters['user/isAuthenticated']) {
      return Promise.reject(Error('Not authenticated'))
    }

    const events = await this.$eventsHandlerService
      .getUserTransactionEvents({
        fromSequenceId: context.getters.lastEventSequenceId,
      })
      .catch(() => [])

    if (events.length) {
      await context.dispatch('transactions/fetchLatestTransactionsForUser', null, { root: true })

      context.commit('ADD_TO_TRANSACTION_EVENTS', events)
    }
  },

  /**
   * @param context
   * @returns {Promise<void>}
   */
  async fetchUserTransactionEvents(context) {
    try {
      if (!context.rootGetters['user/isAuthenticated']) {
        return Promise.reject(Error('Not authenticated'))
      }

      const events = await this.$eventsHandlerService.getUserTransactionEvents()

      if (events) {
        context.commit('SET_TRANSACTION_EVENTS', events)
      }
    } catch (error) {
      return Promise.reject(Error(error))
    }
  },

  /**
   * @param context
   * @returns {Promise<void>}
   */
  async updateUserEventSequenceIdMessageRead(context) {
    try {
      if (!context.rootGetters['user/isAuthenticated']) {
        return Promise.reject(Error('Not authenticated'))
      }

      const lastSequenceId = context.rootGetters['events/lastEventSequenceId']
      const lastSavedSequenceId = context.rootState.user.user.privateData.lastEventSequenceIdMessageRead

      if (lastSequenceId === lastSavedSequenceId) return

      await this.$marketplaceUserService.setLastEventSequenceIdMessageRead(lastSequenceId)
    } catch (error) {
      return Promise.reject(Error(error))
    }
  },

  /**
   * @param context
   * @returns {Promise<void>}
   */
  async updateUserEventSequenceIdNotificationShown(context) {
    try {
      if (!context.rootGetters['user/isAuthenticated']) {
        return Promise.reject(Error('Not authenticated'))
      }

      const lastSequenceId = context.rootGetters['events/lastEventSequenceId']

      await this.$marketplaceUserService.setLastEventSequenceIdNotificationShown(lastSequenceId)
        .then(async updatedUser => context.dispatch('user/setUser', updatedUser, { root: true }))
    } catch (error) {
      return Promise.reject(Error(error))
    }
  },

  /**
   * @param context
   * @returns {Promise<void>}
   */
  async initAutoFetchEvents(context) {
    context.commit('SET_FETCH_EVENTS_INTERVAL', () => context.dispatch('fetchLatestUserTransactionEvents'))
  },
}
