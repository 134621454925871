import defaultState from './state'

export default {
  /**
   * @param state
   */
  RESET_STATE(state) {
    Object.assign(state, defaultState())
  },

  /**
   * @param state
   * @param {loavies.models.wallet.WalletModel} wallet
   */
  SET_WALLET(state, wallet) {
    state.wallet = wallet
  },
}
